export default {
  placeholder: {
    en: 'Enter amount',
    ru: 'Введите сумму',
    fr: 'Entrez le montant',
    es: 'Introduzca la cantidad',
    pt: 'Insira o valor',
    de: 'Gib den Betrag ein',
    zh: '輸入數量',
  },
  buttonTitle: {
    en: 'Max',
    ru: 'Макс.',
    fr: 'Max',
    es: 'Max',
    pt: 'Max',
    de: 'Max',
    zh: '最大',
  },
}
