import React from 'react'
import { useConfig } from 'hooks'
import { useDevice } from 'device'
import { commonMessages } from 'helpers'

import { Text } from 'components/ui'
import { Button } from 'components/inputs'
import { Tooltip } from 'components/feedback'
import { Box, Flex } from 'components/layout'

import Card from 'compositions/cards/Card/Card'
import CardHeader from 'compositions/CardHeader/CardHeader'

import { ReportModal, openReportModal } from './ReportModal/ReportModal'
import useChartData from './util/useChartData'

import messages from './messages'
import s from './ChartBlock.module.scss'


const ChartBlock: React.FC = () => {
  const { isMobile } = useDevice()
  const { address } = useConfig()
  const { bars, legendY, legendX, isChartEmpty } = useChartData()

  const buttonNode = (
    <Button
      message={messages.buttonTitle}
      color="blue"
      size={isMobile ? 'md' : 'sm'}
      disabled={!address}
      fullWidthOnMobile
      dataTestId="rewards-report-widget-export-button"
      onClick={openReportModal}
    />
  )

  return (
    <Box mt={32}>
      <Card dataTestId="rewards-report-widget">
        <CardHeader
          title={messages.title}
          content={!isMobile && buttonNode}
          dataTestId="rewards-report-widget-header"
          html
        />
        <Box
          p={20}
          relative
          dataTestId="rewards-report-widget-chart"
        >
          {
            (isChartEmpty || !address) && (
              <Box
                className={s.noStats}
                ph={16}
                pv={8}
              >
                <Text
                  message={address ? messages.noStats : commonMessages.disabledMessages.notConnectedWallet}
                  size={16}
                  color="default"
                  align="center"
                />
              </Box>
            )
          }
          <Flex
            className={s.container}
            align="end"
          >
            {
              Boolean(legendY.length) && (
                <Flex
                  className={s.yLegendContainer}
                  direction="column"
                  justify={legendY.length > 1 ? 'between' : 'end'}
                >
                  {
                    legendY.map((count, index) => (
                      <div
                        key={index}
                        className={s.yLegend}
                      >
                        <Text
                          className={s.legendText}
                          message={count}
                          color="default"
                          align="center"
                          size={10}
                          noWrap
                        />
                      </div>
                    ))
                  }
                </Flex>
              )
            }
            {
              bars.map(({ height, tooltip }, index) => (
                <Flex
                  key={index}
                  className={s.barContainer}
                  direction="column"
                  justify="end"
                >
                  <Tooltip
                    wrapperClassName={s.bar}
                    style={{
                      height,
                      transitionDelay: `${index * 0.015}s`,
                    }}
                    content={Boolean(tooltip) && (
                      <Box p={4} noWrapper>
                        <Text
                          message={tooltip as string}
                          color="default"
                          align="center"
                          size={12}
                          html
                        />
                      </Box>
                    )}
                    center
                  />
                  {
                    (!isMobile || index % 2 === 0) && (
                      <Text
                        className={s.xLegend}
                        message={legendX[index]}
                        color="default"
                        align="center"
                        size={10}
                      />
                    )
                  }
                </Flex>
              ))
            }
          </Flex>
          {
            isMobile && (
              <Box mt={20}>
                {buttonNode}
              </Box>
            )
          }
        </Box>
      </Card>
      <ReportModal />
    </Box>
  )
}


export default React.memo(ChartBlock)
