import { analytics, constants, getters } from 'helpers'
import { splitSignature } from '@ethersproject/bytes'
import { parseEther } from '@ethersproject/units'


type Input = {
  config: Config
  amount: string
  address: string
  recipient: string
  contracts: Contracts
  library: Library
}

const batchApproveAndStake = async (values: Input) => {
  const {
    config,
    amount,
    address,
    library,
    recipient,
    contracts,
  } = values

  const poolContract = contracts.base.poolContract[config.network.id as 'gnosis']
  const gnoTokenContract = contracts.helpers.createErc20(config.addresses.tokens.default.deposit)

  if (poolContract && gnoTokenContract) {
    const currentTimestamp = Number((Date.now() / 1000).toFixed(0))
    const deadline = currentTimestamp + 3600 // + 1 hour

    const [ feeData, tokenName, tokenNonce ] = await Promise.all([
      library.getFeeData(),
      gnoTokenContract.name(),
      gnoTokenContract.nonces(address),
    ])

    const data = JSON.stringify({
      primaryType: 'Permit',
      types: {
        EIP712Domain: [
          { name: 'name', type: 'string' },
          { name: 'version', type: 'string' },
          { name: 'chainId', type: 'uint256' },
          { name: 'verifyingContract', type: 'address' },
        ],
        Permit: [
          { name: 'holder', type: 'address' },
          { name: 'spender', type: 'address' },
          { name: 'nonce', type: 'uint256' },
          { name: 'expiry', type: 'uint256' },
          { name: 'allowed', type: 'bool' },
        ],
      },
      domain: {
        version: '1',
        name: tokenName,
        chainId: config.network.chainId,
        verifyingContract: config.addresses.tokens.default.deposit,
      },
      message: {
        allowed: true,
        holder: address,
        expiry: deadline,
        nonce: tokenNonce.toString(),
        spender: config.addresses.base.pool,
      },
    })

    const amountBN = parseEther(amount)
    const signer = library.getUncheckedSigner(address)
    const signedContract = poolContract.connect(signer)

    const { v, r, s } = await library
      .send('eth_signTypedData_v4', [ address, data ])
      .then(splitSignature)

    analytics.sentry.breadcrumb({
      message: 'GNOSIS: Send stake transaction',
      data: {
        amount,
        recipient,
        token: 'GNO',
      },
    })

    analytics.statistics.sendEvent('deposit', {
      amount,
      recipient,
      token: 'GNO',
    })

    const params = [
      amountBN,
      recipient,
      constants.blockchain.emptyAddress,
      false,
      tokenNonce,
      deadline,
      v,
      r,
      s,
    ] as const

    const { maxFeePerGas, maxPriorityFeePerGas } = feeData

    const gasCost = await signedContract.estimateGas.stakeGNOWithPermit(...params)
    const gasLimit = getters.getGasMargin(gasCost)

    const overrides: Parameters<typeof signedContract.stakeGNOWithPermit>[typeof params.length] = {
      gasLimit,
    }

    if (maxFeePerGas) {
      overrides.maxFeePerGas = maxFeePerGas
    }

    if (maxPriorityFeePerGas) {
      overrides.maxPriorityFeePerGas = maxPriorityFeePerGas
    }

    return signedContract.stakeGNOWithPermit(...params, overrides)
  }
}


export default batchApproveAndStake
