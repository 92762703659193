import { useCallback, useState } from 'react'
import { openNotification } from 'notifications'
import { analytics } from 'helpers'
import { Field } from 'formular'
import { useConfig, useStore } from 'hooks'
import { formatEther, parseEther } from '@ethersproject/units'

import messages from './messages'


const storeSelector = (store: Store) => ({
  nativeTokenBalance: store.account.balances.nativeTokenBalance,
})

type Input = {
  depositField: Field<string>
  fetchDepositData: (amountIn: string) => Promise<any>
}

const getMaxDeposit = ({ maxFeePerGas, gasLimit, balance }: { maxFeePerGas: BigNumber, gasLimit: BigNumber, balance: BigNumber }) => {
  const gasCost = maxFeePerGas.mul(gasLimit)
  const total = balance.sub(gasCost)

  return total.gt(0) ? formatEther(total) : null
}

const useMaxButtonClick = ({ depositField, fetchDepositData }: Input) => {
  const { nativeTokenBalance } = useStore(storeSelector)
  const { address, library, contracts } = useConfig()
  const [ isMaxDepositFetching, setMaxDepositFetching ] = useState(false)

  const handleMaxButtonClick = useCallback(async () => {
    const balance = parseEther(nativeTokenBalance)
    const hasBalance = parseEther(nativeTokenBalance).gt(0)

    if (address && library && contracts && hasBalance) {
      depositField.setError(null)
      setMaxDepositFetching(true)

      try {
        const { feeData, gasLimit, isUniswapFlow } = await fetchDepositData(nativeTokenBalance)

        //In the case of a uniswap, we add 10% to the gas fee, before calculating the maximum allowable balance.
        //This is done for situations when a uniswap contract recalculates the cost of gas upwards.
        //For example: 10000 eth -> 0.000000000000220163 gas fee && 9999.9999 eth -> 0.000000000000220176
        const calculatedGasLimit = isUniswapFlow ? gasLimit.mul(110).div(100) : gasLimit

        const depositValue = getMaxDeposit({
          maxFeePerGas: feeData.maxFeePerGas,
          gasLimit: calculatedGasLimit,
          balance,
        })

        if (depositValue) {
          depositField.set(depositValue)
        }
        else {
          openNotification({
            type: 'warning',
            text: messages.notifications.insufficientBalance,
          })
        }
      }
      catch (error) {
        analytics.sentry.exception('Deposit max button error', error as Error)
      }

      setMaxDepositFetching(false)
    }
    else {
      depositField.set('0')
    }

    depositField.validate()
  }, [ address, contracts, depositField, library, nativeTokenBalance, fetchDepositData ])

  return {
    isMaxDepositFetching,
    handleMaxButtonClick,
  }
}


export default useMaxButtonClick
