export default {
  operator: {
    en: 'Operator',
    ru: 'Оператор',
    fr: 'Opérateur',
    es: 'Operador',
    pt: 'Operador',
    de: 'Betreiber',
    zh: '營運者',
  },
  validators: {
    en: 'Validators',
    ru: 'Валидаторы',
    fr: 'Validateurs',
    es: 'Validadores',
    pt: 'Validadores',
    de: 'Validatoren',
    zh: '驗證者',
  },
}
