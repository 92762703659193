import { useForm } from 'formular'
import { useConfig, useStore } from 'hooks'
import { useCallback, useEffect, useRef } from 'react'
import { validators } from 'helpers'
import { parseEther } from '@ethersproject/units'


const storeSelector = (store: Store) => ({
  rewardTokenBalance: store.account.balances.rewardTokenBalance,
})

const useReinvestForm = () => {
  const { address } = useConfig()
  const { rewardTokenBalance } = useStore(storeSelector)

  const balanceValidator = useCallback((value: string) => (
    validators.sufficientBalance(parseEther(rewardTokenBalance))(value)
  ), [ rewardTokenBalance ])

  const balanceValidatorRef = useRef(balanceValidator)
  balanceValidatorRef.current = balanceValidator

  const form = useForm<GnosisReinvest.Form>({
    fields: {
      amount: [
        validators.numberWithDot,
        validators.greaterThanZero,
        validators.refValidator(balanceValidatorRef),
      ],
      termsAndConditions: [],
    },
  })

  useEffect(() => {
    form.unsetValues()
  }, [ form, address ])

  const handleMaxButtonClick = useCallback(() => {
    form.fields.amount.set(Number(rewardTokenBalance) ? rewardTokenBalance : '0')
    form.fields.amount.validate()
  }, [ form, rewardTokenBalance ])

  return {
    form,
    handleMaxButtonClick,
  }
}


export default useReinvestForm
