import { useCallback, useEffect } from 'react'
import localStorage from 'local-storage'
import { useConfig, useObjectState } from 'hooks'
import { Field } from 'formular'
import { BigNumber } from '@ethersproject/bignumber'
import { formatEther, parseEther } from '@ethersproject/units'
import { actions as actionsHelpers, constants } from 'helpers'

import { uniswapFlow, defaultFlow } from './helpers'


type State = {
  feeData: Deposit.FeeData | null
  gasLimit: BigNumber | null
  amountOut: string
  uniswapRequestParams: Deposit.UniswapRequestParams | null
  isUniswapFlow: boolean
}

type Input = {
  addressField: Field<string>
  depositField: Field<string>
}

const initialState = {
  feeData: null,
  gasLimit: null,
  amountOut: '0',
  uniswapRequestParams: null,
  isUniswapFlow: false,
}

const getIsUniswapFlowBlocked = () => {
  const isManualBlocking = true
  const isPreviewBuild = !IS_PROD || IS_PREVIEW
  const isStorageBlocking = localStorage.getSessionItem(constants.queryNames.blockDepositUniFlow) && isPreviewBuild

  return isManualBlocking || isStorageBlocking
}

const useStakeData = (values: Input) => {
  const { addressField, depositField } = values

  const [ state, setState ] = useObjectState<State>(initialState)
  const { config, library, address, contracts, networkId } = useConfig()
  const { feeData, gasLimit, amountOut, uniswapRequestParams, isUniswapFlow } = state

  const fetchDefaultFlowGasLimit = useCallback((amountBN: BigNumber) => {
    if (address && contracts) {
      return defaultFlow.fetchDepositGas({
        amountBN,
        config,
        address,
        contracts,
        recipient: addressField.state.value,
      })
    }
  }, [ config, address, addressField, contracts ])

  const fetchUniswapData = useCallback(async (amountIn: string) => {
    const isUniswapFlowBlocked = getIsUniswapFlowBlocked()

    if (!isUniswapFlowBlocked && contracts && address) {
      return uniswapFlow.fetchUniswapData({
        config,
        address,
        amountIn,
        contracts,
        recipient: addressField.state.value,
      })
    }

    return Promise.resolve()
  }, [ config, contracts, address, addressField ])

  const fetchDepositData = useCallback(async (deposit: string) => {
    if (!library) {
      return initialState
    }

    const amountBN = parseEther(deposit)
    const amountIn = amountBN.toString()

    const [
      feeData,
      uniswapData,
      defaultFlowGasLimit,
    ]: [
      Deposit.FeeData,
      Deposit.UniswapData | void,
      BigNumber | void,
    ] = await Promise.all([
      library.getFeeData(),
      fetchUniswapData(amountIn),
      fetchDefaultFlowGasLimit(amountBN),
    ])

    if (!defaultFlowGasLimit) {
      return initialState
    }

    if (uniswapData) {
      const isUniswapFlow = uniswapFlow.getIsUniswapFlow({
        feeData,
        amountBN,
        uniswapData,
      })

      if (isUniswapFlow) {
        const { gasLimit, swapAmounts, requestParams } = uniswapData

        return {
          feeData,
          gasLimit,
          uniswapRequestParams: requestParams,
          amountOut: formatEther(swapAmounts.swapAmountOutBN),
          isUniswapFlow: true,
        }
      }
    }

    return {
      feeData,
      amountOut: deposit,
      gasLimit: defaultFlowGasLimit,
      uniswapRequestParams: null,
      isUniswapFlow: false,
    }
  }, [ library, fetchUniswapData, fetchDefaultFlowGasLimit ])

  const checkIsValidAmount = useCallback((depositFieldState) => (
    Boolean(
      Number(depositFieldState.value)
      && !depositFieldState.isValidating
      && (
        depositFieldState.isValid
        || !depositFieldState.error
      )
    )
  ), [])

  useEffect(() => {
    const fetchData = actionsHelpers.debounce((depositFieldState: Record<string, string | boolean>) => {
      if (checkIsValidAmount(depositFieldState)) {
        const value = depositFieldState.value as string

        fetchDepositData(value)
          .then((data) => {
            const { feeData, gasLimit, amountOut, uniswapRequestParams, isUniswapFlow } = data

            if (checkIsValidAmount(depositFieldState)) {
              setState({
                feeData,
                gasLimit,
                amountOut,
                isUniswapFlow,
                uniswapRequestParams,
              })
            }
          })
      }
      else {
        setState(initialState)
      }
    }, 150)

    depositField.on('state change', fetchData)

    return () => {
      depositField.off('state change', fetchData)
    }
  }, [ depositField, checkIsValidAmount, fetchDepositData, setState ])

  // Reset state on network or address change
  useEffect(() => {
    return () => {
      setState(initialState)
    }
  }, [ networkId, address, setState ])

  const submitParams = {
    feeData,
    gasLimit,
    uniswapRequestParams,
  }

  return {
    amountOut,
    submitParams,
    isUniswapFlow,
    fetchDepositData,
  }
}


export default useStakeData
