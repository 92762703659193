import React, { useCallback, useState } from 'react'
import cx from 'classnames'
import { constants, modifiers } from 'helpers'
import { formatEther } from '@ethersproject/units'
import { LimitOrder } from '@1inch/limit-order-protocol'

import { Text, Icon } from 'components/ui'
import { Box, Flex } from 'components/layout'
import Tooltip from 'components/feedback/Tooltip/Tooltip'

import s from './Order.module.scss'
import messages from './messages'


type OrderProps = {
  order: {
    signature: string
    orderHash: string
    data: LimitOrder
  }
  makingToken: Tokens
  cancelOrder: (order: OrderProps['order']) => Promise<void>
}

const Order: React.FC<OrderProps> = (props) => {
  const { order, makingToken, cancelOrder } = props
  const { makingAmount, takingAmount } = order.data

  const [ isLoading, setLoading ] = useState(false)

  const etherMakingAmount = formatEther(makingAmount)
  const etherTakingAmount = formatEther(takingAmount)

  const formattedMakingAmount = modifiers.formatTokenValue({
    value: etherMakingAmount,
    max: 2,
  })

  const formattedTakingAmount = modifiers.formatTokenValue({
    value: etherTakingAmount,
    max: 2,
  })

  const handleCancelOrder = useCallback(() => {
    setLoading(true)

    cancelOrder(order)
      .catch(() => setLoading(false))
  }, [ order, cancelOrder, setLoading ])

  const itemClassName = cx(s.item, {
    [s.loading]: isLoading,
  })

  return (
    <Box className={itemClassName} radius={8} p={8} relative>
      {
        !isLoading && (
          <button
            className={s.closeButton}
            type="button"
            data-testid="order-cancel"
            onClick={handleCancelOrder}
          >
            <Icon
              name="fa-light fa-xmark"
              color="danger-states"
              size={20}
            />
          </button>
        )
      }
      <Flex justify="between">
        <Box alignCenter fullWidth>
          <Text
            message={messages.sell}
            color="dark-gray"
            size={16}
          />
          <Tooltip
            content={`${etherMakingAmount} ${makingToken}`}
            inModal
            center
          >
            <Box mt={12} noWrapper>
              <Text
                className={s.value}
                message={`${formattedMakingAmount} ${makingToken}`}
                color="info"
                size={14}
              />
            </Box>
          </Tooltip>
        </Box>
        <Box alignCenter fullWidth>
          <Text
            message={messages.get}
            color="dark-gray"
            size={16}
          />
          <Tooltip
            content={`${etherTakingAmount} ${constants.tokens.eth}`}
            inModal
            center
          >
            <Box mt={12} noWrapper>
              <Text
                className={s.value}
                message={`${formattedTakingAmount} ${constants.tokens.eth}`}
                color="info"
                size={14}
              />
            </Box>
          </Tooltip>
        </Box>
      </Flex>
    </Box>
  )
}


export default React.memo(Order)
