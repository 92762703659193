import { getters, constants } from 'helpers'


type Input = {
  address: string
  amountIn: string
  contracts: Contracts
  params: Deposit.UniswapRequestParams
}

const fetchDepositGas = async (values: Input): Promise<BigNumber | null> => {
  const { amountIn, address, contracts, params } = values

  if (!contracts?.helpers.uniswap.routerContract) {
    return constants.blockchain.amount0
  }

  try {
    const estimatedGas = await contracts.helpers.uniswap.routerContract.estimateGas.exactInputSingle(
      params,
      { value: amountIn, from: address }
    )

    return getters.getGasMargin(estimatedGas)
  }
  catch {
    // if "deadline" prop from uni params was finished, then will be error.
    // Need to catch the error and direct user to the default flow
  }

  return null
}


export default fetchDepositGas
