import { parseEther } from '@ethersproject/units'
import { analytics, constants, getters } from 'helpers'


type Input = {
  config: Config
  amount: string
  address: string
  recipient: string
  contracts: Contracts
  library: Library
  token: Extract<Tokens, 'GNO' | 'mGNO'>
}

const stake = async (values: Input) => {
  const {
    token,
    config,
    amount,
    address,
    library,
    recipient,
    contracts,
  } = values

  const methodName = token === constants.tokens.gno ? 'stakeGNO' : 'stakeMGNO'
  const poolContract = contracts.base.poolContract[config.network.id as 'gnosis']

  if (poolContract) {
    const amountBN = parseEther(amount)
    const signer = library.getUncheckedSigner(address)
    const signedContract = poolContract.connect(signer)

    const analyticsData = {
      token,
      amount,
      recipient,
    }

    analytics.sentry.breadcrumb({
      message: 'GNOSIS: Send stake transaction',
      data: analyticsData,
    })

    analytics.statistics.sendEvent('deposit', analyticsData)

    const params = [
      amountBN,
      recipient,
      constants.blockchain.emptyAddress,
      false,
    ] as const

    const [ gasCost, feeData ] = await Promise.all([
      signedContract.estimateGas[methodName](...params),
      library.getFeeData(),
    ])

    const { maxFeePerGas, maxPriorityFeePerGas } = feeData

    const overrides: Parameters<typeof signedContract[typeof methodName]>[typeof params.length] = {
      gasLimit: getters.getGasMargin(gasCost),
    }

    if (maxFeePerGas) {
      overrides.maxFeePerGas = maxFeePerGas
    }

    if (maxPriorityFeePerGas) {
      overrides.maxPriorityFeePerGas = maxPriorityFeePerGas
    }

    return signedContract[methodName](...params, overrides)
  }
}


export default stake
