import React, { useEffect } from 'react'
import localStorage from 'local-storage'
import { modalWrapper } from 'modal'
import { commonMessages } from 'helpers'

import { Text } from 'components/ui'
import { Box } from 'components/layout'
import { Modal } from 'components/feedback'
import AddTokensToWallet from 'compositions/AddTokensToWallet/AddTokensToWallet'

import messages from './messages'


type DepositSuccessModalProps = {
  localStorageName: string
}

export const [ DepositSuccessModal, openDepositSuccessModal ] = (
  modalWrapper<DepositSuccessModalProps>(UNIQUE_FILE_ID, (props) => {
    const { localStorageName, closeModal } = props

    useEffect(() => {
      localStorage.setItem(localStorageName, true)
    }, [])

    return (
      <Modal analyticsName="staking deposit success" closeModal={closeModal}>
        <Box pv={16}>
          <Text
            message={commonMessages.notifications.success}
            color="default"
            align="center"
            size={24}
          />
        </Box>
        <Box mt={16}>
          <Text
            message={messages.note}
            color="dark-gray"
            align="center"
            size={16}
          />
        </Box>
        <Box mt={16}>
          <AddTokensToWallet />
        </Box>
      </Modal>
    )
  })
)
