import React from 'react'
import type { Field } from 'formular'
import { useDevice } from 'modules/device'

import { Text } from 'components/ui'
import { Checkbox } from 'components/inputs'

import messages from './messages'
import s from './TermsCheckbox.module.scss'


type TermsCheckboxProps = {
  field: Field<boolean>
  dataTestId: string
}

const TermsCheckbox: React.FC<TermsCheckboxProps> = (props) => {
  const { field, dataTestId } = props

  const { isMobile } = useDevice()

  return (
    <Checkbox field={field} dataTestId={dataTestId} withPreventDefault={false}>
      <Text
        className={s.text}
        message={messages.text}
        color="default"
        tag="span"
        size={isMobile ? 12 : 14}
        dataTestId={dataTestId ? `${dataTestId}-text` : ''}
        html
      />
    </Checkbox>
  )
}


export default React.memo(TermsCheckbox)
