import { modifiers } from 'helpers'
import { useStore, useFiatValues, useConfig } from 'hooks'


type Output = {
  value: number
  rewardToken: Tokens
  depositToken: Tokens
  initialTime: number
  fiatRewards: number
  currencySymbol: string
  showCountdown: boolean
  isInitialRequestLoading: boolean
}

const storeSelector = (store: Store) => ({
  currencySymbol: store.account.currencySymbol,
  oraclesNextUpdate: store.system.oraclesNextUpdate,
  isInitialRequestLoading: store.ui.initialRequestLoading,
  rewardTokenBalance: store.account.balances.rewardTokenBalance,
  stakedTokenBalance: store.account.balances.stakedTokenBalance,
})

const useRewardsCard = (): Output => {
  const {
    currencySymbol,
    stakedTokenBalance,
    rewardTokenBalance,
    oraclesNextUpdate,
    isInitialRequestLoading,
  } = useStore(storeSelector)

  const { config } = useConfig()
  const { rewardToken, depositToken } = config.tokens

  const { fiatRewards } = useFiatValues({
    fiatRewards: {
      value: rewardTokenBalance,
      token: rewardToken,
    },
  })

  const currentTime = new Date().getTime()
  const formattedValue = modifiers.formatTokenValue({ value: rewardTokenBalance })
  const showCountdown = Boolean(Number(stakedTokenBalance) && oraclesNextUpdate)
  const isUpdating = Boolean(oraclesNextUpdate && oraclesNextUpdate < currentTime)
  const initialTime = isUpdating ? 0 : oraclesNextUpdate - currentTime

  return {
    initialTime,
    rewardToken,
    depositToken,
    showCountdown,
    currencySymbol,
    isInitialRequestLoading,
    fiatRewards: fiatRewards.value,
    value: parseFloat(formattedValue),
  }
}


export default useRewardsCard
