import React from 'react'
import { useFormState } from 'formular'
import { commonMessages } from 'helpers'
import type { Form } from 'formular'

import { Button } from 'components/inputs'


type ConfirmButtonProps = {
  className?: string
  form: Form<any>
  disabled?: boolean
  loading?: boolean
  title?: Intl.Message
  dataTestId: string
  onClick: () => void
}

const ConfirmButton: React.FC<ConfirmButtonProps> = (props) => {
  const { className, title, form, disabled, loading, dataTestId, onClick } = props

  const { values, errors, isValid } = useFormState(form)

  const isDisabled = (
    disabled
    || !isValid
    || Boolean(errors)
    || !Object.values(values).every(Boolean)
  )

  return (
    <Button
      className={className}
      message={title || commonMessages.buttons.confirm}
      color="orange"
      loading={loading}
      dataTestId={dataTestId}
      disabled={isDisabled}
      onClick={onClick}
    />
  )
}


export default React.memo(ConfirmButton)
