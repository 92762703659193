import React from 'react'
import cx from 'classnames'

import { Tabs } from 'components/ui'
import { Box } from 'components/layout'
import Card from 'compositions/cards/Card/Card'

import s from './Container.module.scss'


type ContainerProps = {
  className?: string
  tabs: Array<{
    id: string
    title: Intl.Message
    node: React.ReactNode
  }>
}

const Container: React.FC<ContainerProps> = (props) => {
  const { className, tabs } = props

  return (
    <Card
      className={cx(s.card, className)}
      dataTestId="main-widget"
    >
      <Box p={16} noWrapper>
        <Tabs>
          {
            tabs.map(({ id, node, title }) => (
              <Tabs.Content
                id={id}
                key={id}
                title={title}
                dataTestId={`main-${id}-tab`}
              >
                {node}
              </Tabs.Content>
            ))
          }
        </Tabs>
      </Box>
    </Card>
  )
}


export default Container
