import React from 'react'
import { useDevice } from 'device'

import InfoCard from './InfoCard/InfoCard'
import ControlsCard from './ControlsCard/ControlsCard'

import { Grid, Box } from 'components/layout'


const MainBlock: React.FC = () => {
  const { isTabletPortrait, isMobile } = useDevice()

  if (isTabletPortrait || isMobile) {
    return (
      <>
        <Box mt={32}>
          <ControlsCard />
        </Box>
        <Box mt={32}>
          <InfoCard />
        </Box>
      </>
    )
  }

  return (
    <Box mt={16}>
      <Grid columns={2} gutter={32}>
        <InfoCard />
        <ControlsCard />
      </Grid>
    </Box>
  )
}


export default React.memo(MainBlock)
