import React from 'react'

import { Flex, Box } from 'components/layout'
import { Tooltip } from 'components/feedback'
import { Icon, RoundIcon } from 'components/ui'
import type { RoundIconProps } from 'components/ui'

import messages from './messages'
import s from './Tokens.module.scss'


type TokensProps = {
  size?: RoundIconProps['size']
  leftToken: RoundIconProps['token']
  rightToken: RoundIconProps['token']
}

const Tokens: React.FC<TokensProps> = (props) => {
  const { size = 75, leftToken, rightToken } = props

  const leftTooltipContent = { ...messages.deposit, values: { token: leftToken } }
  const rightTooltipContent = { ...messages.receive, values: { token: rightToken } }

  return (
    <Flex justify="center">
      <Tooltip content={leftTooltipContent} center>
        <RoundIcon token={leftToken} size={size} shadow />
      </Tooltip>
      <Box className={s.iconContainer} p={8} mh={24}>
        <Icon name="fa-solid fa-arrow-right" size={14} color="white" />
      </Box>
      <Tooltip content={rightTooltipContent} center>
        <RoundIcon token={rightToken} size={size} shadow />
      </Tooltip>
    </Flex>
  )
}


export default React.memo(Tokens)
