import React from 'react'
import { FieldState } from 'formular'
import { commonMessages } from 'helpers'

import { Text } from 'components/ui'
import { Box } from 'components/layout'
import { FormState } from 'components/inputs'
import TokenAmountInput from 'compositions/TokenAmountInput/TokenAmountInput'

import StakeInfo from '../../components/StakeInfo/StakeInfo'
import TermsCheckbox from '../../components/TermsCheckbox/TermsCheckbox'
import ConfirmButton from '../../components/ConfirmButton/ConfirmButton'
import { ApproveModal } from '../../components/ApproveModal/ApproveModal'
import { DepositSuccessModal } from '../../components/DepositSuccessModal/DepositSuccessModal'

import Balances from './Balances/Balances'
import TokensWrapper from './TokensWrapper/TokensWrapper'

import useStake from './util/useStake'

import s from './Stake.module.scss'


const Stake: React.FC = () => {
  const {
    form,
    amountOut,
    disabledMessage,
    gnoTokenBalance,
    mgnoTokenBalance,
    isDisabled,
    isCurveFlow,
    isNeedApprove,
    isBalancesFetching,
    onSubmit,
    onMaxButtonClick,
  } = useStake()

  return (
    <>
      <TokensWrapper tokenField={form.fields.token} />
      <Box className={s.maxWidth} mt={32} center>
        <Balances
          tokenField={form.fields.token}
          gnoTokenBalance={gnoTokenBalance}
          mgnoTokenBalance={mgnoTokenBalance}
          isLoading={isBalancesFetching}
        />
      </Box>
      <Box className={s.maxWidth} mt={16} center>
        <TokenAmountInput
          loading={isBalancesFetching}
          field={form.fields.deposit}
          disabled={Boolean(disabledMessage)}
          dataTestIdPrefix="stake-amount"
          onMaxButtonClick={onMaxButtonClick}
        />
      </Box>
      <FieldState field={form.fields.token}>
        {
          ({ value }) => (
            <StakeInfo
              amountOut={amountOut}
              addressField={form.fields.address}
              depositField={form.fields.deposit}
              token={value}
              isDepositDisabled={isDisabled}
              liquidityPool={isCurveFlow ? 'curve' : null}
            />
          )
        }
      </FieldState>
      <Box className={s.checkboxContainer} mt={24} alignCenter>
        {
          Boolean(disabledMessage) ? (
            <Text
              message={disabledMessage as Intl.Message}
              color="danger"
              size={16}
              dataTestId="stake-wallet-error"
              html
            />
          ) : (
            <TermsCheckbox
              field={form.fields.termsAndConditions}
              dataTestId="stake-terms-checkbox"
            />
          )
        }
      </Box>
      <Box mt={24} alignCenter>
        <FormState form={form}>
          {
            ({ values, errors }) => {
              const approveButtonTitle = isNeedApprove
                ? { ...commonMessages.buttons.approve, values: { token: values.token } }
                : undefined

              return (
                <ConfirmButton
                  form={form}
                  loading={isBalancesFetching}
                  title={approveButtonTitle}
                  disabled={isDisabled || Boolean(errors)}
                  dataTestId="stake-confirm-button"
                  onClick={onSubmit}
                />
              )
            }
          }
        </FormState>
      </Box>
      <DepositSuccessModal />
      <ApproveModal />
    </>
  )
}


export default React.memo(Stake)
