import { BigNumber } from '@ethersproject/bignumber'


type Input = {
  amountBN: BigNumber
  feeData: Deposit.FeeData
  uniswapData: Deposit.UniswapData
}

const getIsUniswapFlow = (values: Input): boolean => {
  const { amountBN, feeData, uniswapData } = values
  const { swapAmounts, gasLimit } = uniswapData

  const { maxFeePerGas } = feeData
  const { minSwapAmountOut } = swapAmounts

  if (gasLimit && maxFeePerGas) {
    const defaultGasCost = gasLimit.mul(maxFeePerGas)
    const defaultTotal = amountBN.sub(defaultGasCost)

    const uniGasCost = gasLimit.mul(maxFeePerGas)
    const uniTotal = BigNumber.from(minSwapAmountOut).sub(uniGasCost)

    return defaultTotal.lt(uniTotal)
  }

  return false
}


export default getIsUniswapFlow
