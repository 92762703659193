export default {
  title: {
    en: 'Staking rewards for the past 30&nbsp;days',
    ru: 'Вознаграждения стейкинга за последние 30 дней',
    fr: 'Récompenses de staking pour les 30 derniers jours',
    es: 'Ganancias de Staking de los últimos 30&nbsp;días',
    pt: 'Recompensas de staking nos últimos 30&nbsp;dias',
    de: 'Staking Belohnungen für die letzten 30&nbsp;Tage',
    zh: '過去 30&nbsp;天的質押獎勵',
  },
  noStats: {
    en: 'No rewards for the past 30 days',
    ru: 'Нет вознаграждений за последние 30 дней',
    fr: 'Pas de récompenses pour les 30 derniers jours',
    es: 'Sin ganancias durante los últimos 30 días',
    pt: 'Sem recompensas nos últimos 30 dias',
    de: 'Keine Belohnung für die letzten 30 Tage',
    zh: '沒有過去 30 天的質押獎勵',
  },
  buttonTitle: {
    en: 'Export',
    ru: 'Экспорт',
    fr: 'Exporter',
    es: 'Exportar',
    pt: 'Exportar',
    de: 'Exportieren',
    zh: '匯出',
  },
}
