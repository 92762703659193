import { useCallback, useEffect, useState } from 'react'
import { Form } from 'formular'
import { parseEther } from '@ethersproject/units'
import { openNotification } from 'notifications'
import { useActions, useBalances, useConfig } from 'hooks'
import { actions as helpersActions, analytics, commonMessages, requests } from 'helpers'

import { openApproveModal } from '../../../components/ApproveModal/ApproveModal'

import { batchApproveAndSwap, swap } from './helpers'

import messages from './messages'


type Input = {
  form: Form<Reinvest.Form>
  amountOut: string
  isNeedAllowance: boolean
  isPermitSupported: boolean
  updateAllowance: () => void
  setNeedAllowance: (isNeedAllowance: boolean) => void
  setPermitDisabled: (isPermitDisabled: boolean) => void
}

const useSubmit = (props: Input) => {
  const {
    form, amountOut, isNeedAllowance, isPermitSupported,
    updateAllowance, setNeedAllowance, setPermitDisabled,
  } = props

  const actions = useActions()
  const { config, contracts, address, library, activeWallet } = useConfig()
  const { fetchAndSetRewardTokenBalance, fetchAndSetStakedTokenBalance } = useBalances()

  const [ isSubmitting, setSubmitting ] = useState(false)

  const isSubmitDisabled = (
    !library
    || !address
    || !contracts
    || !activeWallet
    || isSubmitting
  )

  useEffect(() => {
    if (isSubmitting) {
      actions.ui.setBottomLoader({
        content: messages.loaders.waitingConfirmation,
      })

      return () => {
        actions.ui.resetBottomLoader()
      }
    }
  }, [ actions, isSubmitting ])

  const handleError = useCallback((error: Error) => {
    analytics.sentry.exception('Deposit send transaction error', error)

    openNotification({
      type: 'error',
      text: commonMessages.notifications.failed,
    })
  }, [])

  const handleApprove = useCallback(async () => {
    if (!library || !address || !contracts) {
      return
    }

    return requests.approve({
      library,
      contracts,
      from: address,
      to: config.addresses.helpers.uniswap.router,
      tokenAddress: config.addresses.tokens.default.reward,
    })
      .then((result) => (
        helpersActions.handleTransaction({
          sentText: commonMessages.notifications.sent,
          txHash: result?.hash,
          library,
          actions,
          config,
        })
      ))
  }, [ config, address, actions, library, contracts ])

  const handleApproveAndSwap = useCallback(async (amount: string) => {
    if (!address || !library || !contracts) {
      return
    }

    const amountOutBN = parseEther(amountOut)
    const amountBN = parseEther(amount)

    return batchApproveAndSwap({
      config,
      library,
      address,
      contracts,
      amountBN,
      amountOutBN,
    })
  }, [ address, config, library, contracts, amountOut ])

  const handleSubmitTransaction = useCallback(async (result) => {
    if (!library) {
      return
    }

    form.fields.amount.set('')

    const callback = () => {
      fetchAndSetRewardTokenBalance()
      fetchAndSetStakedTokenBalance()

      openNotification({
        type: 'success',
        text: commonMessages.notifications.success,
      })
    }

    return helpersActions.handleTransaction({
      withConfirmedNotification: false,
      txHash: result?.hash,
      sentText: messages.notifications.transactionSent,
      config,
      library,
      actions,
      callback,
    })
  }, [ form, config, actions, library, fetchAndSetRewardTokenBalance, fetchAndSetStakedTokenBalance ])

  const handleSubmit = useCallback(async (amount: string) => {
    if (!library || !address || !contracts) {
      return
    }

    const amountOutBN = parseEther(amountOut)
    const amountBN = parseEther(amount)

    return swap({ address, config, amountBN, amountOutBN, library, contracts })
      .then((result) => handleSubmitTransaction(result))
      .catch((error) => {
        const errorCode = error?.data?.originalError?.code || error?.code

        // code 4001: User canceled transaction
        if (errorCode === 4001) {
          updateAllowance()

          openApproveModal({
            type: 'reinvest',
            token: config.tokens.rewardToken,
            onClick: () => handleSubmit(amount),
          })
        }
        else {
          console.warn(error)
          handleError(error)
        }
      })
  }, [
    config,
    address,
    library,
    contracts,
    amountOut,
    handleError,
    updateAllowance,
    handleSubmitTransaction,
  ])

  const submit = useCallback(async ({ isPermitDisabled }: { isPermitDisabled?: boolean } = {}) => {
    if (isSubmitDisabled) {
      return
    }

    const { values, errors } = await form.submit()

    if (errors) {
      return
    }

    const { amount } = values

    analytics.sentry.breadcrumb({
      message: 'send deposit transaction',
      data: {
        amount,
        isNeedAllowance,
        isPermitDisabled,
        isPermitSupported,
      },
    })

    setSubmitting(true)

    const isBatchStakeFlow = isPermitSupported && !isPermitDisabled

    if (isNeedAllowance) {
      const promise = isBatchStakeFlow
        ? handleApproveAndSwap(amount)
        : handleApprove()

      promise
        .then(
          (result) => {
            return isBatchStakeFlow
              ? handleSubmitTransaction(result)
              : handleSubmit(amount)
          },
          (error) => {
            const errorCode = error?.data?.originalError?.code || error?.code

            console.warn(error)

            // code 4001: User canceled transaction
            if (isBatchStakeFlow && errorCode !== 4001) {
              // It may be that a wallet that supports batching transactions will not be able to do this,
              // so 2 transactions will need to be done. Example - MetaMask with Trezor
              setPermitDisabled(true)

              openApproveModal({
                type: 'batchReinvest',
                token: config.tokens.rewardToken,
                onClick: () => submit({ isPermitDisabled: true }),
              })
            }
            else {
              // code -32603: Reverted error.
              // There is some problem with approved tokens on the Gnosis network. At some point,
              // approve may stop working. In this case, you need to approve them again.
              if (errorCode === -32603) {
                setNeedAllowance(true)
              }

              handleError(error)
            }
          }
        )
        .finally(() => setSubmitting(false))
    }
    else {
      handleSubmit(amount)
        .finally(() => setSubmitting(false))
    }
  }, [
    form,
    config,
    isNeedAllowance,
    isSubmitDisabled,
    isPermitSupported,
    handleError,
    handleSubmit,
    handleApprove,
    setNeedAllowance,
    setPermitDisabled,
    handleApproveAndSwap,
    handleSubmitTransaction,
  ])

  const isNeedApprove = isNeedAllowance && !isPermitSupported

  return {
    isNeedApprove,
    isSubmitting,
    submit,
  }
}


export default useSubmit
