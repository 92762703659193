import { useCallback, useEffect, useMemo } from 'react'
import { useConfig, useStore, useObjectState } from 'hooks'
import { constants, analytics, modifiers } from 'helpers'
import { BigNumber } from '@ethersproject/bignumber'
import { parseEther } from '@ethersproject/units'
import { useCacheListner } from 'cache-storage'


export type Output = {
  isFetching: boolean
  isEmptyFiatValue: boolean
  calculateAmountOut: (amountBN: BigNumber, token: Tokens) => BigNumber
}

const storeSelector = (store: Store) => ({
  ethUsdRate: store.system.fiatPrices.ETH.USD,
})

const _getMaxTotal = (usdValue: number, ethUsdRate: number) => {
  if (!ethUsdRate) {
    return BigNumber.from('0')
  }

  const ethCount = parseEther((usdValue / ethUsdRate).toFixed())

  return ethCount
}

const useAmountOut = (): Output => {
  const { config, contracts } = useConfig()
  const { ethUsdRate } = useStore(storeSelector)

  const [ { isFetching, isEmptyFiatValue }, setState ] = useObjectState({
    isFetching: true,
    isEmptyFiatValue: false,
  })

  const [ data, setData, isChanged ] = useCacheListner<{
    rewardTokenBalanceBN: BigNumber
    stakedTokenBalanceBN: BigNumber
  }>(UNIQUE_FILE_ID)

  const [ maxStakedTotalBN, maxRewardTotalBN ] = useMemo(() => [
    _getMaxTotal(24_000_000, ethUsdRate),
    _getMaxTotal(1_000_000, ethUsdRate),
  ], [ ethUsdRate ])

  const fetchBlockdaemonBalances = useCallback(async () => {
    const { rewardTokenContract, stakedTokenContract } = contracts.tokens.default

    setState({ isFetching: true })

    if (!stakedTokenContract || !rewardTokenContract) {
      analytics.sentry.exception('HARBOUR: useAmountOut - empty reward or staked balances contracts')

      setState({ isFetching: false })

      return
    }

    if (!isChanged) {
      const [ rewardTokenBalanceBN, stakedTokenBalanceBN ] = await Promise.all([
        rewardTokenContract.balanceOf(constants.specialAddresses.blockdaemon),
        stakedTokenContract.balanceOf(constants.specialAddresses.blockdaemon),
      ])

      setData({ rewardTokenBalanceBN, stakedTokenBalanceBN })
    }

    setState({ isFetching: false })
  }, [])

  const calculateAmountOut = useCallback<Output['calculateAmountOut']>((amountBN, token) => {
    const isStakedToken = token === config.tokens.stakedToken

    if (!data) {
      analytics.sentry.exception('HARBOUR: useAmountOut - calculateAmountOut was called before fetch balances')

      return constants.blockchain.amount0
    }

    try {
      // $amount = Number of tokens the user wants to withdraw (sETH-h or rETH-h)
      // $balance = How many tokens of this type are currently in the blockdeamon wallet
      // $total = How much total money in dollars blockdeamon can spend on closing transactions for a given token type

      // Discount formula => 0.25 + (0.75 * ($balance + $amount) / $total)

      const minPercent = isStakedToken ? 0.01 : 0.0125
      const basePercent = isStakedToken ? 0.0025 : 0.0055
      const totalBN = isStakedToken ? maxStakedTotalBN : maxRewardTotalBN
      const balanceBN = isStakedToken ? data.stakedTokenBalanceBN : data.rewardTokenBalanceBN

      const nextBalanceBN = amountBN.add(balanceBN)

      let percent

      if (nextBalanceBN.gt(totalBN)) {
        // If the DB balance runs out, the discount will always be 1%
        percent = new modifiers.DecimalsBN().getPercent(minPercent, 10)
      }
      else {
        percent = new modifiers.DecimalsBN(nextBalanceBN)
          .setMultiplier(10)
          .mulPercent(0.0075)
          .div(totalBN)
          .addPercent(basePercent, 10)
      }

      const discountBN = percent
        .mul(amountBN)
        .removeMultiplier(10)
        .toBN()

      return amountBN.sub(discountBN)
    }
    catch (error) {
      analytics.sentry.exception('HARBOUR: useAmountOut - calculate error', error as Error)

      return constants.blockchain.amount0
    }
  }, [ data, maxStakedTotalBN, maxRewardTotalBN, config.tokens.stakedToken ])

  useEffect(() => {
    if (ethUsdRate <= 0) {
      // We cannot do the calculations without this fiat data
      analytics.sentry.exception('HARBOUR: useAmountOut - empty fiat data')

      setState({ isEmptyFiatValue: true })
    }

    fetchBlockdaemonBalances()
  }, [])

  return useMemo(() => ({
    isFetching,
    isEmptyFiatValue,
    calculateAmountOut,
  }), [ isFetching, calculateAmountOut ])
}


export default useAmountOut
