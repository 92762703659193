import { useEffect, useState } from 'react'
import { useConfig } from 'hooks'
import { constants } from 'helpers'
import { Field } from 'formular'


type Input = {
  tokenField: Field<string>
}

type Output = {
  isPermitSupported: boolean
  setPermitDisabled: (isPermitDisabled: boolean) => void
}

const usePermitSupport = ({ tokenField }: Input): Output => {
  const { activeWallet } = useConfig()
  const [ isGno, setGno ] = useState(tokenField.state.value === constants.tokens.gno)
  const [ isPermitDisabled, setPermitDisabled ] = useState(false)

  const isPermitSupported = (
    !isPermitDisabled
    && isGno
    && (
      activeWallet === constants.walletNames.metaMask
      || activeWallet === constants.walletNames.ledger
    )
  )

  useEffect(() => {
    const handleChange = (token: string) => setGno(token === constants.tokens.gno)

    tokenField.on('change', handleChange)

    return () => {
      tokenField.off('change', handleChange)
    }
  }, [ isGno, tokenField ])

  return {
    isPermitSupported,
    setPermitDisabled,
  }
}


export default usePermitSupport
