import React from 'react'
import cx from 'classnames'
import Image from 'next/image'
import { useStore } from 'hooks'
import { constants, commonMessages } from 'helpers'

import { Flex, Box } from 'components/layout'
import { Text, CountUp } from 'components/ui'

import s from './TokenBalance.module.scss'


type TokenBalanceProps = {
  balance: string
  isLoading: boolean
  isSelected: boolean
  type: Extract<Tokens, 'GNO' | 'mGNO'>
  dataTestId?: string
  onClick: () => void
}

const storeSelector = (store: Store) => ({
  isInitialRequestLoading: store.ui.initialRequestLoading,
})

const TokenBalance: React.FC<TokenBalanceProps> = (props) => {
  const { type, balance, isLoading, isSelected, dataTestId, onClick } = props

  const { isInitialRequestLoading } = useStore(storeSelector)

  const containerClassName = cx(s.container, {
    [s.selected]: isSelected,
    [s.loading]: isLoading || isInitialRequestLoading,
  })

  return (
    <button
      className={containerClassName}
      type="button"
      data-testid={dataTestId}
      onClick={onClick}
    >
      <Box pv={8} ph={16} relative>
        <Box className={s.selectedTextContainer} alignCenter>
          <Text
            className={s.selectedText}
            message={commonMessages.selected}
            color="info"
            tag="span"
            size={12}
          />
        </Box>
        <Flex justify="start">
          <Box className={s.imageContainer} mr={8}>
            <Image
              src={constants.tokensImages[type]}
              height={16}
              width={16}
              alt=""
            />
          </Box>
          <Text
            className={s.text}
            color="default"
            tag="span"
            size={14}
          >
            <CountUp
              end={parseFloat(balance)}
              suffix={` ${type}`}
              duration={1.5}
              decimals={4}
              separator=","
            />
          </Text>
        </Flex>
      </Box>
    </button>
  )
}


export default React.memo(TokenBalance)
