export default {
  text: {
    en: `
      Your deposit will be routed through the {title} Pool {link}
      and will earn you a premium of {amount} {token}
    `,
    ru: `
      Ваш депозит будет направлен через пул {title} {link}
      и принесет Вам премию в размере {amount} {token}
    `,
    fr: `
      Votre dépôt sera dirigé vers le pool {title} {link}
      et vous aurez une prime de {amount} {token}
    `,
    es: `
      Su depósito será direccionado a través del Pool en {title} {link}
      y ganará una diferencia de {amount} {token}
    `,
    pt: `
      Seu depósito será encaminhado através do Pool {title} {link}
      e você ganhará um prêmio de {amount} {token}
    `,
    de: `
      Deine Einzahlung wird über den {title} Pool geleitet {link}
      und wird Dir einen Bonus von {amount} {token} einbringen
    `,
    zh: `
      您的存款將會透過 {title} 礦池存入 {link}
      並額外獲得 {amount} {token}
    `,
  },
}
