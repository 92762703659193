export default {
  errors: {
    noStats: {
      en: 'No stats for period from {from} to {to}',
      ru: 'Нет статистики за период с {from} по {to}',
      fr: 'Pas de statistique pour la période de {from} à {to}',
      es: 'Sin estadísticas para el período de {from} hasta {to}',
      pt: 'Nenhuma estatística para o período de {from} a {to}',
      de: 'Keine Statistiken für die Periode von {from} bis {to}',
      zh: '沒有從 {from} 到 {to} 的資料',
    },
  },
  format: {
    xlsx: {
      en: 'Microsoft Excel Document (xlsx)',
      ru: 'Документ Microsoft Excel (xlsx)',
      fr: 'Document Microsoft Excel (xlsx)',
      es: 'Documento de Microsoft Excel (xlsx)',
      pt: 'Documento do Microsoft Excel (xlsx)',
      de: 'Microsoft Excel Datei (xlsx)',
      zh: '微軟 Excel 文件 (xlsx)',
    },
    csv: {
      en: 'Comma-Separated Values (csv)',
      ru: 'Значения, разделенные запятыми (csv)',
      fr: 'Comma-Separated Values (csv)',
      es: 'Valores separados por coma (csv)',
      pt: 'Valores separados por vírgula (csv)',
      de: 'Komma-Separierte Werte (CSV)',
      zh: '逗號分隔的純文字文件 (csv)',
    },
  },
  file: {
    name: {
      en: 'Staking stats from {from} to {to}',
      ru: 'Статистика стейкинга с {from} по {to}',
      fr: 'Statistic de staking de {from} à {to}',
      es: 'Estadísticas de staking desde {from} hasta {to}',
      pt: 'Estatísticas de staking de {from} a {to}',
      de: 'Staking Statistik von {from} bis {to}',
      zh: '從 {from} 到 {to} 的質押資料',
    },
    headings: [
      {
        en: 'Reward ({token})',
        ru: 'Вознаграждение ({token})',
        fr: 'Récompenses ({token})',
        es: 'Ganancias ({token})',
        pt: 'Recompensa ({token})',
        de: 'Belohnung ({token})',
        zh: '獎勵 ({token})',
      },
      {
        en: 'Reward (USD)',
        ru: 'Вознаграждение (USD)',
        fr: 'Récompenses (USD)',
        es: 'Ganancias (USD)',
        pt: 'Recompensa (USD)',
        de: 'Belohnung (USD)',
        zh: '獎勵 (USD)',
      },
      {
        en: 'Date (MM/DD/YYYY)',
        ru: 'Дата (ММ/ДД/ГГГГ)',
        fr: 'Date (MM/JJ/AAAA)',
        es: 'Fecha (DD/MM/YYYY)',
        pt: 'Data (DD/MM/YYYY)',
        de: 'Datum (MM/TT/JJJJ)',
        zh: '日期 (MM/DD/YYYY)',
      },
    ],
  },
}
