import React from 'react'

import { Text } from 'components/ui'
import { Box } from 'components/layout'
import { FormState } from 'components/inputs'
import TokenAmountInput from 'compositions/TokenAmountInput/TokenAmountInput'

import useStake from './util/useStake'
import Tokens from '../../components/Tokens/Tokens'
import StakeInfo from '../../components/StakeInfo/StakeInfo'
import TermsCheckbox from '../../components/TermsCheckbox/TermsCheckbox'
import ConfirmButton from '../../components/ConfirmButton/ConfirmButton'
import { DepositSuccessModal } from '../../components/DepositSuccessModal/DepositSuccessModal'

import s from './Stake.module.scss'


const Stake: React.FC = () => {
  const {
    form,
    amountOut,
    stakedToken,
    depositToken,
    disabledMessage,
    isLoading,
    isDisabled,
    isUniswapFlow,
    onSubmit,
    onMaxButtonClick,
  } = useStake()

  return (
    <Box pb={16} ph={16}>
      <Tokens leftToken={depositToken} rightToken={stakedToken} />
      <Box className={s.depositInputContainer} mt={32} center>
        <TokenAmountInput
          loading={isLoading}
          token={depositToken}
          field={form.fields.deposit}
          disabled={Boolean(disabledMessage)}
          dataTestIdPrefix="stake-amount"
          onMaxButtonClick={onMaxButtonClick}
        />
      </Box>
      <StakeInfo
        token={depositToken}
        amountOut={amountOut}
        className={s.stakeInfo}
        isDepositDisabled={isDisabled}
        addressField={form.fields.address}
        depositField={form.fields.deposit}
        liquidityPool={isUniswapFlow ? 'uniswap' : null}
      />
      <Box className={s.checkboxContainer} mt={24} alignCenter>
        {
          Boolean(disabledMessage) ? (
            <Text
              message={disabledMessage as Intl.Message}
              size={16}
              color="danger"
              dataTestId="stake-wallet-error"
              html
            />
          ) : (
            <TermsCheckbox
              field={form.fields.termsAndConditions}
              dataTestId="stake-terms-checkbox"
            />
          )
        }
      </Box>
      <Box mt={24} alignCenter>
        <FormState form={form}>
          {
            ({ errors }) => (
              <ConfirmButton
                form={form}
                loading={isLoading}
                disabled={isDisabled || Boolean(errors)}
                dataTestId="stake-confirm-button"
                onClick={onSubmit}
              />
            )
          }
        </FormState>
      </Box>
      <DepositSuccessModal />
    </Box>
  )
}


export default React.memo(Stake)
