import { useCallback, useEffect } from 'react'
import { Field } from 'formular'
import { useConfig, useObjectState } from 'hooks'
import { parseEther } from '@ethersproject/units'
import { BigNumber } from '@ethersproject/bignumber'
import { actions as actionsHelpers, requests } from 'helpers'

import { calculateSGNO } from './helpers'


type State = {
  amountOut: string
  isNeedAllowance: boolean
}

type Input = {
  depositField: Field<string>
  getCurveExchange: (amountBN: BigNumber) => Promise<BigNumber | undefined>
}

const useReinvestData = ({ depositField, getCurveExchange }: Input) => {
  const { contracts, address, config } = useConfig()

  const [ { amountOut, isNeedAllowance }, setState ] = useObjectState<State>({
    amountOut: '0',
    isNeedAllowance: false,
  })

  const poolAddress = config.addresses.pools.curve.stakedTokenRewardToken
  const tokenContract = contracts?.tokens.default.rewardTokenContract

  const handleFetchAllowance = useCallback((amount: string) => {
    if (address && tokenContract) {
      return requests.allowance({
        amount,
        tokenContract,
        sender: address,
        recipient: poolAddress,
        wrongAmount: '10000000',
      })
    }

    return Promise.reject()
  }, [ address, tokenContract, poolAddress ])

  const handleFetchAmountOut = useCallback(async (deposit: string) => {
    const amountBN = parseEther(deposit)

    return calculateSGNO({
      amountBN,
      getCurveExchange,
    })
  }, [ getCurveExchange ])

  const checkIsValidAmount = useCallback((depositFieldState) => (
    Boolean(
      Number(depositFieldState.value)
      && !depositFieldState.isValidating
      && (
        depositFieldState.isValid
        || !depositFieldState.error
      )
    )
  ), [])

  useEffect(() => {
    const fetchData = actionsHelpers.debounce((depositFieldState: Record<string, string | boolean>) => {
      if (checkIsValidAmount(depositFieldState)) {
        const value = depositFieldState.value as string

        Promise.all([
          handleFetchAmountOut(value),
          handleFetchAllowance(value),
        ])
          .then(([ amountOut, isNeedAllowance ]) => {
            if (checkIsValidAmount(depositFieldState)) {
              setState({
                amountOut,
                isNeedAllowance,
              })
            }
          })
      }
      else {
        setState({
          amountOut: '0',
          isNeedAllowance: false,
        })
      }
    }, 150)

    depositField.on('state change', fetchData)

    return () => {
      depositField.off('state change', fetchData)
    }
  }, [ depositField, checkIsValidAmount, handleFetchAllowance, handleFetchAmountOut, setState ])

  const updateAllowance = useCallback(() => {
    const value = depositField.state.value as string

    handleFetchAllowance(value)
      .then((isNeedAllowance) => {
        setState({ isNeedAllowance })
      })
  }, [ depositField, handleFetchAllowance, setState ])

  const setNeedAllowance = useCallback((isNeedAllowance) => {
    setState({ isNeedAllowance })
  }, [ setState ])

  return {
    amountOut,
    isNeedAllowance,
    setNeedAllowance,
    updateAllowance,
  }
}


export default useReinvestData
