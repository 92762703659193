export default {
  title: {
    en: 'Activation time',
    ru: 'Время активации',
    fr: 'Temps d\'activation',
    es: 'Tiempo de activación',
    pt: 'Tempo de ativação',
    de: 'Aktivierungszeit',
    zh: '已啟用時間',
  },
  immediate: {
    en: 'Immediate',
    ru: 'Сразу',
    fr: 'Immédiat',
    es: 'Inmediato',
    pt: 'Imediato',
    de: 'Sofort',
    zh: '立即生效',
  },
  tooltips: {
    immediate: {
      en: 'You will receive sETH2 tokens immediately upon deposit.',
      ru: 'Вы получите токены sETH2 сразу после внесения.',
      fr: 'Vous recevrez des jetons sETH2 immédiatement après le dépôt',
      es: 'Usted recibirá tokens de sETH2 inmediatamente al depositar.',
      pt: 'Você receberá tokens sETH2 imediatamente após o depósito',
      de: 'Du erhältst sETH2 Tokens sofort nach der Einzahlung',
      zh: '當您存入後會立即獲得 sETH2 代幣',
    },
  },
}
