import React from 'react'
import Image from 'next/image'
import { useDevice } from 'device'

import { Box, Flex } from 'components/layout'
import { Tooltip } from 'components/feedback'
import { QuestionIcon, Text, CountUp } from 'components/ui'

import s from './Item.module.scss'


type ItemProps = {
  value: number
  suffix?: string
  decimals: number
  text: Intl.Message
  image?: StaticImage
  tooltip?: Intl.Message
  valueDataTestId?: string
  textDataTestId?: string
  tooltipDataTestId?: string
}

const Item: React.FC<ItemProps> = (props) => {
  const {
    value, text, decimals, suffix, image, tooltip,
    valueDataTestId, textDataTestId, tooltipDataTestId,
  } = props

  const { isMobile } = useDevice()

  return (
    <Box pv={16}>
      <Flex justify="center">
        {
          Boolean(image) && (
            <Box mr={4} lineHeight0>
              <Image
                src={image as StaticImage}
                width={20}
                height={20}
                alt="image"
              />
            </Box>
          )
        }
        <Text
          size={isMobile ? 16 : 20}
          color="default"
          dataTestId={valueDataTestId}
        >
          <CountUp
            end={value}
            decimals={decimals}
            duration={1.5}
            separator=","
            suffix={suffix}
          />
        </Text>
      </Flex>
      <Box mt={4} noWrapper>
        <Flex justify="center">
          <Text
            className={s.text}
            color="dark-gray"
            message={text}
            align="center"
            size={isMobile ? 12 : 16}
            dataTestId={textDataTestId}
          />
          {
            Boolean(tooltip) && (
              <Tooltip
                content={tooltip}
                center
                dataTestId={tooltipDataTestId}
              >
                <Box ml={4}>
                  <QuestionIcon background="gray" size={14} />
                </Box>
              </Tooltip>
            )
          }
        </Flex>
      </Box>
    </Box>
  )
}


export default React.memo(Item)
