import { useConfig } from 'hooks'
import type { Form } from 'formular'

import { useSubmitDisable } from '../../../util'

import useSubmit from './useSubmit'
import useReinvestForm from './useReinvestForm'
import useReinvestData from './useReinvestData'
import usePermitSupport from './usePermitSupport'


type Output = {
  form: Form<Reinvest.Form>
  amountOut: string
  disabledMessage: null | Intl.Message
  rewardToken: Config['tokens']['rewardToken']
  stakedToken: Config['tokens']['stakedToken']
  isLoading: boolean
  isDisabled: boolean
  isNeedApprove: boolean
  onMaxButtonClick: () => void
  onSubmit: () => void
}

const useReinvest = (): Output => {
  const { config } = useConfig()
  const { form, handleMaxButtonClick } = useReinvestForm()
  const { disabledMessage, isSubmitDisabled } = useSubmitDisable()
  const { isPermitSupported, setPermitDisabled } = usePermitSupport()

  const { amountOut, isLoading, isNeedAllowance, updateAllowance, setNeedAllowance } = useReinvestData({
    depositField: form.fields.amount,
  })

  const { isNeedApprove, isSubmitting, submit } = useSubmit({
    form,
    amountOut,
    isNeedAllowance,
    isPermitSupported,
    updateAllowance,
    setNeedAllowance,
    setPermitDisabled,
  })

  const isDisabled = isSubmitDisabled || isSubmitting || !Number(amountOut)

  return {
    form,
    amountOut,
    isLoading,
    isDisabled,
    isNeedApprove,
    disabledMessage,
    rewardToken: config.tokens.rewardToken,
    stakedToken: config.tokens.stakedToken,
    onSubmit: submit,
    onMaxButtonClick: handleMaxButtonClick,
  }
}


export default useReinvest
