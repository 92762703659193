import { useConfig } from 'hooks'
import type { Form } from 'formular'

import { useSubmitDisable } from '../../../util'

import useSubmit from './useSubmit'
import useStakeForm from './useStakeForm'
import useStakeData from './useStakeData'
import useMaxButtonClick from './useMaxButtonClick'


type Output = {
  form: Form<Deposit.Form>
  amountOut: string
  disabledMessage: null | Intl.Message
  stakedToken: Config['tokens']['stakedToken']
  depositToken: Config['tokens']['depositToken']
  isLoading: boolean
  isDisabled: boolean
  isUniswapFlow: boolean
  onSubmit: () => Promise<void>
  onMaxButtonClick: () => void
}

const useStake = (): Output => {
  const { config } = useConfig()
  const { disabledMessage, isSubmitDisabled } = useSubmitDisable()

  const { form } = useStakeForm()

  const {
    amountOut,
    submitParams,
    isUniswapFlow,
    fetchDepositData,
  } = useStakeData({
    addressField: form.fields.address,
    depositField: form.fields.deposit,
  })

  const { isMaxDepositFetching, handleMaxButtonClick } = useMaxButtonClick({
    depositField: form.fields.deposit,
    fetchDepositData,
  })

  const { submit, isSubmitting } = useSubmit({
    form,
    submitParams,
    isUniswapFlow,
    isSubmitDisabled,
  })

  const isDisabled = isSubmitDisabled || isSubmitting || !Number(amountOut)
  const isLoading = isMaxDepositFetching

  return {
    form,
    amountOut,
    disabledMessage,
    stakedToken: config.tokens.stakedToken,
    depositToken: config.tokens.depositToken,

    isLoading,
    isDisabled,
    isUniswapFlow,

    onSubmit: submit,
    onMaxButtonClick: handleMaxButtonClick,
  }
}


export default useStake
