export default {
  text: {
    en: `
      I agree with the
      <a href="https://stakewise.io/terms-and-conditions/" target="_blank" rel="noreferrer nofollow">
        Terms & Conditions
      </a>
    `,
    ru: `
      Я согласен с
      <a href="https://stakewise.io/terms-and-conditions/" target="_blank" rel="noreferrer nofollow">
        Условиями Использования
      </a>
    `,
    fr: `
      J'accepte les
      <a href="https://stakewise.io/terms-and-conditions/" target="_blank" rel="noreferrer nofollow">
        Termes et Conditions
      </a>
    `,
    es: `
      Estoy de acuerdo con los
      <a href="https://stakewise.io/terms-and-conditions/" target="_blank" rel="noreferrer nofollow">
        Términos y condiciones
      </a>
    `,
    pt: `
      Concordo com os
      <a href="https://stakewise.io/terms-and-conditions/" target="_blank" rel="noreferrer nofollow">
      Termos e Condições
      </a>
    `,
    de: `
      Ich stimme den
      <a href="https://stakewise.io/terms-and-conditions/" target="_blank" rel="noreferrer nofollow">
        Teilnahme- und Geschäftsbedingungen
      </a>zu
    `,
    zh: `
      我同意
      <a href="https://stakewise.io/terms-and-conditions/" target="_blank" rel="noreferrer nofollow">
        合約條款 & 條件
      </a>
    `,
  },
}
