import React from 'react'
import { useStore } from 'hooks'

import { Box, Flex } from 'components/layout'
import { Text, CountUp } from 'components/ui'
import CardWithIcon from 'compositions/cards/CardWithIcon/CardWithIcon'

import messages from './messages'


const storeSelector = (store: Store) => ({
  stakingAPR: store.system.validators.stakingAPR,
  isInitialRequestLoading: store.ui.initialRequestLoading,
})

const AprCard: React.FC = () => {
  const { stakingAPR, isInitialRequestLoading } = useStore(storeSelector)

  const value = Math.max(stakingAPR, 0)

  return (
    <CardWithIcon
      iconColor="info"
      borderColor="blue"
      title={messages.title}
      bottomNote={messages.note}
      iconName="fa-solid fa-rocket-launch"
      isLoading={isInitialRequestLoading}
      dataTestIdPrefix="apr"
      contentNode={
        <Box mt={4} noWrapper>
          <Flex justify="end" align="center">
            <Text
              color="default"
              size={24}
              light
              dataTestId="apr-value"
            >
              <CountUp
                end={value}
                decimals={2}
                duration={1.5}
                suffix={` %`}
              />
            </Text>
          </Flex>
        </Box>
      }
    />
  )
}


export default React.memo(AprCard)
