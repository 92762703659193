export default {
  total: {
    en: 'Total {depositToken} in Pool',
    ru: 'Всего {depositToken} в Пуле',
    fr: 'Total des {depositToken} dans le pool',
    es: 'Total de {depositToken} en el Pool',
    pt: '{depositToken} total na reserva',
    de: 'Anzahl {depositToken} im Pool',
    zh: '礦池中的 {depositToken} 總數量',
  },
  fee: {
    en: 'Staking Fee',
    ru: 'Комиссия',
    fr: 'Frais de staking',
    es: 'Tarifa de Staking',
    pt: 'Taxa de staking',
    de: 'Staking Gebühr',
    zh: '質押費用',
  },
  feeTooltip: {
    en: 'Staking fee will be deducted from the pool\'s total staking reward',
    ru: 'Комиссия за стейкинг будет получена из общего вознаграждения стейкинг пула',
    fr: 'Les frais de staking seront déduis des récompenses totales du pool',
    es: 'La tarifa de staking será deducida de las ganancias totales del pool',
    pt: 'A taxa de staking será deduzida da recompensa total de staking da reserva',
    de: 'Staking Gebühren werden von den Pool Staking Belohnungen abgezogen',
    zh: '質押費用會從礦池中的總質押收益中扣除',
  },
}
