import React from 'react'
import { useConfig } from 'hooks'
import { modifiers } from 'helpers'
import { useFieldState } from 'formular'
import type { Field } from 'formular'

import { Text } from 'components/ui'
import { Box } from 'components/layout'

import SwapPremiumIcon from './SwapPremiumIcon/SwapPremiumIcon'
import RowWrapper from '../components/RowWrapper/RowWrapper'

import messages from './messages'


export type ReceiveRowProps = {
  amountOut: string
  isDepositDisabled: boolean
  depositField: Field<string>
  liquidityPool: 'curve' | 'uniswap' | null
}

const ReceiveRow: React.FC<ReceiveRowProps> = (props) => {
  const { amountOut, depositField, isDepositDisabled, liquidityPool } = props

  const { config } = useConfig()
  const { value } = useFieldState(depositField)
  const formattedValue = modifiers.formatTokenValue({ value: amountOut })

  const showPremiumIcon = Boolean(!isDepositDisabled && liquidityPool)
  const message = `${formattedValue} ${config.tokens.stakedToken}`

  return (
    <RowWrapper
      title={messages.title}
      dataTestId="stake-received-item"
    >
      <Box mr={4} noWrapper>
        <Text
          message={message}
          color="default"
          size={14}
          dataTestId="stake-received-amount"
        />
      </Box>
      {
        showPremiumIcon && (
          <SwapPremiumIcon
            amountOut={amountOut}
            amount={value}
            liquidityPool={liquidityPool}
          />
        )
      }
    </RowWrapper>
  )
}


export default React.memo(ReceiveRow)
