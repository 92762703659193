import sendDeposit from './sendDeposit'
import getIsUniswapFlow from './getIsUniswapFlow'
import fetchUniswapData from './fetchUniswapData'


export default {
  sendDeposit,
  getIsUniswapFlow,
  fetchUniswapData,
}
