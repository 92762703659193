export default {
  notifications: {
    // TODO - need a translation
    failedBalances: {
      en: 'Failed to load your account balances. Try refreshing the page.',
      ru: 'Не удалось загрузить балансы вашего аккаунта. Попробуйте обновить страницу.',
      fr: 'Failed to load your account balances. Try refreshing the page.',
      es: 'Failed to load your account balances. Try refreshing the page.',
      pt: 'Failed to load your account balances. Try refreshing the page.',
      de: 'Failed to load your account balances. Try refreshing the page.',
      zh: 'Failed to load your account balances. Try refreshing the page.',
    },
  },
  loaders: {
    waitingConfirmation: {
      // TODO Make abstract common texts for all waiting events
      en: 'Waiting for the deposit confirmation',
      ru: 'Ожидаем подтверждения депозита',
      fr: 'Attente de la confirmation',
      es: 'Esperando la confirmación del depósito',
      pt: 'Waiting for deposit confirmation',
      de: 'Warte auf die Bestätigung',
      zh: '等待確認',
    },
  },
}
