import React from 'react'
import { Field } from 'formular'
import { modalWrapper } from 'modal'

import { Box } from 'components/layout'
import { Text } from 'components/ui'
import { Modal } from 'components/feedback'
import { Button, Input, Select } from 'components/inputs'

import useReportModal, { FormatOptions } from './util/useReportModal'

import messages from './messages'
import s from './ReportModal.module.scss'


type FormFields = Array<{
  title: Intl.Message
  field: Field<string>
  options?: FormatOptions
}>

export const [ ReportModal, openReportModal ] = (
  modalWrapper(UNIQUE_FILE_ID, (props) => {
    const { closeModal } = props
    const { form, fileName, formatOptions, content, error } = useReportModal()

    const formFields: FormFields = [
      {
        title: messages.from,
        field: form.fields.from,
      },
      {
        title: messages.to,
        field: form.fields.to,
      },
      {
        title: messages.format,
        field: form.fields.format,
        options: formatOptions,
      },
    ]

    return (
      <Modal className={s.modal} analyticsName="staking stats" closeModal={closeModal}>
        <Box mt={16}>
          <Text
            message={messages.title}
            color="default"
            align="center"
            size={24}
          />
        </Box>
        <Box mt={24}>
          {
            formFields.map(({ title, field, options }, index) => (
              <Box
                key={index}
                className={s.fields}
                mt={index ? 16 : undefined}
              >
                <Text
                  message={title}
                  size={18}
                  color="default"
                />
                <Box className={s.row} ml={8}>
                  {
                    Boolean(options) ? (
                      <Select
                        field={field}
                        options={options as FormatOptions}
                      />
                    ) : (
                      <Input
                        field={field}
                        mask="date"
                        placeholder={messages.placeholder}
                      />
                    )
                  }
                </Box>
              </Box>
            ))
          }
          <Box mt={24} alignCenter>
            {
              Boolean(error) && (
                <Box mb={16}>
                  <Text
                    message={error as string}
                    size={14}
                    color="danger"
                    align="center"
                  />
                </Box>
              )
            }
            <a
              href={content}
              download={fileName}
              target="_blank"
              rel="noreferrer nofollow"
            >
              <Button
                message={messages.buttonTitle}
                color="blue"
                size="md"
                disabled={!content}
                fullWidthOnMobile
              />
            </a>
          </Box>
        </Box>
      </Modal>
    )
  })
)
