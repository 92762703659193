import React from 'react'
import { Field, useFieldState } from 'formular'
import { parseEther, formatEther } from '@ethersproject/units'

import useAmountOut from '../util/useAmountOut'
import ReceiveRow from '../../../components/ReceiveRow/ReceiveRow'


type ReceiveRowProps = {
  tokenIn: Tokens
  tokenOut: Tokens
  amountField: Field<string>
}

const ReceiveRowWrapper: React.FC<ReceiveRowProps> = (props) => {
  const { amountField, tokenIn, tokenOut } = props

  const { calculateAmountOut } = useAmountOut()
  const { value: amountIn } = useFieldState(amountField)

  if (!amountIn) {
    return null
  }

  const amountBN = parseEther(amountIn)
  const amountOutBN = calculateAmountOut(amountBN, tokenOut)
  const amountOut = formatEther(amountOutBN)

  return (
    <ReceiveRow
      token={tokenIn}
      value={amountOut}
    />
  )
}


export default React.memo(ReceiveRowWrapper)
