export default {
  // TODO - need a translation
  note: {
    en: 'It could take up to 7 days for your order to execute',
    ru: 'Исполнение вашего заказа может занять до 7 дней',
  },
  // TODO - need a translation
  orders: {
    en: 'Orders: {ordersCount}',
  },
}
