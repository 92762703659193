import { useCallback, useEffect, useState } from 'react'
import { Form } from 'formular'
import { ContractTransaction } from 'ethers'
import { useActions, useBalances, useConfig } from 'hooks'
import { actions as helpersActions, analytics, commonMessages, constants } from 'helpers'
import { openNotification } from 'notifications'

import { openApproveModal } from '../../../components/ApproveModal/ApproveModal'

import messages from './messages'


type Input = {
  form: Form<GnosisReinvest.Form>
  isNeedAllowance: boolean
  updateAllowance: () => void
  setNeedAllowance: (isNeedAllowance: boolean) => void
  handleCurveApprove: (amount: string) => Promise<ContractTransaction | undefined>
  handleCurveExchange: (amount: string) => Promise<ContractTransaction | undefined>
}

const useSubmit = (props: Input) => {
  const {
    form, isNeedAllowance,
    updateAllowance, setNeedAllowance, handleCurveApprove, handleCurveExchange,
  } = props

  const actions = useActions()
  const { config, contracts, address, library, activeWallet } = useConfig()
  const { fetchAndSetRewardTokenBalance, fetchAndSetStakedTokenBalance } = useBalances()

  const [ isSubmitting, setSubmitting ] = useState(false)

  const isSubmitDisabled = (
    !library
    || !address
    || !contracts
    || !activeWallet
    || isSubmitting
  )

  useEffect(() => {
    if (isSubmitting) {
      actions.ui.setBottomLoader({
        content: messages.loaders.waitingConfirmation,
      })

      return () => {
        actions.ui.resetBottomLoader()
      }
    }
  }, [ actions, isSubmitting ])

  const handleError = useCallback((error: Error) => {
    analytics.sentry.exception('Gnosis reinvest send transaction error', error)

    openNotification({
      type: 'error',
      text: commonMessages.notifications.failed,
    })
  }, [])

  const handleApprove = useCallback(async (amount: string) => {
    if (!library || !address || !contracts) {
      return
    }

    return handleCurveApprove(amount)
      .then((result) => (
        helpersActions.handleTransaction({
          sentText: commonMessages.notifications.sent,
          txHash: result?.hash,
          library,
          actions,
          config,
        })
      ))
  }, [ config, actions, address, library, contracts, handleCurveApprove ])

  const handleSubmitTransaction = useCallback(async (result) => {
    if (!library) {
      return
    }

    form.fields.amount.set('')

    const callback = () => {
      fetchAndSetRewardTokenBalance()
      fetchAndSetStakedTokenBalance()

      openNotification({
        type: 'success',
        text: commonMessages.notifications.success,
      })
    }

    return helpersActions.handleTransaction({
      withConfirmedNotification: false,
      txHash: result?.hash,
      sentText: messages.notifications.transactionSent,
      config,
      library,
      actions,
      callback,
    })
  }, [ form, config, actions, library, fetchAndSetRewardTokenBalance, fetchAndSetStakedTokenBalance ])

  const handleSubmit = useCallback(async (amount: string) => {
    if (!library || !address) {
      return
    }

    analytics.sentry.breadcrumb({
      message: 'send reinvest transaction',
      data: {
        amount,
        isNeedAllowance,
      },
    })

    return handleCurveExchange(amount)
      .then((result) => handleSubmitTransaction(result))
      .catch((error) => {
        const errorCode = error?.data?.originalError?.code || error?.code

        // code 4001: User canceled transaction
        if (errorCode === 4001) {
          updateAllowance()

          openApproveModal({
            type: 'reinvest',
            token: constants.tokens.rgno,
            onClick: () => handleSubmit(amount),
          })
        }
        else {
          console.warn(error)
          handleError(error)
        }
      })
  }, [
    address,
    library,
    handleError,
    isNeedAllowance,
    updateAllowance,
    handleCurveExchange,
    handleSubmitTransaction,
  ])

  const submit = useCallback(async () => {
    if (isSubmitDisabled) {
      return
    }

    const { values, errors } = await form.submit()

    if (errors) {
      return
    }

    const { amount } = values

    setSubmitting(true)

    if (isNeedAllowance) {
        handleApprove(amount)
        .then(
          () => handleSubmit(amount),
          (error) => {
            const errorCode = error?.data?.originalError?.code || error?.code

            console.warn(error)

            // code -32603: Reverted error.
            // There is some problem with approved tokens on the Gnosis network. At some point,
            // approve may stop working. In this case, you need to approve them again.
            if (errorCode === -32603) {
              setNeedAllowance(true)
            }

            handleError(error)
          }
        )
        .finally(() => setSubmitting(false))
    }
    else {
      handleSubmit(amount)
        .finally(() => setSubmitting(false))
    }
  }, [
    form,
    isNeedAllowance,
    isSubmitDisabled,
    handleError,
    handleSubmit,
    handleApprove,
    setNeedAllowance,
  ])

  return {
    isNeedApprove: isNeedAllowance,
    isSubmitting,
    submit,
  }
}


export default useSubmit
