import React from 'react'
import { commonMessages } from 'helpers'

import { Box } from 'components/layout'
import { Text } from 'components/ui'

import TokenAmountInput from 'compositions/TokenAmountInput/TokenAmountInput'

import Tokens from '../../components/Tokens/Tokens'
import ReceiveRow from '../../components/ReceiveRow/ReceiveRow'
import TermsCheckbox from '../../components/TermsCheckbox/TermsCheckbox'
import ConfirmButton from '../../components/ConfirmButton/ConfirmButton'
import { ApproveModal } from '../../components/ApproveModal/ApproveModal'
import LiquidityPoolNote from '../../components/LiquidityPoolNote/LiquidityPoolNote'

import useReinvest from './util/useReinvest'

import s from './Reinvest.module.scss'


const Reinvest: React.FC = () => {
  const {
    form,
    amountOut,
    rewardToken,
    stakedToken,
    isNeedApprove,
    disabledMessage,
    isDisabled,
    onSubmit,
    onMaxButtonClick,
  } = useReinvest()

  const approveButtonTitle = {
    ...commonMessages.buttons.approve,
    values: { token: rewardToken },
  }

  return (
    <Box pb={16} ph={16}>
      <Tokens leftToken={rewardToken} rightToken={stakedToken} size={70} />
      <Box className={s.depositInputContainer} mt={32} center>
        <TokenAmountInput
          token={rewardToken}
          field={form.fields.amount}
          dataTestIdPrefix="reinvest-amount"
          disabled={Boolean(disabledMessage)}
          onMaxButtonClick={onMaxButtonClick}
        />
      </Box>
      <div className={s.info}>
        <ReceiveRow value={amountOut} token={stakedToken} />
        <LiquidityPoolNote className={s.note} liquidityPool="curve" />
      </div>
      <Box className={s.checkboxContainer} mt={24} alignCenter>
        {
          Boolean(disabledMessage) ? (
            <Text
              message={disabledMessage as Intl.Message}
              size={16}
              color="danger"
              dataTestId="reinvest-wallet-error"
              html
            />
          ) : (
            <TermsCheckbox
              field={form.fields.termsAndConditions}
              dataTestId="reinvest-terms-checkbox"
            />
          )
        }
      </Box>
      <Box mt={24} alignCenter>
        <ConfirmButton
          form={form}
          disabled={isDisabled}
          dataTestId="reinvest-confirm-button"
          title={isNeedApprove ? approveButtonTitle : undefined}
          onClick={onSubmit}
        />
      </Box>
      <ApproveModal />
    </Box>
  )
}


export default React.memo(Reinvest)
