export default {
  deposit: {
    en: 'Deposit {token}',
    ru: 'Внести {token}',
    fr: 'Déposez {token}',
    es: 'Depositar {token}',
    pt: 'Depositar {token}',
    de: 'Zahle {token} ein',
    zh: '存入 {token}',
  },
  receive: {
    en: 'Receive {token}',
    ru: 'Получить {token}',
    fr: 'Recevez {token}',
    es: 'Recibir {token}',
    pt: 'Receber {token}',
    de: 'Empfange {token}',
    zh: '獲得 {token}',
  },
}
