export default {
  title: {
    en: 'You receive',
    ru: 'Вы получите',
    fr: 'Vous recevrez',
    es: 'Usted recibe',
    pt: 'Você recebe',
    de: 'Du erhältst',
    zh: '您收到',
  },
}
