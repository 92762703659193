import React from 'react'
import cx from 'classnames'
import { useSelectedLanguage } from 'hooks'

import { Box, Flex } from 'components/layout'
import Card from 'compositions/cards/Card/Card'
import { RoundIcon, Icon, Text } from 'components/ui'
import type { RoundIconProps, IconProps } from 'components/ui'

import s from './CardWithIcon.module.scss'


export type CardWithIconProps = {
  className?: string
  title: Intl.Message | string
  contentNode: React.ReactNode
  iconType?: RoundIconProps['token']
  iconColor?: IconProps['color']
  iconName?: string
  bottomNote: Intl.Message | string
  bottomNode?: React.ReactNode
  isLoading?: boolean
  borderColor?: 'green' | 'dark-green' | 'red' | 'blue' | 'orange'
  dataTestIdPrefix?: string
}


const CardWithIcon: React.FC<CardWithIconProps> = (props) => {
  const {
    title,
    iconType,
    iconName,
    className,
    iconColor,
    isLoading,
    bottomNote,
    bottomNode,
    borderColor,
    contentNode,
    dataTestIdPrefix = '',
  } = props

  const { isGerman } = useSelectedLanguage()

  const cardClassName = cx(s.card, className, {
    [s.loading]: isLoading,
    [s[`border-color-${borderColor}`]]: borderColor,
  })

  return (
    <Card className={cardClassName} dataTestId={`${dataTestIdPrefix}-widget`}>
      <Box className={s.content} p={16} relative noWrapper>
        <Flex align="start">
          {
            Boolean(iconType) && (
              <RoundIcon token={iconType} size={52} shadow />
            )
          }
          {
            Boolean(iconName && iconColor) && (
              <RoundIcon size={52} shadow>
                <Icon
                  name={iconName as string}
                  color={iconColor}
                  size={24}
                />
              </RoundIcon>
            )
          }
          <Box alignRight>
            <Text
              className={s.text}
              message={title}
              dataTestId={`${dataTestIdPrefix}-header`}
            />
            {contentNode}
          </Box>
        </Flex>
      </Box>
      <Box pv={12} ph={16} noWrapper>
        <Flex>
          <Text
            className={s.text}
            message={bottomNote}
            size={isGerman ? 12 : 14}
            dataTestId={`${dataTestIdPrefix}-description`}
          />
          {
            Boolean(bottomNode) && (
              <Box className={s.bottomNode} ml={16}>
                {bottomNode}
              </Box>
            )
          }
        </Flex>
      </Box>
    </Card>
  )
}


export default CardWithIcon
