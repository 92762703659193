import React from 'react'

import { Flex } from 'components/layout'
import Card from 'compositions/cards/Card/Card'

import Map from './Map/Map'
import Statistic from './Statistic/Statistic'

import s from './InfoCard.module.scss'


const InfoCard: React.FC = () => {

  return (
    <Card className={s.card} dataTestId="validators-map-widget">
      <Flex direction="column" fullHeight>
        <Map />
        <Statistic />
      </Flex>
    </Card>
  )
}


export default React.memo(InfoCard)
