import { useCallback, useEffect } from 'react'
import { openNotification } from 'notifications'
import { formatEther } from '@ethersproject/units'
import { useBalances, useConfig, useNewTotalStakingAmount, useObjectState } from 'hooks'

import messages from './messages'


type State = {
  gnoTokenBalance: string
  mgnoTokenBalance: string
  isBalancesFetching: boolean
}

const useGnosisBalances = () => {
  const fetchTotalStakingAmount = useNewTotalStakingAmount()
  const { fetchAndSetStakedTokenBalance } = useBalances()
  const { contracts, address } = useConfig()

  const [ state, setState ] = useObjectState<State>({
    gnoTokenBalance: '0',
    mgnoTokenBalance: '0',
    isBalancesFetching: Boolean(address),
  })

  const { gnoTokenBalance, mgnoTokenBalance, isBalancesFetching } = state

  const fetchGnoBalances = useCallback(async (address: string) => {
    const alternativeDepositToken = contracts?.tokens.default.alternativeDepositTokenContract
    const depositTokenContract = contracts?.tokens.default.depositTokenContract

    if (alternativeDepositToken && depositTokenContract) {
      try {
        setState({ isBalancesFetching: true })

        const [ mgnoTokenBalanceBN, gnoTokenBalanceBN ] = await Promise.all([
          alternativeDepositToken.balanceOf(address),
          depositTokenContract.balanceOf(address),
        ])

        setState({
          gnoTokenBalance: formatEther(gnoTokenBalanceBN),
          mgnoTokenBalance: formatEther(mgnoTokenBalanceBN),
          isBalancesFetching: false,
        })
      }
      catch (error) {
        setState({ isBalancesFetching: false })

        openNotification({
          type: 'warning',
          text: messages.notifications.failedBalances,
        })
      }
    }
  }, [ contracts, setState ])

  const fetchBalances = useCallback(() => {
    if (address) {
      fetchGnoBalances(address)
      fetchTotalStakingAmount()
      fetchAndSetStakedTokenBalance()
    }
  }, [ address, fetchGnoBalances, fetchTotalStakingAmount, fetchAndSetStakedTokenBalance ])

  useEffect(() => {
    if (address) {
      fetchGnoBalances(address)
    }
  }, [ address, fetchGnoBalances ])

  return {
    gnoTokenBalance,
    mgnoTokenBalance,
    isBalancesFetching,
    fetchBalances,
  }
}


export default useGnosisBalances
