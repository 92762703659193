import React, { useMemo } from 'react'
import { modalWrapper } from 'modal'
import { commonMessages } from 'helpers'

import { Box } from 'components/layout'
import { Button } from 'components/inputs'
import { Text, Icon } from 'components/ui'
import { Modal } from 'components/feedback'

import messages from './messages'
import s from './ApproveModal.module.scss'


type ApproveModalProps = {
  token: Tokens
  type: 'reinvest' | 'stake' | 'batchStake' | 'batchReinvest'
  onClick: () => void
}

export const [ ApproveModal, openApproveModal ] = (
  modalWrapper<ApproveModalProps>(UNIQUE_FILE_ID, (props) => {
    const { token, type, closeModal, onClick } = props

    const handleButtonClick = () => {
      if (typeof onClick === 'function') {
        onClick()
      }

      closeModal()
    }

    const text = useMemo(() => {
      if (type === 'reinvest' || type === 'batchReinvest') {
        return {
          ...messages.reinvestText,
          values: { token },
        }
      }

      return {
        ...messages.stakeText,
        values: { token },
      }
    }, [ type, token ])

    const isBatchError = type === 'batchStake' || type === 'batchReinvest'

    const buttonTitle = isBatchError
      ? { ...commonMessages.buttons.approve, values: { token } }
      : commonMessages.buttons.confirm

    const analyticsName = {
      reinvest: 'reinvest error modal',
      stake : 'stake error modal',
      batchStake : 'batchStake error modal',
      batchReinvest : 'batchReinvest error modal',
    }[type]

    const dataTestId = {
      reinvest: 'reinvest-error-modal',
      stake : 'stake-error-modal',
      batchStake : 'batch-stake-error-modal',
      batchReinvest : 'batch-reinvest-error-modal',
    }[type]

    return (
      <Modal
        className={s.modal}
        analyticsName={analyticsName}
        closeModal={closeModal}
      >
        <Box pv={16}>
          <Text
            message={messages.title}
            color="default"
            align="center"
            size={24}
          />
          <Box mt={24} alignCenter>
            <Icon
              name="fa-solid fa-triangle-exclamation"
              color="warning"
              size={40}
            />
          </Box>
          <Box pv={16}>
            <Text
              message={text}
              color="default"
              align="center"
              size={16}
            />
          </Box>
          <Box mt={24} alignCenter>
            <Button
              message={buttonTitle}
              color="orange"
              dataTestId={dataTestId}
              onClick={handleButtonClick}
            />
          </Box>
        </Box>
      </Modal>
    )
  })
)
