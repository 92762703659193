import React from 'react'
import { useConfig } from 'hooks'
import { modalWrapper } from 'modal'
import { LimitOrder } from '@1inch/limit-order-protocol'

import { Text } from 'components/ui'
import { Box } from 'components/layout'
import { Modal } from 'components/feedback'

import Order from './Order/Order'
import useCancelOrder from './util/useСancelOrder'

import s from './OrdersModal.module.scss'
import messages from './messages'


type OrdersModalProps = {
  orders: Array<{
    signature: string
    orderHash: string
    data: LimitOrder
  }>
  removeOrder: (signature: string) => void
}

export const [ OrdersModal, openOrdersModal ] = (
  modalWrapper<OrdersModalProps>(UNIQUE_FILE_ID, (props) => {
    const { orders, removeOrder, closeModal } = props

    const { config } = useConfig()
    const { isLoading, cancelOrder } = useCancelOrder({ removeOrder, closeModal })

    return (
      <Modal
        className={s.modal}
        analyticsName="withdraw orders modal"
        closeModal={isLoading ? null : closeModal}
      >
        <Box pv={16}>
          <Text
            message={messages.title}
            color="default"
            align="center"
            size={24}
          />
        </Box>
        <Box mt={24}>
          {
            orders.map((order) => {
              const isStaked = order.data.makerAsset === config.addresses.tokens.default.staked.toLowerCase()
              const makingToken = isStaked ? config.tokens.stakedToken : config.tokens.rewardToken

              return (
                <Order
                  key={order.signature}
                  order={order}
                  makingToken={makingToken}
                  cancelOrder={cancelOrder}
                />
              )
            })
          }
        </Box>
      </Modal>
    )
  })
)
