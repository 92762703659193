import { useEffect, useRef, useState } from 'react'

import type { WorkBook, WritingOptions, XLSX$Utils } from 'xlsx'


const useXLSX = () => {
  const [ isLoading, setLoading ] = useState(true)

  const xlsxRef = useRef<{ utils: XLSX$Utils; write: ((data: WorkBook, opts?: WritingOptions | undefined) => any); }>()

  useEffect(() => {
    (async () => {
      const xlsx = await import('xlsx')

      xlsxRef.current = {
        utils: xlsx.utils,
        write: xlsx.write,
      }

      setLoading(false)
    })()
  }, [])

  return {
    utils: xlsxRef.current?.utils,
    write: xlsxRef.current?.write,
    isLoading,
  }
}


export default useXLSX
