import { useCallback, useEffect, useRef } from 'react'
import { useConfig } from 'hooks'
import { BigNumber } from '@ethersproject/bignumber'
import { parseEther } from '@ethersproject/units'
import { constants, requests } from 'helpers'

import { fetchCurveCoins } from '../../util'


type State = {
  stakedTokenIndex: number | null
  rewardTokenIndex: number | null
  promise: Promise<void> | null
  isFetched: boolean
}

const useCurveExchange = () => {
  const { config, address, library, contracts } = useConfig()

  const stateRef = useRef<State>({
    stakedTokenIndex: null,
    rewardTokenIndex: null,
    isFetched: false,
    promise: null,
  })

  useEffect(() => {
    const contract = contracts?.pools.curve.poolStakedTokenRewardTokenContract

    const { isFetched } = stateRef.current

    if (!isFetched && contract) {
      stateRef.current.promise = fetchCurveCoins({
        contract,
        inToken: config.addresses.tokens.default.reward,
        outToken: config.addresses.tokens.default.staked,
      })
        .then(({ inTokenIndex, outTokenIndex }) => {
          stateRef.current = {
            rewardTokenIndex: inTokenIndex,
            stakedTokenIndex: outTokenIndex,
            isFetched: true,
            promise: null,
          }
        })
    }
  }, [ config, contracts ])

  const getCurveExchange = useCallback(async (amountBN: BigNumber) => {
    if (contracts?.pools.curve.poolStakedTokenRewardTokenContract) {
      await (stateRef.current.promise || Promise.resolve())

      const { rewardTokenIndex, stakedTokenIndex } = stateRef.current

      return contracts.pools.curve.poolStakedTokenRewardTokenContract.get_dy(
        String(rewardTokenIndex), // send (index 0)
        String(stakedTokenIndex), // receive (index 1)
        amountBN
      )
    }

    return Promise.reject('Contract is not exist')
  }, [ contracts ])

  const handleCurveApprove = useCallback(async (amount: string) => {
    const { isFetched } = stateRef.current

    if (library && address && contracts && isFetched) {
      return requests.approve({
        to: config.addresses.pools.curve.stakedTokenRewardToken,
        from: address,
        amount,
        library,
        contracts,
        tokenAddress: config.addresses.tokens.default.reward,
      })
    }

    return Promise.reject()
  }, [ config, library, address, contracts ])

  const handleCurveExchange = useCallback(async (amount: string) => {
    const { isFetched, rewardTokenIndex, stakedTokenIndex } = stateRef.current

    if (library && address && contracts && isFetched) {
      const amountBN = parseEther(amount)
      const signer = library.getUncheckedSigner(address)

      if (signer) {
        const signedContract = contracts.pools.curve.poolStakedTokenRewardTokenContract?.connect(signer)

        return signedContract?.['exchange(int128,int128,uint256,uint256)'](
          String(rewardTokenIndex), // send (index 0)
          String(stakedTokenIndex), // receive (index 1)
          amountBN,
          constants.blockchain.amount0
        )
      }
    }

    return Promise.reject()
  }, [ address, library, contracts ])

  return {
    getCurveExchange,
    handleCurveApprove,
    handleCurveExchange,
  }
}


export default useCurveExchange
