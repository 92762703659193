import { analytics } from 'helpers'
import { CurvePoolAbi } from 'contracts'


type Input = {
  contract?: CurvePoolAbi
  inToken: string
  outToken: string
}

type Output = {
  inTokenIndex: number
  outTokenIndex: number
}

const fetchCurveCoins = ({ contract, inToken, outToken }: Input): Promise<Output> => (
  Promise.all([
    contract?.coins(0),
    contract?.coins(1),
  ])
    .then((tokenAddresses) => {
      const expectedTokens = [
        inToken,
        outToken,
      ]

      const isError = tokenAddresses.some((tokenAddress) => (
        !expectedTokens.includes(tokenAddress as string)
      ))

      if (isError) {
        analytics.sentry.exception('Fetch curve coins error, wrong addresses received', undefined, {
          expectedTokens,
          tokenAddresses,
        })

        return Promise.reject()
      }

      const inTokenIndex = tokenAddresses.indexOf(inToken)
      const outTokenIndex = tokenAddresses.indexOf(outToken)

      return {
        inTokenIndex,
        outTokenIndex,
      }
    },
    (error) => {
      analytics.sentry.exception('Fetch curve coins error', error)

      return Promise.reject()
    })
)


export default fetchCurveCoins
