import type { UniRouterAbi } from 'contracts'


type Input = {
  signer: Signer
  amountBN: BigNumber
  gasLimit: BigNumber
  contracts: Contracts
  feeData: Deposit.FeeData
  params: Deposit.UniswapRequestParams
}

const sendDeposit = (values: Input) => {
  const { signer, contracts, amountBN, feeData, gasLimit, params } = values

  if (contracts.helpers.uniswap.routerContract) {
    const signedContract = contracts.helpers.uniswap.routerContract.connect(signer)

    const { maxFeePerGas, maxPriorityFeePerGas } = feeData

    const overrides: Parameters<UniRouterAbi['exactInputSingle']>[1] = {
      gasLimit,
      value: amountBN,
    }

    if (maxFeePerGas) {
      overrides.maxFeePerGas = maxFeePerGas
    }

    if (maxPriorityFeePerGas) {
      overrides.maxPriorityFeePerGas = maxPriorityFeePerGas
    }

    return signedContract.exactInputSingle(params, overrides)
  }
}


export default sendDeposit
