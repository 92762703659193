import React from 'react'
import { useConfig } from 'hooks'

import { Box } from 'components/layout'

import AddressRow from './AddressRow/AddressRow'
import ReceiveRow from './ReceiveRow/ReceiveRow'
import ActivationTimeRow from './ActivationTimeRow/ActivationTimeRow'
import type { AddressRowProps } from './AddressRow/AddressRow'
import type { ReceiveRowProps } from './ReceiveRow/ReceiveRow'


type StakeInfoProps = {
  className?: string
  amountOut: string
  isDepositDisabled: boolean
  token: AddressRowProps['token']
  liquidityPool: 'curve' | 'uniswap' | null
  addressField: AddressRowProps['addressField']
  depositField: ReceiveRowProps['depositField']
}

const StakeInfo: React.FC<StakeInfoProps> = (props) => {
  const {
    token,
    className,
    amountOut,
    addressField,
    depositField,
    isDepositDisabled,
    liquidityPool,
  } = props

  const { isMainnet } = useConfig()

  return (
    <div className={className}>
      <AddressRow
        token={token}
        addressField={addressField}
      />
      <Box mt={8}>
        <ReceiveRow
          amountOut={amountOut}
          depositField={depositField}
          isDepositDisabled={isDepositDisabled}
          liquidityPool={liquidityPool}
        />
      </Box>
      {
        isMainnet && (
          <Box mt={8}>
            <ActivationTimeRow />
          </Box>
        )
      }
    </div>
  )
}


export default React.memo(StakeInfo)
