import { splitSignature } from '@ethersproject/bytes'
import { Interface, AbiCoder } from '@ethersproject/abi'
import { ProviderConnector } from '@1inch/limit-order-protocol'


// 1inch works with a web3 connector. We need to create an analogue to work with ethers

interface EIP712TypedData {
  types: EIP712Types
  domain: EIP712Object
  message: EIP712Object
  primaryType: string
}

interface EIP712Types {
  [key: string]: EIP712Parameter[]
}

interface EIP712Parameter {
  name: string
  type: string
}

type EIP712ObjectValue = string | number | EIP712Object

interface EIP712Object {
  [key: string]: EIP712ObjectValue
}

interface AbiItem {
  anonymous?: boolean
  constant?: boolean
  inputs?: AbiInput[]
  name?: string
  outputs?: AbiOutput[]
  payable?: boolean
  stateMutability?: string
  type: string
}

interface AbiInput {
  name: string
  type: string
  indexed?: boolean
  components?: AbiInput[]
}

interface AbiOutput {
  name: string
  type: string
  components?: AbiOutput[]
}

class OneInchConnector implements ProviderConnector {

  private coder: AbiCoder
  private library: Library

  constructor(library: Library) {
    this.coder = new AbiCoder()
    this.library = library
  }

  contractEncodeABI(
      abi: AbiItem[],
      address: string | null,
      methodName: string,
      methodParams: unknown[]
  ) {
    const abiInterface = new Interface(abi)

    return abiInterface.encodeFunctionData(methodName, methodParams)
  }

  async signTypedData(
    walletAddress: string,
    typedData: EIP712TypedData,
    _typedDataHash: string
  ): Promise<string> {
    if (typedData.types.EIP712Domain) {
      // You should not pass the EIP712Domain into ethers. It will compute it for you. It is seeing that as an alternate possible root.
      delete typedData.types.EIP712Domain
    }

    const signer = this.library.getUncheckedSigner(walletAddress)

    // Here we create signature (https://eips.ethereum.org/EIPS/eip-712)
    const signature = await signer._signTypedData(
      typedData.domain,
      typedData.types,
      typedData.message
    )

    // But we should use compact signature (https://eips.ethereum.org/EIPS/eip-2098)
    const { compact } = splitSignature(signature)

    return compact
  }

  ethCall(contractAddress: string, callData: string): Promise<string> {
    return this.library.call({
      to: contractAddress,
      data: callData,
    })
  }

  decodeABIParameter<T>(type: string, hex: string): T {
    const result = this.coder.decode([ type ], hex) as unknown

    return result as T
  }
}


export default OneInchConnector
