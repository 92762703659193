import {
  LimitOrder,
  LimitOrderBuilder,
  LimitOrderProtocolFacade,
} from '@1inch/limit-order-protocol'
import { constants, getters } from 'helpers'
import { verifyTypedData } from '@ethersproject/wallet'

import adddresses from './adddresses'
import eip712Data from './eip712LimitOrderData'
import OneInchConnector from './OneInchConnector'


class OneInchLimitOrder {

  private config: Config
  private address: string
  private library: Library
  private builder: LimitOrderBuilder
  private connector: OneInchConnector
  private facade: LimitOrderProtocolFacade

  constructor(values: {
    config: Config
    address: string
    library: Library
  }) {
    const { config, library, address } = values

    this.config = config
    this.library = library
    this.address = address.toLowerCase()
    this.connector = new OneInchConnector(library)

    this.builder = new LimitOrderBuilder(
      adddresses.limitOrderEthereumContract,
      config.network.chainId,
      this.connector
    )

    this.facade = new LimitOrderProtocolFacade(
      adddresses.limitOrderEthereumContract,
      this.connector
    )
  }

  async create(values: {
    makerAssetAddress: string
    makerAmountBN: BigNumber
    takerAmountBN: BigNumber
  }) {
    const { makerAssetAddress, makerAmountBN, takerAmountBN } = values

    const wethAddress = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
    const unwrapContractAddress = '0x1282d0c06368c40c8d4a4d818d78f258d982437b'

    const orderStructure = this.builder.buildLimitOrder({
      makerAssetAddress: makerAssetAddress.toLowerCase(),
      makerAddress: this.address,
      makerAmount: makerAmountBN.toString(),

      takerAssetAddress: wethAddress,
      takerAddress: constants.specialAddresses.blockdaemon,
      takerAmount: takerAmountBN.toString(),

      interaction: unwrapContractAddress + this.address.slice(2),
      receiver: unwrapContractAddress,
      predicate: '0x',
      permit: '0x',
    })

    const data = this.builder.buildLimitOrderTypedData(orderStructure)
    const hash = this.builder.buildLimitOrderHash(data)
    const signature = await this.builder.buildOrderSignature(this.address, data)

    return { data, hash, signature }
  }

  async fill(values: {
    order: LimitOrder
    signature: string
  }) {
    const { order, signature } = values

    const signer = this.library.getUncheckedSigner(this.address)

    const data = this.facade.fillLimitOrder(
      order,
      signature,
      '0', // Maker Amount
      order.takingAmount, // Taker Amount
      order.makingAmount // Threshold Amount
    )

    const [ gasPrice, estimatedGas ] = await Promise.all([
      this.library.getGasPrice(),
      signer.estimateGas({
        to: adddresses.limitOrderEthereumContract,
        data,
      }),
    ])

    return signer.sendTransaction({
      gasLimit: getters.getGasMargin(estimatedGas),
      to: adddresses.limitOrderEthereumContract,
      gasPrice,
      data,
    })
  }

  async cancel(order: LimitOrder) {
    const signer = this.library.getUncheckedSigner(this.address)
    const data = this.facade.cancelLimitOrder(order)

    const [ gasPrice, estimatedGas ] = await Promise.all([
      this.library.getGasPrice(),
      signer.estimateGas({
        to: adddresses.limitOrderEthereumContract,
        data,
      }),
    ])

    return signer.sendTransaction({
      gasLimit: getters.getGasMargin(estimatedGas),
      to: adddresses.limitOrderEthereumContract,
      gasPrice,
      data,
    })
  }

  validate(values: {
    order: LimitOrder
    signature: string
  }) {
    const { order, signature } = values
    const { allowedSender, makerAsset, maker } = order

    const addressBySigning = verifyTypedData(
      eip712Data.domain,
      eip712Data.types,
      order,
      signature
    )

    const isValidAddress = getters.isEqualAddresses(addressBySigning, maker)
    const isBD = getters.isEqualAddresses(allowedSender, constants.specialAddresses.blockdaemon)

    const isValidToken = (
      getters.isEqualAddresses(makerAsset, this.config.addresses.tokens.default.staked)
      || getters.isEqualAddresses(makerAsset, this.config.addresses.tokens.default.reward)
    )

    return isValidAddress && isBD && isValidToken
  }
}


export default OneInchLimitOrder
