import React from 'react'
import { useConfig } from 'hooks'

import GnosisView from './GnosisView/GnosisView'
import EthereumView from './EthereumView/EthereumView'


const ControlsCard: React.FC = () => {
  const { isGnosis } = useConfig()

  if (isGnosis) {
    return <GnosisView />
  }

  return (
    <EthereumView />
  )
}


export default React.memo(ControlsCard)
