import { formatEther } from '@ethersproject/units'
import { BigNumber } from '@ethersproject/bignumber'
import { analytics } from 'helpers'
import { GnosisPoolAbi } from 'contracts'


type Input = {
  amountBN: BigNumber,
  poolContract?: GnosisPoolAbi | null
}

const calculateMGNO = async ({ amountBN, poolContract }: Input) => {
  if (poolContract) {
    try {
      const gnoCountBN = await poolContract.calculateGNO(amountBN)

      return formatEther(gnoCountBN)
    }
    catch (error) {
      analytics.sentry.exception('Pool contract convert mGNO to GNO error', error as Error, {
        deposit: formatEther(amountBN),
      })
    }
  }

  return formatEther(amountBN)
}


export default calculateMGNO
