import { formatEther } from '@ethersproject/units'
import { BigNumber } from '@ethersproject/bignumber'
import { analytics } from 'helpers'


type Input = {
  amountBN: BigNumber
  getCurveExchange: (amountBN: BigNumber) => Promise<BigNumber | undefined>
}

const calculateGNO = async ({ amountBN, getCurveExchange }: Input) => {
  try {
    const curveGnoExchangeBN = await getCurveExchange(amountBN)

    if (curveGnoExchangeBN?.gt(amountBN)) {
      return formatEther(curveGnoExchangeBN)
    }
  }
  catch (error) {
    analytics.sentry.exception('Curve get GNO amount error', error as Error, {
      deposit: formatEther(amountBN),
    })
  }

  return formatEther(amountBN)
}


export default calculateGNO
