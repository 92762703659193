import React from 'react'
import { modifiers } from 'helpers'
import { useStore, useFiatValues, useConfig } from 'hooks'

import { Text, CountUp } from 'components/ui'
import { Box } from 'components/layout'
import CardWithIcon from 'compositions/cards/CardWithIcon/CardWithIcon'

import messages from './messages'


const storeSelector = (store: Store) => ({
  currencySymbol: store.account.currencySymbol,
  stakedTokenBalance: store.account.balances.stakedTokenBalance,
  isInitialRequestLoading: store.ui.initialRequestLoading,
})

const StakingCard: React.FC = () => {
  const { config } = useConfig()
  const { stakedToken, depositToken } = config.tokens
  const { stakedTokenBalance, currencySymbol, isInitialRequestLoading } = useStore(storeSelector)

  const { fiatValue } = useFiatValues({
    fiatValue: {
      value: stakedTokenBalance,
      token: stakedToken,
    },
  })

  const formattedValue = modifiers.formatTokenValue({
    value: stakedTokenBalance,
    withSeparator: false,
  })

  const bottomNote = { ...messages.note, values: { depositToken } }

  return (
    <CardWithIcon
      title={messages.title}
      borderColor="dark-green"
      bottomNote={bottomNote}
      iconType={stakedToken}
      isLoading={isInitialRequestLoading}
      dataTestIdPrefix="staking"
      contentNode={
        <Box mt={4}>
          <Text
            color="default"
            size={24}
            light
            dataTestId="staking-amount"
          >
            <CountUp
              end={parseFloat(formattedValue)}
              separator=","
              duration={1.5}
              suffix={` ${stakedToken}`}
              decimals={formattedValue === '0.00' ? 2 : 4}
            />
          </Text>
          <Box mt={4} noWrapper>
            <Text
              color="info"
              size={16}
              dataTestId="staking-amount-fiat"
            >
              <CountUp
                end={fiatValue.value}
                decimals={2}
                duration={1.5}
                separator=","
                prefix={currencySymbol}
              />
            </Text>
          </Box>
        </Box>
      }
    />
  )
}


export default React.memo(StakingCard)
