import { useConfig } from 'hooks'
import type { Form } from 'formular'

import { useSubmitDisable } from '../../../util'

import useSubmit from './useSubmit'
import useReinvestData from './useReinvestData'
import useReinvestForm from './useReinvestForm'
import useCurveExchange from './useCurveExchange'


type Output = {
  form: Form<GnosisReinvest.Form>
  amountOut: string
  isDisabled: boolean
  isNeedApprove: boolean
  disabledMessage: Intl.Message | null
  rewardToken: Config['tokens']['rewardToken']
  stakedToken: Config['tokens']['stakedToken']
  onMaxButtonClick: () => void
  onSubmit: () => void
}

const useReinvest = (): Output => {
  const { config } = useConfig()
  const { form, handleMaxButtonClick } = useReinvestForm()
  const { disabledMessage, isSubmitDisabled } = useSubmitDisable()
  const { getCurveExchange, handleCurveApprove, handleCurveExchange } = useCurveExchange()

  const {
    amountOut,
    isNeedAllowance,
    setNeedAllowance,
    updateAllowance,
  } = useReinvestData({
    depositField: form.fields.amount,
    getCurveExchange,
  })

  const { isNeedApprove, isSubmitting, submit } = useSubmit({
    form,
    isNeedAllowance,
    updateAllowance,
    setNeedAllowance,
    handleCurveApprove,
    handleCurveExchange,
  })

  const isDisabled = isSubmitting || isSubmitDisabled || !Number(amountOut)

  return {
    form,
    amountOut,
    isNeedApprove,
    disabledMessage,
    isDisabled,
    rewardToken: config.tokens.rewardToken,
    stakedToken: config.tokens.stakedToken,
    onSubmit: submit,
    onMaxButtonClick: handleMaxButtonClick,
  }
}


export default useReinvest
