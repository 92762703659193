import { useCallback, useState } from 'react'
import { useConfig, useActions } from 'hooks'
import { OneInchLimitOrder } from 'defi/oneInch'
import { LimitOrder } from '@1inch/limit-order-protocol'
import { actions as actionsHelpers, commonMessages } from 'helpers'


type Input = {
  closeModal: () => void
  removeOrder: (signature: string) => void
}

const useCancelOrder = (values: Input) => {
  const { removeOrder, closeModal } = values
  const { address, library, config } = useConfig()

  const actions = useActions()
  const [ isLoading, setLoading ] = useState(false)

  const handleCancelOrder = useCallback((item: {
    signature: string
    orderHash: string
    data: LimitOrder
  }) => {
    const { data, signature } = item

    if (address) {
      setLoading(true)
      const limitOrders = new OneInchLimitOrder({ config, address, library })

      const callback = () => {
        closeModal()
        removeOrder(signature)
      }

      return limitOrders.cancel(data)
        .then((result) => {
          actionsHelpers.handleTransaction({
            sentText: commonMessages.notifications.sent,
            txHash: result?.hash,
            callback,
            library,
            actions,
            config,
          })
        })
        .catch(() => {
          setLoading(false)

          return Promise.reject()
        })
    }

    return Promise.reject()
  }, [ config, address, library, actions, removeOrder, closeModal ])

  return {
    isLoading,
    cancelOrder: handleCancelOrder,
  }
}


export default useCancelOrder
