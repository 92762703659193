import { useMemo } from 'react'
import { useConfig, useStore } from 'hooks'
import { commonMessages, constants } from 'helpers'

import messages from './messages'


const storeSelector = (store: Store) => ({
  isPoolPaused: store.system.isPoolPaused,
  isWhitelisted: store.account.isWhitelisted,
})

const useSubmitDisable = () => {
  const { address, activeWallet } = useConfig()
  const { isPoolPaused, isWhitelisted } = useStore(storeSelector)

  const { disabledMessage, isSubmitDisabled } = useMemo(() => {
    const isReadOnlyWallet = activeWallet === constants.walletNames.monitorAddress
    const isSubmitDisabled = isPoolPaused || isReadOnlyWallet || !address

    let disabledMessage = null

    if (!address) {
      disabledMessage = commonMessages.disabledMessages.notConnectedWallet
    }
    else if (!isWhitelisted) {
      disabledMessage = commonMessages.disabledMessages.notWhitelisted
    }
    else if (isPoolPaused) {
      disabledMessage = messages.paused
    }
    else if (isReadOnlyWallet) {
      disabledMessage = commonMessages.disabledMessages.readOnly
    }

    return {
      disabledMessage,
      isSubmitDisabled,
    }
  }, [ address, activeWallet, isPoolPaused, isWhitelisted ])

  return {
    disabledMessage,
    isSubmitDisabled,
  }
}


export default useSubmitDisable
