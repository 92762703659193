const formatDate = (date: string | Date, separator: string = '/') => {
  const formattedDate = Intl.DateTimeFormat('en', { day: 'numeric', month: 'numeric', year: 'numeric' }).format(new Date(date))

  let [ month, day, year ] = formattedDate.split('/')

  if (month.length === 1) {
    month = `0${month}`
  }

  if (day.length === 1) {
    day = `0${day}`
  }

  return [ month, day, year ].join(separator)
}


export default formatDate
