import React from 'react'
import { useDevice } from 'device'
import { commonMessages, constants } from 'helpers'

import { Text } from 'components/ui'
import { Box, Flex } from 'components/layout'
import { Button, Select, FormState } from 'components/inputs'
import TokenAmountInput from 'compositions/TokenAmountInput/TokenAmountInput'

import useWithdraw from './util/useWithdraw'
import Tokens from '../../components/Tokens/Tokens'
import { OrdersModal } from './OrdersModal/OrdersModal'
import ReceiveRowWrapper from './ReceiveRowWrapper/ReceiveRowWrapper'
import TermsCheckbox from '../../components/TermsCheckbox/TermsCheckbox'
import ConfirmButton from '../../components/ConfirmButton/ConfirmButton'

import s from './Withdraw.module.scss'
import messages from './messages'


const tokenOptions = [
  {
    imageSize: 16,
    title: constants.tokens['seth-h'],
    value: constants.tokens['seth-h'],
    image: constants.tokensImages[constants.tokens['seth-h']],
  },
  {
    imageSize: 16,
    title: constants.tokens['reth-h'],
    value: constants.tokens['reth-h'],
    image: constants.tokensImages[constants.tokens['reth-h']],
  },
]

const Withdraw: React.FC = () => {
  const {
    form,
    tokenIn,
    tokenOut,
    isLoading,
    ordersCount,
    isNeedApprove,
    isFormDisabled,
    disabledMessage,
    isSubmitDisabled,
    onSubmit,
    onOrdersClick,
    onMaxButtonClick,
  } = useWithdraw()

  const { isMobile } = useDevice()

  const approveButtonTitle = {
    ...commonMessages.buttons.approve,
    values: { token: isMobile ? '' : tokenOut },
  }

  const ordersTitle = {
    ...messages.orders,
    values: { ordersCount },
  }

  return (
    <Box pb={16} ph={16}>
      <Tokens leftToken={tokenOut} rightToken={tokenIn} />
      <Box className={s.control} mt={32} noWrapper>
        <Select
          className={s.select}
          field={form.fields.token}
          size={50}
          options={tokenOptions}
          dataTestId="withdraw-token-select"
          disabled={isLoading || isFormDisabled}
        />
        <TokenAmountInput
          className={s.input}
          field={form.fields.amount}
          dataTestIdPrefix="withdraw-amount"
          disabled={Boolean(disabledMessage) || isFormDisabled}
          onMaxButtonClick={onMaxButtonClick}
        />
      </Box>
      <div className={s.info}>
        <ReceiveRowWrapper
          amountField={form.fields.amount}
          tokenOut={tokenOut}
          tokenIn={tokenIn}
        />
        <Text
          className={s.note}
          message={messages.note}
          color="default"
          align="center"
          size={16}
        />
      </div>
      <Box className={s.checkboxContainer} mt={24} alignCenter>
        {
          Boolean(disabledMessage) ? (
            <Text
              message={disabledMessage as Intl.Message}
              size={16}
              color="danger"
              dataTestId="withdraw-wallet-error"
              html
            />
          ) : (
            <TermsCheckbox
              field={form.fields.termsAndConditions}
              dataTestId="withdraw-terms-checkbox"
            />
          )
        }
      </Box>
      <Box mt={24} noWrapper>
        <Flex justify="center" align="center">
          <FormState form={form}>
            {
              ({ errors }) => (
                <ConfirmButton
                  className={s.submitButton}
                  form={form}
                  loading={isLoading}
                  dataTestId="withdraw-confirm-button"
                  title={isNeedApprove ? approveButtonTitle : undefined}
                  disabled={isSubmitDisabled || isFormDisabled || Boolean(errors)}
                  onClick={onSubmit}
                />
              )
            }
          </FormState>
          <Button
            className={s.ordersButton}
            color="blue"
            loading={isLoading}
            message={ordersTitle}
            disabled={!ordersCount}
            dataTestId="withdraw-orders-button"
            onClick={onOrdersClick}
          />
        </Flex>
      </Box>
      <OrdersModal />
    </Box>
  )
}


export default React.memo(Withdraw)
