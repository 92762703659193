import { toHex } from '@uniswap/v3-sdk'
import { BigNumber } from '@ethersproject/bignumber'
import { splitSignature } from '@ethersproject/bytes'
import { CurrencyAmount, Fraction } from '@uniswap/sdk-core'
import { constants, getters } from 'helpers'


type Input = {
  config: Config
  address: string
  amountBN: BigNumber
  contracts: Contracts
  amountOutBN: BigNumber
  library: Library
}

const tokenAllowanceBN = BigNumber.from(2).pow(256).sub(1)

const batchApproveAndSwap = async (values: Input) => {
  const { address, config, amountBN, amountOutBN, library, contracts } = values

  if (!contracts.tokens.default.rewardTokenContract || !contracts.helpers.uniswap.routerContract) {
    return
  }

  const currentTimestamp = Number((Date.now() / 1000).toFixed(0))
  const deadline = currentTimestamp + 3600 // + 1 hour

  const { uniRewardToken } = getters.getUniTokens(config)
  const tokenName = await contracts.tokens.default.rewardTokenContract.name()
  const tokenNonce = await contracts.tokens.default.rewardTokenContract.nonces(address)

  const data = JSON.stringify({
    primaryType: 'Permit',
    types: {
      EIP712Domain: [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' },
      ],
      Permit: [
        { name: 'owner', type: 'address' },
        { name: 'spender', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' },
      ],
    },
    domain: {
      name: tokenName,
      version: '1',
      chainId: config.network.chainId,
      verifyingContract: config.addresses.tokens.default.reward,
    },
    message: {
      deadline,
      owner: address,
      nonce: tokenNonce.toString(),
      spender: config.addresses.helpers.uniswap.router,
      value: tokenAllowanceBN.toString(),
    },
  })

  const { v, r, s } = await library
    .send('eth_signTypedData_v4', [ address, data ])
    .then(splitSignature)

  const signer = library.getUncheckedSigner(address)
  const signedContract = contracts.helpers.uniswap.routerContract.connect(signer)

  const selfPermit = signedContract.interface.encodeFunctionData('selfPermit', [
    config.addresses.tokens.default.reward,
    tokenAllowanceBN.toString(),
    deadline,
    v,
    r,
    s,
  ])

  const swapAmount = CurrencyAmount.fromRawAmount(
    uniRewardToken,
    amountOutBN.toString()
  )

  const amountOutMinimum = new Fraction(1)
    .add(constants.blockchain.uniswapDefaultSlippage)
    .invert()
    .multiply(swapAmount.quotient)
    .quotient.toString()

  const exactInputSingle = signedContract.interface.encodeFunctionData(
    'exactInputSingle',
    [
      {
        tokenOut: config.addresses.tokens.default.staked,
        tokenIn: config.addresses.tokens.default.reward,
        fee: config.settings.rewardUniswapFee,
        amountIn: amountBN.toString(),
        sqrtPriceLimitX96: toHex(0),
        recipient: address,
        amountOutMinimum,
        deadline,
      },
    ]
  )

  const { maxFeePerGas, maxPriorityFeePerGas } = await library.getFeeData()

  const estimatedGas = await signedContract.estimateGas.multicall([
    selfPermit,
    exactInputSingle,
  ])

  const overrides: Parameters<typeof signedContract.multicall>[1] = {
    gasLimit: getters.getGasMargin(estimatedGas),
  }

  if (maxFeePerGas) {
    overrides.maxFeePerGas = maxFeePerGas
  }

  if (maxPriorityFeePerGas) {
    overrides.maxPriorityFeePerGas = maxPriorityFeePerGas
  }

  return signedContract.multicall([ selfPermit, exactInputSingle ], overrides)
}


export default batchApproveAndSwap
