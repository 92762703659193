import React, { useMemo } from 'react'
import { commonMessages } from 'helpers'
import { useConfig } from 'hooks'

import Stake from './Stake/Stake'
import Reinvest from './Reinvest/Reinvest'
import Withdraw from './Withdraw/Withdraw'
import Container from '../components/Container/Container'


const EthereumView: React.FC = () => {
  const { isHarbour } = useConfig()

  const tabs = useMemo(() => {
    const stake = {
      id: 'staking',
      title: commonMessages.stake,
      node: <Stake />,
    }

    const reinvest = {
      id: 'reinvest',
      title: commonMessages.reinvest,
      node: <Reinvest />,
    }

    const withdraw = {
      id: 'withdraw',
      title: commonMessages.withdraw,
      node: <Withdraw />,
    }

    if (isHarbour) {
      return [ stake, withdraw ]
    }

    return [ stake, reinvest ]
  }, [ isHarbour ])

  return (
    <Container tabs={tabs} />
  )
}


export default React.memo(EthereumView)
