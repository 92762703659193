import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'intl'
import { useConfig } from 'hooks'
import { useRouter } from 'next/router'
import { constants, validators } from 'helpers'
import { Form, useFieldState, useForm } from 'formular'

import useRewards from '../../util/useRewards'
import formatDate from './formatDate'
import useXLSX from './useXLSX'

import messages from './messages'


const { required, date, compareDate } = validators

const fromValidation = [ required, date, compareDate({ lessThan: 'to' }) ]
const toValidation = [ required, date, compareDate({ moreThan: 'from' }) ]

type FileFormat = 'xlsx' | 'csv'

type ReportFormFields = {
  from: string
  to: string
  format: FileFormat
}

export type FormatOptions = Array<{
  title: Intl.Message
  value: FileFormat
}>

const formatOptions: FormatOptions = [
  {
    title: messages.format.xlsx,
    value: 'xlsx',
  },
  {
    title: messages.format.csv,
    value: 'csv',
  },
]

const useReportModal = () => {
  const intl = useIntl()
  const { config } = useConfig()
  const { locale } = useRouter()
  const { utils, write, isLoading } = useXLSX()
  const [ isFormValid, setFormValid ] = useState(true)
  const { rewards, isFetching, isFetched, fetchRewards } = useRewards()

  const toDate = new Date()
  const fromDate = new Date(new Date().setDate(toDate.getDate() - 30))

  const form = useForm<ReportFormFields>({
    fields: {
      from: {
        validate: fromValidation,
        value: formatDate(fromDate, '/'),
      },
      to: {
        validate: toValidation,
        value: formatDate(toDate, '/'),
      },
      format: {
        value: formatOptions[0].value,
      },
    },
  })

  const { value: format } = useFieldState(form.fields.format)

  const canGenerateReport = isFormValid && !isFetching && isFetched && !isLoading

  const { fileName, content, error } = useMemo(() => {
    if (canGenerateReport && utils && write) {
      const { from, to } = form.getValues()

      const formattedFrom = formatDate(from, '-')
      const formattedTo = formatDate(to, '-')
      const fileName = `${intl.formatMessage(messages.file.name, { from: formattedFrom, to: formattedTo })}.${format}`

      const prefix = {
        csv: 'data:text/csv;base64,',
        xlsx: 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
      }[format]

      const titles = messages.file.headings.map((title) => intl.formatMessage(title, {
        token: config.tokens.rewardToken,
      }))

      const values = rewards.map(({ date, value, fiatValue }) => {
        const reportDate = formatDate(date, '/')

        return [ value, fiatValue, reportDate ]
      })

      const book = utils.book_new()
      const sheet = utils.aoa_to_sheet([ titles, ...values ])

      // Cell sizes
      if (format === 'xlsx') {
        sheet['!cols'] = [
          { wch: 22 },
          { wch: 13 },
          { wch: 18 },
        ]
      }

      utils.book_append_sheet(book, sheet)

      const rows = write(book, { type: 'base64', bookType: format })

      const content = values.length ? `${prefix}${rows}` : ''
      const error = values.length ? null : intl.formatMessage(messages.errors.noStats, { from, to })

      return {
        fileName,
        content,
        error,
      }
    }

    return {}
  }, [ rewards, intl, form, format, locale, canGenerateReport, utils, write ])

  useEffect(() => {
    if (isFormValid) {
      const { from, to } = form.getValues()

      const fromDate = new Date(from).getTime() / 1000
      const toDate = new Date(to).getTime() / 1000

      fetchRewards({
        from_date: fromDate,
        to_date: toDate,
      })
    }
  }, [ isFormValid, form, fetchRewards ])

  useEffect(() => {
    const validateForm = ({ form }: { form: Form<ReportFormFields> }) => {
      const from = form.fields.from.state.value
      const to = form.fields.to.state.value

      const isFromValid = !fromValidation.some((validation) => validation(from, form.fields))
      const isToValid = !toValidation.some((validation) => validation(to, form.fields))

      setFormValid(isFromValid && isToValid)
    }

    form.on('change', validateForm)

    return () => {
      form.off('change', validateForm)
    }
  }, [ form, setFormValid ])

  return {
    form,
    fileName,
    formatOptions,
    content,
    error,
  }
}


export default useReportModal
