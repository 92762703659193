import React, { useCallback } from 'react'
import { useDevice } from 'device'
import { constants } from 'helpers'
import { useFieldState, Field } from 'formular'

import { Grid } from 'components/layout'

import TokenBalance from './TokenBalance/TokenBalance'


type BalancesProps = {
  isLoading: boolean
  gnoTokenBalance: string
  mgnoTokenBalance: string
  tokenField: Field<Extract<Tokens, 'GNO' | 'mGNO'>>
}

const Balances: React.FC<BalancesProps> = (props) => {
  const { gnoTokenBalance, mgnoTokenBalance, tokenField, isLoading } = props

  const { isMobile } = useDevice()
  const { value } = useFieldState(tokenField)

  const handleGnoTokenClick = useCallback(() => {
    tokenField.set(constants.tokens.gno)
  }, [ tokenField ])

  const handleMgnoTokenClick = useCallback(() => {
    tokenField.set(constants.tokens.mgno)
  }, [ tokenField ])

  return (
    <Grid columns={isMobile ? 1 : 2} gutter={16}>
      <TokenBalance
        type={constants.tokens.gno}
        balance={gnoTokenBalance}
        isSelected={value === constants.tokens.gno}
        isLoading={isLoading}
        onClick={handleGnoTokenClick}
        dataTestId="gno-button-select"
      />
      <TokenBalance
        type={constants.tokens.mgno}
        balance={mgnoTokenBalance}
        isSelected={value === constants.tokens.mgno}
        isLoading={isLoading}
        onClick={handleMgnoTokenClick}
        dataTestId="mno-button-select"
      />
    </Grid>
  )
}


export default React.memo(Balances)
