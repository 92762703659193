import { getters, analytics } from 'helpers'
import { CurrencyAmount } from '@uniswap/sdk-core'
import { Route, encodeRouteToPath } from '@uniswap/v3-sdk'
import { formatEther, parseEther } from '@ethersproject/units'


type Input = {
  config: Config
  amount: string
  contracts: Contracts
}

const fetchAmountOut = async (values: Input): Promise<string> => {
  const { config, amount, contracts } = values

  try {
    const amountBN = parseEther(amount)
    const pools = await getters.getUniPools({ contracts, config })
    const pool = pools[config.addresses.pools.uniswap.stakedTokenRewardToken]

    if (!contracts.helpers.uniswap.quoterContract || !pool) {
      return '0'
    }

    const { uniStakedToken, uniRewardToken } = getters.getUniTokens(config)

    const route = new Route(
      [ pool ],
      uniRewardToken,
      uniStakedToken
    )

    const currencyAmount = CurrencyAmount.fromRawAmount(
      uniRewardToken,
      amountBN.toString()
    )

    const path = encodeRouteToPath(route, false)
    const amountHash = `0x${currencyAmount.quotient.toString(16)}`
    const swapAmountOutBN = await contracts.helpers.uniswap.quoterContract.quoteExactInput(path, amountHash)

    return formatEther(swapAmountOutBN)
  }
  catch (error) {
    analytics.sentry.exception('Uniswap get sETH2 amount error', error as Error, {
      deposit: amount,
    })
  }

  return '0'
}


export default fetchAmountOut
