import { useCallback, useEffect, useRef } from 'react'
import { Field } from 'formular'
import { useConfig } from 'hooks'
import localStorage from 'local-storage'
import { BigNumber } from '@ethersproject/bignumber'
import { parseEther } from '@ethersproject/units'
import { constants, requests } from 'helpers'

import { fetchCurveCoins } from '../../util'


type State = {
  stakedTokenIndex: number | null
  depositTokenIndex: number | null
  isFetched: boolean
}

type Input = {
  tokenField: Field<string>
}

const useCurveExchange = ({ tokenField }: Input) => {
  const { config, address, library, contracts } = useConfig()

  const stateRef = useRef<State>({
    stakedTokenIndex: null,
    depositTokenIndex: null,
    isFetched: false,
  })

  useEffect(() => {
    const contract = contracts?.pools.curve.poolStakedTokenDepositTokenContract
    const isBlocked = Boolean(localStorage.getSessionItem(constants.queryNames.blockDepositCurveFlow)) && (!IS_PROD || IS_PREVIEW)

    const { isFetched } = stateRef.current

    if (!isFetched && !isBlocked && contract) {
      const handleFetch = (value: string) => {
        if (value === constants.tokens.gno) {
          fetchCurveCoins({
            contract,
            inToken: config.addresses.tokens.default.deposit,
            outToken: config.addresses.tokens.default.staked,
          })
            .then(({ inTokenIndex, outTokenIndex }) => {
              stateRef.current = {
                depositTokenIndex: inTokenIndex,
                stakedTokenIndex: outTokenIndex,
                isFetched: true,
              }
            })
        }
      }

      if (tokenField.state.value === constants.tokens.gno) {
        handleFetch(tokenField.state.value)
      }
      else {
        tokenField.on('change', handleFetch)

        return () => {
          tokenField.off('change', handleFetch)
        }
      }
    }
  }, [ config, contracts, tokenField ])

  const getCurveExchange = useCallback(async (amountBN: BigNumber) => {
    const { isFetched, depositTokenIndex, stakedTokenIndex } = stateRef.current

    if (isFetched && contracts) {
      return contracts.pools.curve.poolStakedTokenDepositTokenContract?.get_dy(
        String(depositTokenIndex), // send (index 1)
        String(stakedTokenIndex), // receive (index 0)
        amountBN
      )
    }

    return Promise.reject()
  }, [ contracts ])

  const handleCurveApprove = useCallback(async (params: GnosisStake.SubmitParams) => {
    const { isFetched } = stateRef.current

    if (library && address && contracts && isFetched) {
      const { amount } = params

      return requests.approve({
        to: config.addresses.pools.curve.stakedTokenDepositToken,
        from: address,
        amount,
        library,
        contracts,
        tokenAddress: config.addresses.tokens.default.deposit,
      })
    }

    return Promise.reject()
  }, [ config, library, address, contracts ])

  const handleCurveExchange = useCallback(async (params: GnosisStake.SubmitParams) => {
    const { isFetched, depositTokenIndex, stakedTokenIndex } = stateRef.current

    if (library && address && contracts && isFetched) {
      const { amount, recipient } = params
      const amountBN = parseEther(amount)
      const signer = library.getUncheckedSigner(address)

      if (signer) {
        const signedContract = contracts.pools.curve.poolStakedTokenDepositTokenContract?.connect(signer)

        return signedContract?.['exchange(int128,int128,uint256,uint256,address)'](
          String(depositTokenIndex), // send (index 1)
          String(stakedTokenIndex), // receive (index 0)
          amountBN,
          constants.blockchain.amount0,
          recipient
        )
      }
    }

    return Promise.reject()
  }, [ address, library, contracts ])

  return {
    getCurveExchange,
    handleCurveApprove,
    handleCurveExchange,
  }
}


export default useCurveExchange
