import Image from 'next/image'
import { useDevice } from 'device'
import { constants } from 'helpers'

import { Text } from 'components/ui'
import { Box, Flex } from 'components/layout'

import s from './Token.module.scss'


type TokenProps = {
  type: Tokens
  dataTestId?: string
}

const Token: React.FC<TokenProps> = ({ type, dataTestId }) => {
  const { isMobile } = useDevice()

  return (
    <Box pv={8} pl={16} noWrapper>
      <Flex justify="start">
        <Box className={s.imageContainer} mr={8}>
          <Image src={constants.tokensImages[type]} alt={type} />
        </Box>
        {
          !isMobile && (
            <Text
              message={type}
              color="default"
              noWrap
              dataTestId={dataTestId}
            />
          )
        }
      </Flex>
    </Box>
  )
}


export default Token
