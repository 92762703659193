import { useCallback, useState } from 'react'
import { Form } from 'formular'
import { openNotification } from 'notifications'
import { parseEther } from '@ethersproject/units'
import { useActions, useBalances, useConfig, useNewTotalStakingAmount } from 'hooks'
import { actions as actionsHelpers, analytics, commonMessages, constants } from 'helpers'

import { openDepositSuccessModal } from '../../../components/DepositSuccessModal/DepositSuccessModal'

import { uniswapFlow, defaultFlow, sendAnalytics } from './helpers'

import messages from './messages'


type Input ={
  form: Form<Deposit.Form>
  submitParams: Deposit.SubmitParams
  isUniswapFlow: boolean
  isSubmitDisabled: boolean
}

const useSubmit = (values: Input) => {
  const { form, submitParams, isUniswapFlow, isSubmitDisabled } = values

  const actions = useActions()
  const fetchTotalStakingAmount = useNewTotalStakingAmount()
  const [ isSubmitting, setSubmitting ] = useState(false)
  const { config, address, library, contracts, activeWallet } = useConfig()
  const { fetchAndSetNativeTokenBalance, fetchAndSetStakedTokenBalance } = useBalances()

  const handleTransactionCallback = useCallback(() => {
    fetchAndSetNativeTokenBalance()
    fetchAndSetStakedTokenBalance()
    fetchTotalStakingAmount()

    const isAddTokensModalShown = localStorage.getItem(constants.localStorageNames.depositAddTokensShown)
    const isMetaMask = activeWallet === constants.walletNames.metaMask

    if (!isMetaMask || isAddTokensModalShown) {
      openNotification({
        type: 'success',
        text: commonMessages.notifications.success,
      })
    }
    else {
      openDepositSuccessModal({
        localStorageName: constants.localStorageNames.depositAddTokensShown,
      })
    }
  }, [
    activeWallet,
    fetchAndSetNativeTokenBalance,
    fetchAndSetStakedTokenBalance,
    fetchTotalStakingAmount,
  ])

  const handleSubmit = useCallback(({ amount, recipient }) => {
    const { feeData, gasLimit, uniswapRequestParams } = submitParams

    if (!library || !address || !contracts) {
      return
    }

    if (!feeData || !gasLimit) {
      return Promise.reject('Empty feeData or gasLimit')
    }

    const amountBN = parseEther(amount)

    sendAnalytics({
      amount,
      recipient,
      isUniswapFlow,
    })

    const signer = library.getUncheckedSigner(address)

    const commonParams = {
      signer,
      feeData,
      amountBN,
      gasLimit,
      contracts,
    }

    if (isUniswapFlow && uniswapRequestParams) {
      return uniswapFlow.sendDeposit({
        ...commonParams,
        params: uniswapRequestParams,
      })
    }

    return defaultFlow.sendDeposit({
      ...commonParams,
      recipient,
      address,
      config,
    })
  }, [ config, address, library, contracts, submitParams, isUniswapFlow ])

  const submit = useCallback(async () => {
    if (isSubmitDisabled || !library || !address || !contracts) {
      return
    }

    setSubmitting(true)

    const { values, errors } = await form.submit()

    if (errors) {
      return
    }

    actions.ui.setBottomLoader({
      content: messages.loaders.waitingConfirmation,
    })

    const { deposit: amount, address: recipient } = values

    try {
      const result = await handleSubmit({ amount, recipient })

      if (result?.hash) {
        const txHash = result?.hash

        form.fields.deposit.set('')

        actionsHelpers.handleTransaction({
          sentText: commonMessages.notifications.sent,
          callback: handleTransactionCallback,
          withConfirmedNotification: false,
          txHash,
          library,
          actions,
          config,
        })
      }
    }
    catch (error) {
      form.fields.deposit.set('')
      actions.ui.resetBottomLoader()
      analytics.sentry.exception('Deposit send transaction error', error as Error)

      openNotification({
        type: 'error',
        text: commonMessages.notifications.failed,
      })
    }
    finally {
      setSubmitting(false)
    }
  }, [
    form,
    config,
    address,
    actions,
    library,
    contracts,
    isSubmitDisabled,
    handleSubmit,
    handleTransactionCallback,
  ])

  return {
    isSubmitting,
    submit,
  }
}


export default useSubmit
