import React from 'react'
import { useDevice } from 'device'

import { Grid, Box } from 'components/layout'

import AprCard from './AprCard/AprCard'
import StakingCard from './StakingCard/StakingCard'
import RewardsCard from './RewardsCard/RewardsCard'

import s from './StatsBlock.module.scss'


const StatsBlock: React.FC = () => {
  const { isMobile, isTablet } = useDevice()

  if (isMobile) {
    return (
      <>
        <StakingCard />
        <Box mt={16}>
          <RewardsCard />
        </Box>
        <Box mt={16}>
          <AprCard />
        </Box>
      </>
    )
  }

  if (isTablet) {
    return (
      <Grid columns={2} gutter={32}>
        <StakingCard />
        <RewardsCard />
        <Grid.Cell size={2}>
          <Box className={s.smallBox} center>
            <AprCard />
          </Box>
        </Grid.Cell>
      </Grid>
    )
  }

  return (
    <Grid columns={3} gutter={32}>
      <StakingCard />
      <RewardsCard />
      <AprCard />
    </Grid>
  )
}


export default React.memo(StatsBlock)
