export default {
  title: {
    en: 'Received Tokens',
    ru: 'Токены к получению',
    fr: 'Tokens reçus',
    es: 'Tokens Recibidos',
    pt: 'Tokens recebidos',
    de: 'Empfangene Tokens',
    zh: '獲得的代幣',
  },
}
