import { analytics } from 'helpers'


type Input = {
  amount: string
  recipient: string
  isUniswapFlow: boolean
}

const sendAnalytics = (values: Input) => {
  const { amount, recipient, isUniswapFlow } = values

  analytics.sentry.breadcrumb({
    message: 'Send stake transaction',
    data: {
      amount,
      recipient,
      isUniswapFlow,
    },
  })

  analytics.statistics.sendEvent('deposit', {
    amount,
    recipient,
    isUniswapFlow,
  })
}


export default sendAnalytics
