import React from 'react'
import { useDevice } from 'device'
import { modifiers } from 'helpers'

import { Icon, Text } from 'components/ui'
import { Tooltip } from 'components/feedback'
import { Flex, Box } from 'components/layout'

import messages from './messages'
import s from './ReceiveRow.module.scss'


type ReceiveRowProps = {
  token: Tokens
  value: string
}

const ReceiveRow: React.FC<ReceiveRowProps> = (props) => {
  const { value, token } = props

  const { isMobile } = useDevice()

  const formattedValue = modifiers.formatTokenValue({ value })
  const receiveValue = `${formattedValue} ${token}`
  const isShortValue = Number(value) > Number(formattedValue)
  const tooltipValue = `${value} ${token}`

  return (
    <Flex justify="center">
      <Box className={s.box} pr={isMobile ? 4 : 8} alignRight>
        <Text
          message={messages.title}
          color="info"
          size={16}
          dataTestId="reinvest-you-receive-item"
        />
      </Box>
      <Box className={s.box} pl={isMobile ? 4 : 8}>
        <Flex justify="start">
          <Text
            message={receiveValue}
            color="default"
            size={14}
            tag="span"
            dataTestId="reinvest-you-receive-amount"
          />
          {
            isShortValue && (
              <Box pl={8}>
                <Tooltip content={tooltipValue} center>
                  <Icon
                    name="fa-solid fa-circle-info"
                    color="info"
                    size={14}
                  />
                </Tooltip>
              </Box>
            )
          }
        </Flex>
      </Box>
    </Flex>
  )
}


export default ReceiveRow
