export default {
  text: {
    en: `You will reinvest your rewards by utilizing the {title} Liquidity Pool {link}`,
    ru: `Вы реинвестируете свои вознаграждения с помощью {title} {link}`,
    fr: `Vous aller réinvestir vos récompenses via le pool de liquidités {title} {link}`,
    es: `Usted va a reinvertir sus ganancias utilizando la piscina de liquidez de {title} {link}`,
    pt: `Você reinvestirá suas recompensas utilizando o {title} reserva de liquidez {link}`,
    de: `Du reinvestierst Deine Belohnungen via dem {title} Liquidity Pool {link}`,
    zh: `您將會透過 {title} 流動性礦池來將您的獎勵重新投入質押 {link}`,
  },
}
