export default {
  title: {
    en: 'Rewards',
    ru: 'Вознаграждения',
    fr: 'Récompenses',
    es: 'Ganancias',
    pt: 'Recompensas',
    de: 'Gebühren',
    zh: '獎勵',
  },
  note: {
    en: 'Your reward {depositToken} balance',
    ru: 'Баланс Ваших {depositToken} вознаграждений',
    fr: 'Votre solde de récompenses {depositToken}',
    es: 'Su balance de {depositToken} en ganancias',
    pt: 'Seu saldo de {depositToken} de recompensa',
    de: 'Dein Kontostand für {depositToken} Belohnungen',
    zh: '您的 {depositToken} 獎勵餘額',
  },
}
