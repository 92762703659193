import { useCallback, useEffect, useState } from 'react'
import { Form } from 'formular'
import { useActions, useConfig } from 'hooks'
import { ContractTransaction } from 'ethers'
import { openNotification } from 'notifications'
import { actions as helpersActions, analytics, commonMessages, constants, requests } from 'helpers'

import { openApproveModal } from '../../../components/ApproveModal/ApproveModal'
import { openDepositSuccessModal } from '../../../components/DepositSuccessModal/DepositSuccessModal'

import { stake, batchApproveAndStake } from './helpers'

import messages from './messages'


type Input = {
  form: Form<GnosisStake.Form>
  isCurveFlow: boolean
  isNeedAllowance: boolean
  isPermitSupported: boolean
  fetchBalances: () => void
  updateAllowance: () => void
  setNeedAllowance: (isNeedAllowance: boolean) => void
  setPermitDisabled: (isPermitDisabled: boolean) => void
  handleCurveApprove: (data: GnosisStake.SubmitParams) => Promise<ContractTransaction | undefined>
  handleCurveExchange: (data: GnosisStake.SubmitParams) => Promise<ContractTransaction | undefined>
}

const useSubmit = (props: Input) => {
  const {
    form, isCurveFlow, isNeedAllowance, isPermitSupported,
    fetchBalances, updateAllowance, setNeedAllowance, setPermitDisabled, handleCurveApprove, handleCurveExchange,
  } = props

  const actions = useActions()
  const { config, contracts, address, library, activeWallet } = useConfig()

  const [ isSubmitting, setSubmitting ] = useState(false)

  const isSubmitDisabled = (
    !library
    || !address
    || !contracts
    || !activeWallet
    || isSubmitting
  )

  useEffect(() => {
    if (isSubmitting) {
      actions.ui.setBottomLoader({
        content: messages.loaders.waitingConfirmation,
      })

      return () => {
        actions.ui.resetBottomLoader()
      }
    }
  }, [ actions, isSubmitting ])

  const handleError = useCallback((error: Error) => {
    analytics.sentry.exception('Gnosis deposit send transaction error', error)

    openNotification({
      type: 'error',
      text: commonMessages.notifications.failed,
    })
  }, [])

  const handleApprove = useCallback(async (params: GnosisStake.SubmitParams) => {
    if (!library || !address || !contracts) {
      return
    }

    const { amount, token } = params

    let promise

    if (isCurveFlow) {
      promise = handleCurveApprove(params)
    }
    else {
      promise = requests.approve({
        to: config.addresses.base.pool,
        from: address,
        amount,
        library,
        contracts,
        tokenAddress: token === constants.tokens.gno
          ? config.addresses.tokens.default.deposit
          : config.addresses.tokens.default.alternativeDeposit,
      })
    }

    return promise
      .then((result) => (
        helpersActions.handleTransaction({
          sentText: commonMessages.notifications.sent,
          txHash: result?.hash,
          library,
          actions,
          config,
        })
      ))
  }, [ config, actions, address, library, contracts, isCurveFlow, handleCurveApprove ])

  const handleApproveAndSubmit = useCallback(async (params: GnosisStake.SubmitParams) => {
    const { amount, recipient } = params

    if (!address || !library || !contracts) {
      return
    }

    return batchApproveAndStake({
      amount,
      config,
      address,
      library,
      recipient,
      contracts,
    })
  }, [ config, address, library, contracts ])

  const handlePoolStake = useCallback(async (params: GnosisStake.SubmitParams) => {
    const { token, amount, recipient } = params

    if (!address || !library || !contracts) {
      return
    }

    return stake({
      token,
      config,
      amount,
      address,
      library,
      recipient,
      contracts,
    })
  }, [ config, address, library, contracts ])

  const handleSubmitTransaction = useCallback(async (result) => {
    if (!library) {
      return
    }

    form.fields.deposit.set('')

    const callback = () => {
      fetchBalances()

      const isAddTokensModalShown = localStorage.getItem(constants.localStorageNames.depositAddTokensShownGnosis)
      const isMetaMask = activeWallet === constants.walletNames.metaMask

      if (!isMetaMask || isAddTokensModalShown) {
        openNotification({
          type: 'success',
          text: commonMessages.notifications.success,
        })
      }
      else {
        openDepositSuccessModal({
          localStorageName: constants.localStorageNames.depositAddTokensShownGnosis,
        })
      }
    }

    return helpersActions.handleTransaction({
      withConfirmedNotification: false,
      txHash: result?.hash,
      sentText: commonMessages.notifications.sent,
      config,
      library,
      actions,
      callback,
    })
  }, [ form, config, actions, library, activeWallet, fetchBalances ])

  const handleSubmit = useCallback(async (params: GnosisStake.SubmitParams) => {
    if (!library || !address) {
      return
    }

    let promise

    if (isCurveFlow) {
      promise = handleCurveExchange(params)
    }
    else {
      promise = handlePoolStake(params)
    }

    return promise
      .then((result) => handleSubmitTransaction(result))
      .catch((error) => {
        const errorCode = error?.data?.originalError?.code || error?.code

        // code 4001: User canceled transaction
        if (errorCode === 4001) {
          updateAllowance()

          openApproveModal({
            type: 'stake',
            token: params.token,
            onClick: () => handleSubmit(params),
          })
        }
        else {
          console.warn(error)
          handleError(error)
        }
      })
  }, [
    address,
    library,
    isCurveFlow,
    handleError,
    handlePoolStake,
    updateAllowance,
    handleCurveExchange,
    handleSubmitTransaction,
  ])

  const submit = useCallback(async ({ isPermitDisabled }: { isPermitDisabled?: boolean } = {}) => {
    if (isSubmitDisabled) {
      return
    }

    const { values, errors } = await form.submit()

    if (errors) {
      return
    }

    const { token, deposit: amount, address: recipient } = values

    const params = {
      token,
      amount,
      recipient,
    }

    analytics.sentry.breadcrumb({
      message: 'send deposit transaction',
      data: {
        ...params,
        isCurveFlow,
        isNeedAllowance,
        isPermitDisabled,
        isPermitSupported,
      },
    })

    setSubmitting(true)

    const isBatchStakeFlow = !isCurveFlow && isPermitSupported && !isPermitDisabled

    if (isNeedAllowance) {
      const promise = isBatchStakeFlow
        ? handleApproveAndSubmit(params)
        : handleApprove(params)

      promise
        .then(
          (result) => {
            if (isCurveFlow || !isBatchStakeFlow) {
              return handleSubmit(params)
            }
            else {
              return handleSubmitTransaction(result)
            }
          },
          (error) => {
            const errorCode = error?.data?.originalError?.code || error?.code

            console.warn(error)

            // code 4001: User canceled transaction
            if (isBatchStakeFlow && errorCode !== 4001) {
              // It may be that a wallet that supports batching transactions will not be able to do this,
              // so 2 transactions will need to be done. Example - MetaMask with Trezor
              setPermitDisabled(true)

              openApproveModal({
                type: 'batchStake',
                token: params.token,
                onClick: () => submit({ isPermitDisabled: true }),
              })
            }
            else {
              // code -32603: Reverted error.
              // There is some problem with approved tokens on the Gnosis network. At some point,
              // approve may stop working. In this case, you need to approve them again.
              if (errorCode === -32603) {
                setNeedAllowance(true)
              }

              handleError(error)
            }
          }
        )
        .finally(() => setSubmitting(false))
    }
    else {
      handleSubmit(params)
        .finally(() => setSubmitting(false))
    }
  }, [
    form,
    isCurveFlow,
    isNeedAllowance,
    isSubmitDisabled,
    isPermitSupported,
    handleError,
    handleSubmit,
    handleApprove,
    handleApproveAndSubmit,
    setNeedAllowance,
    setPermitDisabled,
    handleSubmitTransaction,
  ])

  const isNeedApprove = isNeedAllowance && (isCurveFlow || !isPermitSupported)

  return {
    isCurveFlow,
    isNeedApprove,
    isSubmitting,
    submit,
  }
}


export default useSubmit
