import React from 'react'
import Image from 'next/image'
import { constants } from 'helpers'
import { supportedNetworks } from 'config'
import { useStore, useConfig } from 'hooks'

import { Text } from 'components/ui'
import { Tooltip } from 'components/feedback'
import { Box, Flex } from 'components/layout'

import TooltipContent from './TooltipContent/TooltipContent'

import messages from './messages'
import s from './Map.module.scss'

import mapImage from 'public/static/images/other/map.png'


const storeSelector = (store: Store) => ({
  nodeOperators: store.system.nodeOperators,
})

const tokens: Record<string, string[]> = {
  [constants.tokens.eth]: [
    supportedNetworks.goerli.id,
    supportedNetworks.mainnet.id,
    supportedNetworks.harbourGoerli.id,
    supportedNetworks.harbourMainnet.id,
  ],
  [constants.tokens.gno]: [
    supportedNetworks.gnosis.id,
  ],
}

const Map: React.FunctionComponent = () => {
  const { config } = useConfig()
  const { nodeOperators: { asia, europe, australia, northAmerica } } = useStore(storeSelector)

  const token = Object.keys(tokens).find((key) => {
    const ids: string[] = tokens[key]

    return ids.includes(config.network.id)
  })

  const title = {
    ...messages.title,
    values: { token },
  }

  return (
    <Box p={16}>
      <Text
        message={title}
        color="default"
        align="center"
        size={24}
        dataTestId="validators-map-header"
      />
      <Flex justify="center" fullHeight>
        <Box className={s.mapContainer} mt={24} relative>
          {
            // need to create <div className={s.position} />, bcs Tooltip can't work with position absolute
          }
          {
            Boolean(northAmerica.length) && (
              <div className={s.checkpoint1}>
                <Tooltip
                  center
                  content={(
                    <TooltipContent items={northAmerica} />
                  )}
                  dataTestId="validator-map-point"
                >
                  <div className={s.position} />
                </Tooltip>
              </div>
            )
          }
          {
            Boolean(europe.length) && (
              <div className={s.checkpoint2}>
                <Tooltip
                  center
                  content={(
                    <TooltipContent items={europe} />
                  )}
                  dataTestId="validator-map-point"
                >
                  <div className={s.position} />
                </Tooltip>
              </div>
            )
          }
          {
            Boolean(australia.length) && (
              <div className={s.checkpoint3}>
                <Tooltip
                  center
                  content={(
                    <TooltipContent items={australia} />
                  )}
                >
                  <div className={s.position} />
                </Tooltip>
              </div>
            )
          }
          {
            Boolean(asia.length) && (
              <div className={s.checkpoint4}>
                <Tooltip
                  center
                  content={(
                    <TooltipContent items={asia} />
                  )}
                  dataTestId="validator-map-point"
                >
                  <div className={s.position} />
                </Tooltip>
              </div>
            )
          }
          <Image
            src={mapImage}
            width={mapImage.width / 2}
            height={mapImage.height / 2}
            alt="map"
          />
        </Box>
      </Flex>
    </Box>
  )
}


export default React.memo(Map)
