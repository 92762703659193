import { useCallback, useEffect, useRef, useMemo } from 'react'
import { useForm } from 'formular'
import { validators } from 'helpers'
import { useConfig, useStore } from 'hooks'
import { LimitOrder } from '@1inch/limit-order-protocol'
import { isAddress, getAddress } from '@ethersproject/address'
import { parseEther, formatEther } from '@ethersproject/units'


type Input = Array<{
  signature: string
  orderHash: string
  data: LimitOrder
}>

type Form = {
  token: Tokens
  amount: string
  termsAndConditions: boolean
}

const storeSelector = (store: Store) => ({
  stakedTokenBalance: store.account.balances.stakedTokenBalance,
  rewardTokenBalance: store.account.balances.rewardTokenBalance,
})

const useWithdrawForm = (orders: Input) => {
  const { config, address, networkId } = useConfig()
  const { stakedTokenBalance, rewardTokenBalance } = useStore(storeSelector)

  const getMaxBalance = useCallback((token: Tokens) => {
    const isRewardToken = token === config.tokens.rewardToken
    const balance = isRewardToken ? rewardTokenBalance : stakedTokenBalance

    if (orders.length) {
      // The user's balance will be changed if his order is executed.
      // Until the order is fulfilled, the tokens are stored in the user's wallet.
      // We should not allow him to create orders that are higher than his balance
      const balanceBN = parseEther(balance)

      const tokenAddress = isRewardToken
        ? config.addresses.tokens.default.reward
        : config.addresses.tokens.default.staked

      const balanceWithOrders = orders.reduce((acc, order) => {
        const { data: { makerAsset, makingAmount } } = order

        if (isAddress(makerAsset) && getAddress(makerAsset) === tokenAddress) {
          const makingAmountBN = parseEther(formatEther(makingAmount))

          return acc.sub(makingAmountBN)
        }

        return acc
      }, balanceBN)

      const result = formatEther(balanceWithOrders)

      return Number(result) >= 0 ? result : '0'
    }
    else {
      return balance
    }
  }, [ config, orders, stakedTokenBalance, rewardTokenBalance ])

  const balanceValidator = useCallback((value: string, values: any) => {
    const token = values.token.state.value as Tokens
    const balance = getMaxBalance(token)

    return validators.sufficientBalance(parseEther(balance))(value)
  }, [ getMaxBalance ])

  const balanceValidatorRef = useRef(balanceValidator)
  balanceValidatorRef.current = balanceValidator

  const form = useForm<Form>({
    fields: {
      token: [],
      amount: [
        validators.numberWithDot,
        validators.greaterThanZero,
        validators.refValidator(balanceValidatorRef),
      ],
      termsAndConditions: [
        validators.required,
      ],
    },
    initialValues: {
      token: config.tokens.stakedToken,
    },
  })

  const handleMaxButtonClick = useCallback(() => {
    const token = form.fields.token.state.value
    const balance = getMaxBalance(token)

    form.fields.amount.set(Number(balance) ? balance : '0')
    form.fields.amount.validate()
  }, [ form, getMaxBalance ])

  useEffect(() => {
    form.unsetValues()
  }, [ form, address, networkId ])

  useEffect(() => {
    const resetAmount = () => {
      form.fields.amount.setState({
        value: '',
        error: null,
      })
    }

    form.fields.token.on('state change', resetAmount)

    return () => {
      form.fields.token.off('state change', resetAmount)
    }
  }, [])

  return useMemo(() => ({
    form,
    onMaxButtonClick: handleMaxButtonClick,
  }), [ form, handleMaxButtonClick ])
}


export default useWithdrawForm
