import React from 'react'

import { Box } from 'components/layout'
import { Text, Icon } from 'components/ui'
import { Tooltip } from 'components/feedback'

import RowWrapper from '../components/RowWrapper/RowWrapper'

import messages from './messages'


const ActivationTimeRow: React.FC = () => (
  <RowWrapper
    title={messages.title}
    dataTestId="stake-activation-item"
  >
    <Box mr={8} noWrapper>
        <Text
          message={messages.immediate}
          color="default"
          size={14}
          dataTestId="stake-activation-value"
        />
      </Box>
      <Tooltip content={messages.tooltips.immediate}>
        <Icon name="fa-light fa-clock" color="info" size={16} />
      </Tooltip>
  </RowWrapper>
)


export default React.memo(ActivationTimeRow)
