import { useCallback, useEffect } from 'react'
import { Field } from 'formular'
import localStorage from 'local-storage'
import { useConfig, useObjectState } from 'hooks'
import { parseEther } from '@ethersproject/units'
import { BigNumber } from '@ethersproject/bignumber'
import { actions as actionsHelpers, constants, requests } from 'helpers'

import { calculateGNO, calculateMGNO } from './helpers'


type State = {
  amountOut: string
  isNeedAllowance: boolean
}

type Input = {
  tokenField: Field<string>
  depositField: Field<string>
  getCurveExchange: (amountBN: BigNumber) => Promise<BigNumber | undefined>
}

const initialState = {
  amountOut: '0',
  isNeedAllowance: false,
}

const useStakeData = ({ tokenField, depositField, getCurveExchange }: Input) => {
  const { contracts, address, config } = useConfig()

  const [ { amountOut, isNeedAllowance }, setState ] = useObjectState<State>(initialState)

  const isGno = tokenField.state.value === constants.tokens.gno
  const isBlocked = Boolean(localStorage.getSessionItem(constants.queryNames.blockDepositCurveFlow)) && (!IS_PROD || IS_PREVIEW)
  const isCurveFlow = !isBlocked && isGno && Number(amountOut) > Number(depositField.state.value)

  const poolAddress = isCurveFlow
    ? config.addresses.pools.curve.stakedTokenDepositToken
    : config.addresses.base.pool

  const poolContract = contracts?.base.poolContract[config.network.id as 'gnosis']

  const tokenContract = isGno
    ? contracts?.tokens.default.depositTokenContract
    : contracts?.tokens.default.alternativeDepositTokenContract

  const handleFetchAllowance = useCallback((amount: string) => {
    if (address && tokenContract) {
      return requests.allowance({
        amount,
        tokenContract,
        sender: address,
        recipient: poolAddress,
        wrongAmount: '10000000',
      })
    }

    return Promise.reject()
  }, [ address, tokenContract, poolAddress ])

  const handleFetchAmountOut = useCallback(async (deposit: string) => {
    const amountBN = parseEther(deposit)
    const isGno = tokenField.state.value === constants.tokens.gno

    if (isGno) {
      return calculateGNO({
        amountBN,
        getCurveExchange,
      })
    }

    return calculateMGNO({
      amountBN,
      poolContract,
    })
  }, [ tokenField, poolContract, getCurveExchange ])

  const checkIsValidAmount = useCallback((depositFieldState) => (
    Boolean(
      Number(depositFieldState.value)
      && !depositFieldState.isValidating
      && (
        depositFieldState.isValid
        || !depositFieldState.error
      )
    )
  ), [])

  useEffect(() => {
    const fetchData = actionsHelpers.debounce((depositFieldState: Record<string, string | boolean>) => {
      if (checkIsValidAmount(depositFieldState)) {
        const value = depositFieldState.value as string

        Promise.all([
          handleFetchAmountOut(value),
          handleFetchAllowance(value),
        ])
          .then(([ amountOut, isNeedAllowance ]) => {
            if (checkIsValidAmount(depositFieldState)) {
              setState({
                amountOut,
                isNeedAllowance,
              })
            }
          })
      }
      else {
        setState(initialState)
      }
    }, 150)

    depositField.on('state change', fetchData)

    return () => {
      depositField.off('state change', fetchData)
    }
  }, [ depositField, checkIsValidAmount, handleFetchAllowance, handleFetchAmountOut, setState ])

  // Reset state on address change
  useEffect(() => {
    return () => {
      setState(initialState)
    }
  }, [ address, setState ])

  const updateAllowance = useCallback(() => {
    const value = depositField.state.value as string

    handleFetchAllowance(value)
      .then((isNeedAllowance) => {
        setState({ isNeedAllowance })
      })
  }, [ depositField, handleFetchAllowance, setState ])

  const setNeedAllowance = useCallback((isNeedAllowance) => {
    setState({ isNeedAllowance })
  }, [ setState ])

  return {
    amountOut,
    isCurveFlow,
    isNeedAllowance,
    setNeedAllowance,
    updateAllowance,
  }
}


export default useStakeData
