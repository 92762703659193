import { useEffect } from 'react'
import { useConfig } from 'hooks'
import { constants } from 'helpers'

import useRewards from './useRewards'


const range = (size: number) => Object.keys(new Array(size).fill(null))

const getDatesRange = () => {
  const today = new Date().getDate()

  return range(30)
    .map((count, index) => new Date(new Date().setDate(today - index)).getDate().toString())
    .reverse()
}

const getValuesRange = (max: number, digits: number) => {
  const count = 5

  return (
    range(count + 1)
      .map((_, index) => {
        const value = max / count * index

        return value.toFixed(digits)
      })
      .reverse()
  )
}

const getChartSize = (maxValue: number) => {
  const digits = maxValue < 1 ? -Math.floor(Math.log10(maxValue)) : 0
  const multiplier = maxValue < 1 ? Math.pow(10, digits) : 1

  const integer = maxValue * multiplier

  const ceil = Math.ceil(integer / 5)
  const nextDivisibleDigit = ceil === 1 ? 2 : 5
  const chartMax = Math.ceil(integer / nextDivisibleDigit) * nextDivisibleDigit / multiplier

  const chartStep = chartMax / 100

  return {
    labelDigits: ceil === 1 ? digits + 1 : digits,
    chartMax,
    chartStep,
  }
}

const useChartData = () => {
  const { config, address } = useConfig()
  const { rewards, fetchRewards, isFetched } = useRewards()

  useEffect(() => {
    fetchRewards()
  }, [ fetchRewards ])

  if (!rewards.length || !address) {
    return {
      bars: range(30).map(() => ({ height: '0%', tooltip: null })),
      legendY: getValuesRange(0.1, 2),
      legendX: getDatesRange(),
      isChartEmpty: isFetched,
    }
  }

  const chartRewards = rewards.map(({ date, value, fiatValue }) => {
    const rewardDate = new Date(date)
    const tooltipDate = Intl.DateTimeFormat('en', { day: 'numeric', month: 'short', year: 'numeric' }).format(rewardDate)
    const ethValue = `${value} ${config.tokens.rewardToken}`

    return ({
      date: new Date(date).getDate().toString(),
      value: Number(value),
      tooltip: `
        ${tooltipDate}<br />
        ${ethValue} ($${fiatValue})
      `,
    })
  })

  const maxValue = chartRewards.reduce((result, { value }) => Math.max(result, value), 0)
  const { labelDigits, chartMax, chartStep } = getChartSize(maxValue)

  const legendY = labelDigits < 5 ? getValuesRange(chartMax, labelDigits) : []
  const legendX = chartRewards.map(({ date }) => date)

  const bars = chartRewards.map(({ value, tooltip }) => ({
    height: `${chartStep ? value / chartStep : 0}%`,
    tooltip,
  }))

  return {
    bars,
    legendY,
    legendX,
  }
}


export default useChartData
