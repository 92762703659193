import React, { ReactNode } from 'react'

import { Box, Flex } from 'components/layout'
import { Text } from 'components/ui'

import s from './CardHeader.module.scss'


type CardHeaderProps = {
  title: Intl.Message
  content?: ReactNode
  dataTestId?: string
  html?: boolean
}

const CardHeader: React.FC<CardHeaderProps> = (props) => {
  const { title, content, dataTestId, html } = props

  return (
    <Box className={s.header} p={16} noWrapper>
      <Flex justify="between">
        <Text
          message={title}
          color="default"
          size={20}
          html={html}
          dataTestId={dataTestId}
        />
        {content}
      </Flex>
    </Box>
  )
}


export default CardHeader
