export default {
  title: {
    en: '{token} Validators Map',
    ru: 'Карта Валидаторов {token}',
    fr: 'Carte des validateurs {token}',
    es: 'Mapa de Validadores de {token}',
    pt: 'Mapa de Validadores {token}',
    de: '{token} Validatoren Karte',
    zh: '{token} 驗證節點地圖',
  },
}
