export default {
  notifications: {
    insufficientBalance: {
      en: 'Insufficient ETH balance for the deposit. Check if you have enough gas for the transaction.',
      ru: 'Недостаточный баланс ETH для депозита. Проверьте, хватит ли вам газа на транзакцию.',
      fr: 'Solde d\'ETH insuffisant pour le dépôt',
      es: 'Balance insuficiente de ETH para el deposito',
      pt: 'Saldo ETH insuficiente para o depósito',
      de: 'Unzureichender ETH Kontostand für die Einzahlung',
      zh: 'ETH 餘額不足以發起存入交易',
    },
  },
  loaders: {
    waitingConfirmation: {
      en: 'Waiting for the deposit confirmation',
      ru: 'Ожидаем подтверждения депозита',
      fr: 'Attente de la confirmation',
      es: 'Esperando la confirmación del depósito',
      pt: 'Waiting for deposit confirmation',
      de: 'Warte auf die Bestätigung',
      zh: '等待確認',
    },
  },
  paused: {
    en: 'Pool is paused',
    ru: 'Пул приостановлен',
    fr: 'Le pool est en pause',
    es: 'El Pool está pausado',
    pt: 'A reserva está pausada',
    de: 'Pool ist pausiert',
    zh: '礦池已暫停',
  },
}
