export default {
  notifications: {
    transactionSent: {
      en: 'Rewards have been sent. It will take some time to confirm the transaction',
      ru: 'Вознаграждения отправлены. Подтверждение транзакции займет некоторое время',
      fr: 'Les récompenses ont été envoyées. La confirmation prendre un peu de temps',
      es: 'Las ganancias han sido enviadas. Tomará un tiempo confirmar la transacción',
      pt: 'As recompensas foram enviadas. Levará algum tempo para confirmar a transação',
      de: 'Belohnungen wurden gesendet. Es wird etwas dauern, bis die Transaktion bestätigt wird',
      zh: '獎勵已發送。需要一段時間來確認交易',
    },
  },
  loaders: {
    waitingConfirmation: {
      en: 'Waiting for swap confirmation',
      ru: 'Ожидаем подтверждения обмена',
      fr: 'En attente de la confirmation',
      es: 'Esperando la confirmación del intercambio',
      pt: 'Aguardando confirmação de troca',
      de: 'Warten auf die Bestätigung des Swaps',
      zh: '等待交換確認',
    },
  },
}
