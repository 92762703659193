export default {
  title: {
    en: 'Staking',
    ru: 'Стейкинг',
    fr: 'Staking',
    es: 'Staking',
    pt: 'Staking',
    de: 'Staking',
    zh: '質押',
  },
  note: {
    en: 'Your staked {depositToken} balance',
    ru: 'Баланс Вашего {depositToken} стейкинга',
    fr: 'Votre solde d\'{depositToken} stakés',
    es: 'Su balance de {depositToken} en staking',
    pt: 'Seu saldo de staking {depositToken}',
    de: 'Dein Kontostand für staked {depositToken}',
    zh: '您已質押的 {depositToken} 餘額',
  },
}
