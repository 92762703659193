export default {
  note: {
    en: 'Would you like to add our tokens to wallet?',
    ru: 'Хотите добавить наши токены в кошелёк?',
    fr: 'Voulez vous ajouter les jetons à vorte portefeuille?',
    es: 'Le gustaría agreagar nuestros tokens a su cartera?',
    pt: 'Gostaria de adicionar nossos tokens à carteira?',
    de: 'Möchtest Du unsere Tokens Deiner Wallet hinzufügen?',
    zh: '您是否願意將我們的代幣天加到您的錢包內?',
  },
}
