import { Field } from 'formular'
import cx from 'classnames'

import { Flex } from 'components/layout'
import { Button, Input } from 'components/inputs'

import Token from './Token/Token'

import messages from './messages'
import s from './TokenAmountInput.module.scss'


type TokenAmountInputProps = {
  className?: string
  token?: Tokens
  loading?: boolean
  disabled?: boolean
  field: Field<string>
  dataTestIdPrefix?: string
  onMaxButtonClick: () => void
}

const TokenAmountInput: React.FC<TokenAmountInputProps> = (props) => {
  const { className, field, token, disabled, loading, dataTestIdPrefix, onMaxButtonClick } = props

  const leftNode = token && (
    <Token
      type={token}
      dataTestId={`${dataTestIdPrefix}-token-name`}
    />
  )

  return (
    <Flex className={cx(className, s.root)} justify="start" align="start">
      <div className={s.inputContainer}>
        <Input
          className={s.input}
          size={50}
          field={field}
          validateChange
          leftNode={leftNode}
          disabled={disabled || loading}
          pattern="^[0-9]+\.?[0-9]{0,18}$"
          withRightIcon={false}
          placeholder={messages.placeholder}
          dataTestId={`${dataTestIdPrefix}-input`}
        />
      </div>
      <Button
        className={s.button}
        loading={loading}
        disabled={disabled}
        message={messages.buttonTitle}
        dataTestId={`${dataTestIdPrefix}-max-button`}
        onClick={onMaxButtonClick}
      />
    </Flex>
  )
}


export default TokenAmountInput
