import { getters } from 'helpers'
import { toHex } from '@uniswap/v3-sdk'


type Input = {
  config: Config
  address: string
  amountBN: BigNumber
  contracts: Contracts
  amountOutBN: BigNumber
  library: Library
}

const swap = async (values: Input) => {
  const { address, config, amountBN, amountOutBN, library, contracts } = values

  if (!contracts.helpers.uniswap.routerContract) {
    return
  }

  const signer = library.getUncheckedSigner(address)
  const signedContract = contracts.helpers.uniswap.routerContract.connect(signer)
  const deadline = Number((Date.now() / 1000).toFixed(0)) + 60 * 30

  const params = {
    deadline,
    amountIn: amountBN,
    recipient: address,
    sqrtPriceLimitX96: toHex(0),
    amountOutMinimum: amountOutBN,
    fee: config.settings.rewardUniswapFee,
    tokenIn: config.addresses.tokens.default.reward,
    tokenOut: config.addresses.tokens.default.staked,
  }

  const { maxFeePerGas, maxPriorityFeePerGas } = await library.getFeeData()
  const gasCost = await signedContract.estimateGas.exactInputSingle(params)
  const gasLimit = getters.getGasMargin(gasCost)

  const overrides: Parameters<typeof signedContract.exactInputSingle>[1] = {
    gasLimit,
  }

  if (maxFeePerGas) {
    overrides.maxFeePerGas = maxFeePerGas
  }

  if (maxPriorityFeePerGas) {
    overrides.maxPriorityFeePerGas = maxPriorityFeePerGas
  }

  return signedContract.exactInputSingle(params, overrides)
}


export default swap
