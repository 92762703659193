import { formatEther } from '@ethersproject/units'
import { BigNumber } from '@ethersproject/bignumber'
import { analytics } from 'helpers'


type Input = {
  amountBN: BigNumber
  getCurveExchange: (amountBN: BigNumber) => Promise<BigNumber | undefined>
}

const calculateSGNO = async ({ amountBN, getCurveExchange }: Input) => {
  try {
    const curveGnoExchangeBN = await getCurveExchange(amountBN)

    if (curveGnoExchangeBN) {
      return formatEther(curveGnoExchangeBN)
    }
  }
  catch (error) {
    analytics.sentry.exception('Curve get sGNO amount error', error as Error, {
      deposit: formatEther(amountBN),
    })
  }

  return '0'
}


export default calculateSGNO
