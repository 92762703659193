import React, { useCallback, useRef } from 'react'
import { useConfig } from 'hooks'
import { modalWrapper } from 'modal'
import { useFieldState } from 'formular'
import { commonMessages, requests } from 'helpers'
import { isAddress } from '@ethersproject/address'
import type { Field } from 'formular'

import { Box } from 'components/layout'
import { Text, Icon } from 'components/ui'
import { Modal } from 'components/feedback'
import { Input, Button } from 'components/inputs'

import messages from './messages'
import s from './ChangeDepositAddressModal.module.scss'


type ChangeDepositAddressModalProps = {
  addressField: Field<string>
  token: Extract<Tokens, 'GNO' | 'mGNO' | 'ETH'>
}

export const [ ChangeDepositAddressModal, openChangeDepositAddressModal ] = (
  modalWrapper<ChangeDepositAddressModalProps>(UNIQUE_FILE_ID, (props) => {
    const { addressField, token, closeModal } = props

    const { library, contracts } = useConfig()
    const { error } = useFieldState(addressField)
    const initialValueRef = useRef(addressField.state.value)

    const handleSubmit = useCallback(async () => {
      const address = addressField.state.value

      if (contracts) {
        const isWhitelisted = await requests.fetchWhitelistedAccounts({ contracts, address })

        if (!isWhitelisted) {
          addressField.setError(commonMessages.disabledMessages.notWhitelisted)

          return
        }
      }

      if (isAddress(address)) {
        return closeModal()
      }

      const resolvedName = await library?.resolveName(address)

      if (resolvedName) {
        closeModal()

        return
      }

      addressField.setError(messages.errors.invalidEnsName)
    }, [ library, contracts, addressField, closeModal ])

    const handleModalClose = () => {
      if (error) {
        addressField.setError('')
        addressField.set(initialValueRef.current)
      }

      closeModal()
    }

    const text = {
      ...messages.text,
      values: { token },
    }

    return (
      <Modal
        className={s.modal}
        analyticsName="staking change deposit address"
        closeModal={handleModalClose}
      >
        <Text
          message={messages.title}
          color="default"
          align="center"
          size={24}
        />
        <Box mt={16} noWrapper>
          <Text
            message={text}
            color="dark-gray"
            align="center"
            size={16}
          />
        </Box>
        <Box mt={16} ph={16}>
          <Input
            className={s.inputContainer}
            field={addressField}
            placeholder={messages.inputText}
            withRightIcon={false}
            dataTestId="stake change recipient input"
            leftNode={(
              <Box pv={8} pl={16}>
                <Icon
                  name="fa-light fa-user"
                  color="default"
                  size={16}
                />
              </Box>
            )}
          />
        </Box>
        <Box mt={32} alignCenter>
          <Button
            message={commonMessages.buttons.confirm}
            dataTestId="stake change recipient submit button"
            analyticsEventName="staking change deposit address submit"
            disabled={Boolean(error)}
            onClick={handleSubmit}
          />
        </Box>
      </Modal>
    )
  })
)
