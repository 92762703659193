import { parseEther } from '@ethersproject/units'
import { analytics } from 'helpers'


type Input = {
  amount: string
  config: Config
  address: string
  contracts: Contracts
}

const fetchAllowance = async ({ amount, config, address, contracts }: Input) => {
  try {
    const tokenContract = contracts?.tokens.default.rewardTokenContract

    if (!tokenContract) {
      return false
    }

    const allowance = await tokenContract.allowance(
      address,
      config.addresses.helpers.uniswap.router
    )

    return allowance.lt(parseEther(amount))
  }
  catch (error) {
    analytics.sentry.exception('Uniswap get sETH2 amount error', error as Error, {
      deposit: amount,
    })
  }

  return true
}


export default fetchAllowance
