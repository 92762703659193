import { getters, constants } from 'helpers'


type Input = {
  config: Config
  address: string
  recipient: string
  amountBN: BigNumber
  contracts: Contracts
}

const fetchDepositGas = async (values: Input): Promise<BigNumber> => {
  const { amountBN, config, address, recipient, contracts } = values

  const poolContract = contracts.base.poolContract[config.network.id as 'mainnet' | 'goerli' | 'harbour_mainnet' | 'harbour_goerli' ]

  if (!poolContract) {
    return constants.blockchain.amount0
  }

  const params = {
    from: address,
    value: amountBN,
  }

  const promise = recipient !== address
    ? poolContract.estimateGas.stakeOnBehalf(recipient, params)
    : poolContract.estimateGas.stake(params)

  const estimatedGas = await promise

  return getters.getGasMargin(estimatedGas)
}


export default fetchDepositGas
