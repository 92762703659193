import React, { useEffect } from 'react'
import Head from 'next/head'
import { useIntl } from 'intl'
import { analytics } from 'helpers'

import MainBlock from './MainBlock/MainBlock'
import StatsBlock from './StatsBlock/StatsBlock'
import ChartBlock from './ChartBlock/ChartBlock'

import messages from './messages'


const StakingView: React.FC = () => {
  const { formatMessage } = useIntl()

  useEffect(() => {
    analytics.statistics.sendEvent('Staking page')
  }, [])

  return (
    <>
      <Head>
        <title>{formatMessage(messages.title)}</title>
      </Head>
      <StatsBlock />
      <MainBlock />
      <ChartBlock />
    </>
  )
}


export default StakingView
