import { useState, useCallback } from 'react'
import { useFieldState } from 'formular'
import { constants } from 'helpers'

import useOrders from './useOrders'
import useApprove from './useApprove'
import useAmountOut from './useAmountOut'
import useWithdrawForm from './useWithdrawForm'
import { useSubmitDisable } from '../../../util'
import { openOrdersModal } from '../OrdersModal/OrdersModal'

import messages from './messages'


const useWithdraw = () => {
  const { isFetching: isAmountOutFetching, isEmptyFiatValue, calculateAmountOut } = useAmountOut()

  const {
    orders,
    isOrdersFetching,
    fetchOrders,
    createOrder,
    removeOrder,
  } = useOrders(calculateAmountOut)

  const [ isSubmitting, setSubmitting ] = useState(false)
  const { form, onMaxButtonClick } = useWithdrawForm(orders)
  const { value: tokenOut } = useFieldState(form.fields.token)
  const { disabledMessage, isSubmitDisabled } = useSubmitDisable()
  const { isAllowanceFetching, isNeedApprove, approve, resetApprove } = useApprove(form)

  const handleSubmit = useCallback(async () => {
    setSubmitting(true)

    const amount = form.fields.amount.state.value

    try {
      if (isNeedApprove) {
        const callback = () => {
          setSubmitting(false)
          resetApprove()
        }

        approve(callback)
      }
      else {
        await createOrder(amount, tokenOut)
        await fetchOrders()

        form.unsetValues()
        setSubmitting(false)
      }
    }
    catch {
      setSubmitting(false)
    }
  }, [ form, tokenOut, isNeedApprove, approve, createOrder, resetApprove, fetchOrders ])

  const handleOrdersClick = useCallback(() => {
    openOrdersModal({ orders, removeOrder })
  }, [ orders, removeOrder ])

  const errorMessage = isEmptyFiatValue ? messages.fiatError : disabledMessage
  const isFormDisabled = Boolean(disabledMessage) || isAmountOutFetching || isEmptyFiatValue
  const isLoading = isSubmitting || isOrdersFetching || isAllowanceFetching || isAmountOutFetching

  return {
    form,
    tokenOut,
    ordersCount: orders.length,
    tokenIn: constants.tokens.eth,
    disabledMessage: errorMessage,

    isLoading,
    isNeedApprove,
    isFormDisabled,
    isSubmitDisabled,

    onMaxButtonClick,
    onSubmit: handleSubmit,
    onOrdersClick: handleOrdersClick,
  }
}


export default useWithdraw
