import { toHex } from '@uniswap/v3-sdk'
import { getters } from 'helpers'


const sqrtPriceLimitX96 = toHex(0)

type Output = Omit<Deposit.UniswapRequestParams, 'recipient' | 'amountIn' | 'amountOutMinimum'>

const getRequestData = (config: Config): Output => {
  const { uniWrappedEthToken } = getters.getUniTokens(config)

  const deadline = Number((Date.now() / 1000 + 60 * 60 * 6).toFixed(0))
  const tokenIn = uniWrappedEthToken.address
  const fee = config.settings.stakedUniswapFee
  const tokenOut = config.addresses.tokens.default.staked

  return {
    fee,
    tokenIn,
    tokenOut,
    deadline,
    sqrtPriceLimitX96,
  }
}


export default getRequestData
