import React from 'react'
import { constants } from 'helpers'
import { useFieldState, Field } from 'formular'

import Tokens from '../../../components/Tokens/Tokens'


type TokensWrapperProps = {
  tokenField: Field<Extract<Tokens, 'GNO' | 'mGNO'>>
}

const TokensWrapper: React.FC<TokensWrapperProps> = (props) => {
  const { tokenField } = props

  const { value } = useFieldState(tokenField)

  return (
    <Tokens
      size={70}
      leftToken={value}
      rightToken={constants.tokens.sgno}
    />
  )
}


export default React.memo(TokensWrapper)
