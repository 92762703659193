import React from 'react'
import { commonMessages } from 'helpers'

import Stake from './Stake/Stake'
import Reinvest from './Reinvest/Reinvest'
import Container from '../components/Container/Container'


const GnosisView: React.FC = () => {
  const tabs = [
    {
      id: 'staking',
      title: commonMessages.stake,
      node: <Stake />,
    },
    {
      id: 'reinvest',
      title: commonMessages.reinvest,
      node: <Reinvest />,
    },
  ]

  return (
    <Container tabs={tabs} />
  )
}


export default React.memo(GnosisView)
