import { isAddress } from '@ethersproject/address'
import localStorage from 'local-storage'
import { constants } from 'helpers'


type Input = {
  config: Config
  signer: Signer
  address: string
  recipient: string
  amountBN: BigNumber
  gasLimit: BigNumber
  contracts: Contracts
  feeData: Deposit.FeeData
}

const sendDeposit = (values: Input) => {
  const { amountBN, config, recipient, address, contracts, feeData, signer, gasLimit } = values

  const poolContract = contracts.base.poolContract[config.network.id as 'mainnet' | 'goerli' | 'harbour_mainnet' | 'harbour_goerli']
  const { maxFeePerGas, maxPriorityFeePerGas } = feeData

  if (poolContract) {
    const signedContract = poolContract.connect(signer)
    const referrer = localStorage.getSessionItem<string>(constants.queryNames.depositReferrer) || ''

    const params: Parameters<typeof signedContract.stake>[0]  = {
      value: amountBN,
      gasLimit,
    }

    if (maxFeePerGas) {
      params.maxFeePerGas = maxFeePerGas
    }

    if (maxPriorityFeePerGas) {
      params.maxPriorityFeePerGas = maxPriorityFeePerGas
    }

    if (isAddress(referrer)) {
      return recipient !== address
        ? signedContract?.stakeWithReferrerOnBehalf(referrer, recipient, params)
        : signedContract?.stakeWithReferrer(referrer, params)
    }

    return recipient !== address
      ? signedContract?.stakeOnBehalf(recipient, params)
      : signedContract?.stake(params)
  }
}


export default sendDeposit
