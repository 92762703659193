import { useCallback } from 'react'
import request from 'request-module'
import { formatEther } from '@ethersproject/units'
import { useObjectState, useConfig } from 'hooks'


type ApiData = Array<{
  date: string
  reward: string
  reward_usd: string
}>

type RewardStat = {
  date: string
  value: string
  fiatValue: string
}

type State = {
  rewards: Array<RewardStat>
  isFetched: boolean
  isFetching: boolean
}

const useRewards = () => {
  const { config, address } = useConfig()

  const [ { rewards, isFetching, isFetched }, setState ] = useObjectState<State>({
    rewards: [],
    isFetched: false,
    isFetching: false,
  })

  const fetchRewards = useCallback(async (params?: Record<string, any>) => {
    if (!address) {
      return
    }

    setState({ isFetching: true })

    try {
      const { data } = await request.get<ApiData>({
        url: `${config.api.rest}/staker-rewards/${address}`,
        params: {
          ...params,
          network: config.network.id,
        },
      })

      const rewards = data?.map(({ date, reward, reward_usd }) => {
        const value = formatEther(reward)

        return ({
          date,
          value,
          fiatValue: reward_usd,
        })
      }) || []

      setState({
        rewards,
        isFetched: true,
        isFetching: false,
      })
    }
    catch (error) {
      console.error('Rewards fetching error', error)
      setState({ isFetching: false })
    }
  }, [ address, config, setState ])

  return {
    rewards,
    isFetched,
    isFetching,
    fetchRewards,
  }
}


export default useRewards
