import request from 'request-module'
import { OneInchLimitOrder } from 'defi/oneInch'
import { openNotification } from 'notifications'
import { formatEther, parseEther } from '@ethersproject/units'
import { commonMessages, analytics } from 'helpers'

import type { Output as UseAmountOutOutput } from './useAmountOut'


type Input = {
  config: Config
  amount: string
  address: string
  library: Library
  tokenOut: Tokens
  reCaptchaToken: string
  calculateAmountOut: UseAmountOutOutput['calculateAmountOut']
}

const createOrder = async (values: Input) => {
  const { address, library, config, amount, tokenOut, reCaptchaToken, calculateAmountOut } = values

  try {
    const amountBN = parseEther(amount)
    const amountOutBN = calculateAmountOut(amountBN, tokenOut)

    const makerAssetAddress = tokenOut === config.tokens.stakedToken
      ? config.addresses.tokens.default.staked
      : config.addresses.tokens.default.reward

    const limitOrders = new OneInchLimitOrder({ config, address, library })

    const { data, signature, hash } = await limitOrders.create({
      takerAmountBN: amountOutBN,
      makerAmountBN: amountBN,
      makerAssetAddress,
    })

    await request.post({
      url: `${config.api.rest}/withdraw-orders/`,
      params: {
        network: config.network.id,
      },
      data: {
        recaptcha_token: reCaptchaToken,
        data: {
          signature,
          orderHash: hash,
          data: data.message,
        },
      },
    })

    openNotification({
      type: 'success',
      text: commonMessages.notifications.success,
    })
  }
  catch (error) {
    openNotification({
      type: 'error',
      text: commonMessages.notifications.failed,
    })

    analytics.sentry.exception('HARBOUR: create order fail', error as Error)

    return Promise.reject()
  }
}


export default createOrder
