import { useState } from 'react'
import { useConfig } from 'hooks'
import { constants } from 'helpers'


type Output = {
  isPermitSupported: boolean
  setPermitDisabled: (isPermitDisabled: boolean) => void
}

const usePermitSupport = (): Output => {
  const { activeWallet } = useConfig()
  const [ isPermitDisabled, setPermitDisabled ] = useState(false)

  const isPermitSupported = (
    !isPermitDisabled
    && (
      activeWallet === constants.walletNames.metaMask
      || activeWallet === constants.walletNames.ledger
    )
  )

  return {
    isPermitSupported,
    setPermitDisabled,
  }
}


export default usePermitSupport
