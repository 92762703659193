import React, { forwardRef } from 'react'
import cx from 'classnames'

import { Box } from 'components/layout'

import s from './Card.module.scss'


export type CardProps = {
  children: React.ReactNode
  className?: string
  dataTestId?: string
}

const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const { children, className, dataTestId } = props

  return (
    <Box
      ref={ref}
      className={cx(s.card, className)}
      radius={8}
      overflow="hidden"
      dataTestId={dataTestId}
    >
      {children}
    </Box>
  )
})

Card.displayName = 'Card'


export default Card
