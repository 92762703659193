export default {
  title: {
    en: 'Recipient',
    ru: 'Получатель',
    fr: 'Destinataire',
    es: 'Destinatario',
    pt: 'Recipiente',
    de: 'Empfänger',
    zh: '收據',
  },
  buttonTooltip: {
    en: 'Change recipient address',
    ru: 'Изменить адрес получателя',
    fr: 'Changer l\'adresse du destinataire',
    es: 'Cambiar dirección de recepción',
    pt: 'Alterar endereço do destinatário',
    de: 'Empfängeradresse ändern',
    zh: '更改收據地址',
  },
  infoTooltip: {
    en: `
      You can change the recipient address of your staked {token} tokens.
      Keep in mind that only the owner of the tokens has access to the staked {token}
    `,
    ru: `
      Вы можете изменить адрес получателя Ваших токенов {token}.
      Имейте в виду, что только владелец токенов имеет доступ к {token} стейкинга.
    `,
    fr: `
      Vous pouvez changer l'adresse destinataire des jetons {token} stakés.
      Garder à l'esprit que seul le propriétair des jetons a l'accès aux {token} stakés
    `,
    es: `
      Puede cambiar la dirección de recepción de sus tokens de {token} en staking.
      Considere que sólo el dueño de los tokens tiene acceso al {token} en staking
    `,
    pt: `
 Você pode alterar o endereço do destinatário de seus staked {token} tokens.
 Lembre-se de que apenas o proprietário dos tokens tem acesso ao staked {token}
`,
    de: `
      Du kannst den Empfänger für Deine staked ETH ändern.
      Bedenke, dass nur der Eigentümer der Tokens Zugriff auf die staked ETH Tokens hat
    `,
    zh: `
      您可以更改存取您質押的 {token} 的收款地址
      請注意只有代幣擁有者可存取質押的 {token}
    `,
  },
}
