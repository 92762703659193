import { useCallback, useEffect, useRef } from 'react'
import { Field, useForm } from 'formular'
import { useConfig } from 'hooks'
import { parseEther } from '@ethersproject/units'
import { constants, validators } from 'helpers'


type Input = {
  gnoTokenBalance: string
  mgnoTokenBalance: string
}

const useStakeForm = ({ gnoTokenBalance, mgnoTokenBalance }: Input) => {
  const { address } = useConfig()

  const balanceValidator = useCallback((value: string, fields: Record<string, Field<string>>) => {
    const isGno = fields.token.state.value === constants.tokens.gno
    const balance = isGno ? gnoTokenBalance : mgnoTokenBalance

    return validators.sufficientBalance(parseEther(balance))(value)
  }, [ gnoTokenBalance, mgnoTokenBalance ])

  const balanceValidatorRef = useRef(balanceValidator)
  balanceValidatorRef.current = balanceValidator

  const form = useForm<GnosisStake.Form>({
    fields: {
      deposit: [ validators.numberWithDot, validators.greaterThanZero, validators.refValidator(balanceValidatorRef) ],
      termsAndConditions: [],
      token: [ validators.required ],
      address: [ validators.required, validators.ethOrEnsAddress ],
    },
    initialValues: {
      token: constants.tokens.gno,
    },
  })

  const handleMaxButtonClick = useCallback(async () => {
    const balances = {
      [constants.tokens.gno]: gnoTokenBalance,
      [constants.tokens.mgno]: mgnoTokenBalance,
    } as const

    const balance = balances[form.fields.token.state.value]

    form.fields.deposit.setError(null)
    form.fields.deposit.set(Number(balance) ? balance : '0')
    form.fields.deposit.validate()
  }, [ form, gnoTokenBalance, mgnoTokenBalance ])

  useEffect(() => {
    const onTokenChange = () => {
      form.fields.deposit.setError(null)
      form.fields.deposit.set('')
    }

    form.fields.token.on('change', onTokenChange)

    return () => {
      form.fields.token.off('change', onTokenChange)
    }
  }, [ form ])

  useEffect(() => {
    form.unsetValues()

    if (address) {
      form.fields.address.set(address)
    }
  }, [ form, address ])

  return {
    form,
    handleMaxButtonClick,
  }
}


export default useStakeForm
