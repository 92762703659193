import React from 'react'
import { useConfig } from 'hooks'

import { Text } from 'components/ui'
import { Box, Flex } from 'components/layout'

import messages from './messages'
import s from './TooltipContent.module.scss'


type TooltipContentProps = {
  items: Array<{
    name: string
    validators: number
  }>
}

const TooltipContent: React.FunctionComponent<TooltipContentProps> = (props) => {
  const { items } = props

  const { isHarbourMainnet } = useConfig()

  if (isHarbourMainnet) {
    return (
      <div className={s.container}>
        <Box p={8} noWrapper>
          <Text
            message={messages.operator}
            color="dark-gray"
            align="center"
            size={12}
          />
        </Box>
        {
          items.map(({ name }, index) => (
            <Box key={index} className={s.row} p={8} noWrapper>
              <Text
                message={name}
                color="default"
                align="center"
                size={12}
              />
            </Box>
          ))
        }
      </div>
    )
  }

  return (
    <div className={s.container}>
      <Box p={8} noWrapper>
        <Flex>
          <Text
            className={s.left}
            message={messages.operator}
            color="dark-gray"
            align="center"
            size={12}
          />
          <Text
            className={s.right}
            message={messages.validators}
            color="dark-gray"
            align="center"
            size={12}
          />
        </Flex>
      </Box>
      {
        items.map(({ name, validators }, index) => (
          <Box key={index} p={8} noWrapper>
            <Flex className={s.row} align="center">
              <Text
                className={s.left}
                message={name}
                color="default"
                align="center"
                size={12}
              />
              <Text
                className={s.right}
                message={String(validators)}
                color="green-states"
                align="center"
                size={14}
              />
            </Flex>
          </Box>
        ))
      }
    </div>
  )
}


export default React.memo(TooltipContent)
