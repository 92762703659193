import type { Form } from 'formular'

import { useSubmitDisable } from '../../../util'

import useSubmit from './useSubmit'
import useStakeForm from './useStakeForm'
import useStakeData from './useStakeData'
import useCurveExchange from './useCurveExchange'
import usePermitSupport from './usePermitSupport'
import useGnosisBalances from './useGnosisBalances'


type Output = {
  amountOut: string
  form: Form<GnosisStake.Form>
  gnoTokenBalance: string
  mgnoTokenBalance: string
  disabledMessage: Intl.Message | null
  isDisabled: boolean
  isCurveFlow: boolean
  isNeedApprove: boolean
  isBalancesFetching: boolean
  onSubmit: () => void
  onMaxButtonClick: () => void
}

const useStake = (): Output => {
  const { disabledMessage, isSubmitDisabled } = useSubmitDisable()
  const { gnoTokenBalance, mgnoTokenBalance, isBalancesFetching, fetchBalances } = useGnosisBalances()

  const { form, handleMaxButtonClick } = useStakeForm({
    gnoTokenBalance,
    mgnoTokenBalance,
  })

  const { isPermitSupported, setPermitDisabled } = usePermitSupport({
    tokenField: form.fields.token,
  })

  const { getCurveExchange, handleCurveApprove, handleCurveExchange } = useCurveExchange({
    tokenField: form.fields.token,
  })

  const { amountOut, isCurveFlow, isNeedAllowance, updateAllowance, setNeedAllowance } = useStakeData({
    tokenField: form.fields.token,
    depositField: form.fields.deposit,
    getCurveExchange,
  })

  const { isNeedApprove, isSubmitting, submit } = useSubmit({
    form,
    isCurveFlow,
    isNeedAllowance,
    isPermitSupported,
    fetchBalances,
    updateAllowance,
    setNeedAllowance,
    setPermitDisabled,
    handleCurveApprove,
    handleCurveExchange,
  })

  const isDisabled = isSubmitDisabled || isSubmitting || !Number(amountOut)

  return {
    form,
    amountOut,
    disabledMessage,
    gnoTokenBalance,
    mgnoTokenBalance,
    isDisabled,
    isCurveFlow,
    isNeedApprove,
    isBalancesFetching,
    onSubmit: submit,
    onMaxButtonClick: handleMaxButtonClick,
  }
}


export default useStake
