import { useCallback, useEffect, useRef } from 'react'
import { formatEther } from '@ethersproject/units'
import { useStore, useActions, useConfig } from 'hooks'

import type { CountdownProps } from 'components/ui'


const storeSelector = (store: Store) => ({
  rewardTokenBalance: store.account.balances.rewardTokenBalance,
})

const useUpdateRewards = (): CountdownProps['expiredCallback'] => {
  const actions = useActions()
  const { rewardTokenBalance } = useStore(storeSelector)
  const { address, chainId, contracts } = useConfig()

  const savedChainId  = useRef<number>()
  const savedAddress = useRef<string>()
  const interval = useRef<NodeJS.Timer>()

  useEffect(() => {
    if (interval.current) {
      const isDifferentNetworks = savedChainId.current !== chainId
      const isDifferentAddresses = address && savedAddress.current !== address

      if (isDifferentNetworks || isDifferentAddresses) {
        clearInterval(interval.current)
      }

      if (address) {
        savedAddress.current = address
      }

      if (chainId) {
        savedChainId.current = chainId
      }
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [ address, chainId ])

  return useCallback((resetTimer) => {
    if (!contracts || !address || !contracts.tokens.default.rewardTokenContract) {
      return
    }

    clearInterval(interval.current as NodeJS.Timer)

    const fetch = async () => {
      if (contracts && address && contracts.tokens.default.rewardTokenContract) {
        const value = await contracts.tokens.default.rewardTokenContract.balanceOf(address)

        const newRewardTokenBalance = formatEther(value)
        const isUpdated = newRewardTokenBalance !== rewardTokenBalance

        if (isUpdated) {
          actions.account.setRewardTokenBalance(newRewardTokenBalance)
        }

        return isUpdated
      }
    }

    interval.current = setInterval(() => {
      fetch().then((isSuccess) => {
        if (isSuccess && interval) {
          clearInterval(interval.current as NodeJS.Timer)
          resetTimer(12 * 60 * 60 * 1000) // set new timer (12h) for new update
        }
      })
    }, 3 * 60 * 1000) // check new value every 3 minutes
  }, [ address, contracts, rewardTokenBalance ])
}


export default useUpdateRewards
