import React from 'react'
import cx from 'classnames'
import { useStore, useConfig } from 'hooks'
import { modifiers, constants } from 'helpers'

import { Flex, Grid } from 'components/layout'
import { Button } from 'components/inputs'

import Item from './Item/Item'

import messages from './messages'
import s from './Statistic.module.scss'


const storeSelector = (store: Store) => ({
  protocolFee: store.system.validators.protocolFee,
  totalStakingAmount: store.system.totalStakingAmount,
  isInitialRequestLoading: store.ui.initialRequestLoading,
})

const Statistic: React.FC = () => {
  const { config, isHarbourMainnet } = useConfig()
  const { protocolFee, totalStakingAmount, isInitialRequestLoading } = useStore(storeSelector)

  const formattedAmount = modifiers.formatTokenValue({
    value: totalStakingAmount,
    withSeparator: false,
  })

  const containerClassName = cx(s.container, {
    [s.loading]: isInitialRequestLoading,
    [s.harbourContainer]: isHarbourMainnet,
  })

  const title = {
    ...messages.total,
    values: { depositToken: config.tokens.depositToken },
  }

  if (isHarbourMainnet) {
    return (
      <Flex className={containerClassName} justify="center">
        <a
          href="https://blockdaemon.com/products/liquid-staking/"
          target="_blank"
          rel="noreferrer nofollow"
          data-testid="validators-map-staking-link"
        >
          <Button
            message="Join Portara"
            color="blue"
          />
        </a>
      </Flex>
    )
  }

  return (
    <Grid
      className={containerClassName}
      columns={isHarbourMainnet ? 1 : 2}
    >
      <Item
        value={parseFloat(formattedAmount)}
        image={constants.tokensImages[config.tokens.depositToken]}
        text={title}
        decimals={2}
        valueDataTestId="validator-map-staking-amount"
        textDataTestId="validators-map-staking-amount-header"
      />
      <Item
        tooltip={messages.feeTooltip}
        value={protocolFee}
        text={messages.fee}
        suffix={` %`}
        decimals={0}
        valueDataTestId="validator-map-staking-fee-value"
        textDataTestId="validators-map-staking-fee-header"
        tooltipDataTestId="validators-map-staking-fee-help"
      />
    </Grid>
  )
}


export default React.memo(Statistic)
