import adddresses from './adddresses'


const domain = {
  verifyingContract: adddresses.limitOrderEthereumContract,
  name: '1inch Limit Order Protocol',
  version: '2',
  chainId: 1,
}

const types = {
  Order: [
    {
        name: 'salt',
        type: 'uint256',
    },
    {
        name: 'makerAsset',
        type: 'address',
    },
    {
        name: 'takerAsset',
        type: 'address',
    },
    {
        name: 'maker',
        type: 'address',
    },
    {
        name: 'receiver',
        type: 'address',
    },
    {
        name: 'allowedSender',
        type: 'address',
    },
    {
        name: 'makingAmount',
        type: 'uint256',
    },
    {
        name: 'takingAmount',
        type: 'uint256',
    },
    {
        name: 'makerAssetData',
        type: 'bytes',
    },
    {
        name: 'takerAssetData',
        type: 'bytes',
    },
    {
        name: 'getMakerAmount',
        type: 'bytes',
    },
    {
        name: 'getTakerAmount',
        type: 'bytes',
    },
    {
        name: 'predicate',
        type: 'bytes',
    },
    {
        name: 'permit',
        type: 'bytes',
    },
    {
        name: 'interaction',
        type: 'bytes',
    },
  ],
}


export default { domain, types }
