export default {
  title: {
    en: 'Staking APR',
    ru: 'Стейкинг APR',
    fr: 'TAEG de staking',
    es: 'TAE de Staking',
    pt: 'Staking APR',
    de: 'Staking APR',
    zh: '質押年利率',
  },
  note: {
    en: 'Annual rewards rate after fees',
    ru: 'Годовое вознаграждение после комиссии',
    fr: 'Taux annuel après déduction des frais',
    es: 'Tasa anual de interés luego de tarifas',
    pt: 'Taxa de recompensas anuais após as taxas',
    de: 'Jährliche Belohnungen nach Gebühren',
    zh: '扣除質押費用後的年獲利率',
  },
}
