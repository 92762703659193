import { useCallback, useEffect } from 'react'
import { Field } from 'formular'
import { useConfig, useObjectState } from 'hooks'
import { actions as actionsHelpers } from 'helpers'

import { fetchAllowance, fetchAmountOut } from './helpers'


type State = {
  amountOut: string
  isLoading: boolean
  isNeedAllowance: boolean
}

type Input = {
  depositField: Field<string>
}

const initialState = {
  amountOut: '0',
  isLoading: false,
  isNeedAllowance: false,
}

const useReinvestData = ({ depositField }: Input) => {
  const { config, address, contracts } = useConfig()

  const [ { amountOut, isNeedAllowance, isLoading }, setState ] = useObjectState<State>(initialState)

  const checkIsValidAmount = useCallback((depositFieldState) => (
    Boolean(
      Number(depositFieldState.value)
      && !depositFieldState.isValidating
      && (
        depositFieldState.isValid
        || !depositFieldState.error
      )
    )
  ), [])

  const handleFetchAllowance = useCallback(async (amount: string) => {
    if (!contracts || !address) {
      return false
    }

    return fetchAllowance({ amount, config, address, contracts })
  }, [ config, address, contracts ])

  const handleFetchAmountOut = useCallback(async (amount: string) => {
    if (!contracts) {
      return '0'
    }

    return fetchAmountOut({ config, amount, contracts })
  }, [ config, contracts ])

  useEffect(() => {
    const fetchData = actionsHelpers.debounce((depositFieldState: Record<string, string>) => {
      if (checkIsValidAmount(depositFieldState)) {
        const value = depositFieldState.value

        setState({ isLoading: true })

        Promise.all([
          handleFetchAmountOut(value),
          handleFetchAllowance(value),
        ])
          .then(([ amountOut, isNeedAllowance ]) => {
            if (checkIsValidAmount(depositFieldState)) {
              setState({
                amountOut,
                isNeedAllowance,
                isLoading: false,
              })
            }
          })
      }
      else {
        setState(initialState)
      }
    }, 150)

    depositField.on('state change', fetchData)

    return () => {
      depositField.off('state change', fetchData)
    }
  }, [ depositField, checkIsValidAmount, setState, handleFetchAmountOut, handleFetchAllowance ])

  const updateAllowance = useCallback(() => {
    const value = depositField.state.value as string

    handleFetchAllowance(value)
      .then((isNeedAllowance) => {
        setState({ isNeedAllowance })
      })
  }, [ depositField, handleFetchAllowance, setState ])

  const setNeedAllowance = useCallback((isNeedAllowance) => {
    setState({ isNeedAllowance })
  }, [ setState ])

  return {
    amountOut,
    isLoading,
    isNeedAllowance,
    setNeedAllowance,
    updateAllowance,
  }
}


export default useReinvestData
