import { useCallback, useMemo, useEffect } from 'react'
import { Form } from 'formular'
import { oneInchAddresses } from 'defi/oneInch'
import { useConfig, useActions, useObjectState } from 'hooks'
import { requests, commonMessages, actions as actionsHelpers } from 'helpers'


type Input = Form<{
  token: Tokens
  amount: string
  termsAndConditions: boolean
}>

type State = {
  isNeedApprove: boolean
  isAllowanceFetching: boolean
}

const useApprove = (form: Input) => {
  const actions = useActions()
  const { config, contracts, library, address } = useConfig()

  const [ { isNeedApprove, isAllowanceFetching }, setState ] = useObjectState<State>({
    isNeedApprove: false,
    isAllowanceFetching: false,
  })

  const handleAllowance = useCallback(async (amount: string) => {
    const isRewardToken = form.fields.token.state.value === config.tokens.rewardToken

    const tokenContract = isRewardToken
      ? contracts.tokens.default.rewardTokenContract
      : contracts.tokens.default.stakedTokenContract

    if (address && tokenContract) {
      setState({ isAllowanceFetching: true })

      const isNeedApprove = await requests.allowance({
        recipient: oneInchAddresses.limitOrderEthereumContract,
        sender: address,
        tokenContract,
        amount,
      })

      setState({
        isNeedApprove,
        isAllowanceFetching: false,
      })
    }

    return Promise.reject()
  }, [ config, address, contracts, form, setState ])

  const handleApprove = useCallback(async (callback: () => void) => {
    const isRewardToken = form.fields.token.state.value === config.tokens.rewardToken

    const tokenAddress = isRewardToken
      ? config.addresses.tokens.default.reward
      : config.addresses.tokens.default.staked

    if (address) {
      const result = await requests.approve({
        to: oneInchAddresses.limitOrderEthereumContract,
        from: address,
        tokenAddress,
        contracts,
        library,
      })

      actionsHelpers.handleTransaction({
        sentText: commonMessages.notifications.sent,
        txHash: result?.hash,
        library,
        actions,
        config,
        callback,
      })
    }

    return Promise.reject()
  }, [ address, actions, config, contracts, library, form ])

  const handleResetApprove = useCallback(() => {
    setState({
      isNeedApprove: false,
      isAllowanceFetching: false,
    })
  }, [ setState ])

  useEffect(() => {
    const checkAllowance = actionsHelpers.debounce((state: Record<string, string>) => {
      const isValid = Number(state.value) && (state.isValid || !state.error)

      if (isValid) {
        handleAllowance(state.value)
      }
    }, 150)

    form.fields.amount.on('state change', checkAllowance)

    return () => {
      form.fields.amount.off('state change', checkAllowance)
    }
  }, [ form, handleAllowance ])

  return useMemo(() => ({
    isNeedApprove,
    isAllowanceFetching,
    approve: handleApprove,
    resetApprove: handleResetApprove,
  }), [ isNeedApprove, isAllowanceFetching, handleApprove, handleResetApprove ])
}


export default useApprove
