import React from 'react'

import { Box } from 'components/layout'
import { Text, Countdown, CountUp } from 'components/ui'
import CardWithIcon from 'compositions/cards/CardWithIcon/CardWithIcon'

import useRewardsCard from './util/useRewardsCard'
import useUpdateRewards from './util/useUpdateRewards'

import messages from './messages'


const timeOptions = { withSeconds: false }

const RewardsCard: React.FC = () => {
  const updateRewards = useUpdateRewards()
  const {
    value,
    fiatRewards,
    initialTime,
    rewardToken,
    depositToken,
    showCountdown,
    currencySymbol,
    isInitialRequestLoading,
  } = useRewardsCard()

  const bottomNote = { ...messages.note, values: { depositToken } }

  return (
    <CardWithIcon
      borderColor="red"
      title={messages.title}
      bottomNote={bottomNote}
      iconType={rewardToken}
      isLoading={isInitialRequestLoading}
      dataTestIdPrefix="rewards"
      contentNode={
        <Box mt={4}>
          <Text
            color="default"
            size={24}
            light
            dataTestId="rewards-amount"
          >
            <CountUp
              end={value}
              separator=","
              duration={1.5}
              decimals={value ? 4 : 2}
              suffix={` ${rewardToken}`}
            />
          </Text>
          <Box mt={4} noWrapper>
            <Text
              color="info"
              size={16}
              dataTestId="rewards-amount-fiat"
            >
              <CountUp
                end={fiatRewards}
                decimals={2}
                duration={1.5}
                separator=","
                prefix={currencySymbol}
              />
            </Text>
          </Box>
        </Box>
      }
      bottomNode={
        showCountdown && (
          <Countdown
            initialTime={initialTime}
            timeOptions={timeOptions}
            expiredCallback={updateRewards}
            dataTestId="rewards-status"
          />
        )
      }
    />
  )
}


export default React.memo(RewardsCard)
