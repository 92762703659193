import React from 'react'
import { useDevice } from 'device'

import { Text } from 'components/ui'
import { Flex, Box } from 'components/layout'

import s from './RowWrapper.module.scss'


type RowWrapperProps = {
  title: Intl.Message | React.ReactNode
  dataTestId?: string
}

const RowWrapper: React.FC<RowWrapperProps> = (props) => {
  const { children, title, dataTestId } = props

  const { isMobile } = useDevice()

  return (
    <Flex justify="center" align="center">
      <Box
        className={s.box}
        pr={isMobile ? 4 : 8}
        alignRight
        dataTestId={dataTestId}
      >
        {
          React.isValidElement(title) ? (
            title
          ) : (
            <Text
              message={title as Intl.Message}
              size={isMobile ? 14 : 16}
              color="info"
            />
          )
        }
      </Box>
      <Box className={s.box} pl={isMobile ? 4 : 8} noWrapper>
        <Flex justify="start">
          {children}
        </Flex>
      </Box>
    </Flex>
  )
}


export default RowWrapper
