export default {
  title: {
    en: 'Staking | StakeWise',
    ru: 'Стейкинг | StakeWise',
    fr: 'Staking | StakeWise',
    es: 'Staking | StakeWise',
    pt: 'Staking | StakeWise',
    de: 'Staking | StakeWise',
    zh: '質押 | StakeWise',
  },
}
