import { useCallback, useEffect, useRef } from 'react'
import { useForm } from 'formular'
import { useConfig, useStore } from 'hooks'
import { parseEther } from '@ethersproject/units'
import { validators } from 'helpers'


const storeSelector = (store: Store) => ({
  nativeTokenBalance: store.account.balances.nativeTokenBalance,
})

const useStakeForm = () => {
  const { address, networkId } = useConfig()
  const { nativeTokenBalance } = useStore(storeSelector)

  const balanceValidator = useCallback((value: string) => (
    validators.sufficientBalance(parseEther(nativeTokenBalance))(value)
  ), [ nativeTokenBalance ])

  const balanceValidatorRef = useRef(balanceValidator)
  balanceValidatorRef.current = balanceValidator

  const form = useForm<Deposit.Form>({
    fields: {
      deposit: [ validators.numberWithDot, validators.greaterThanZero, validators.refValidator(balanceValidatorRef) ],
      address: [ validators.required, validators.ethOrEnsAddress ],
      termsAndConditions: [],
    },
  })

  // Unset form values on network or address change
  useEffect(() => {
    form.unsetValues()

    if (address) {
      form.fields.address.set(address)
    }
  }, [ form, address, networkId ])

  return {
    form,
  }
}


export default useStakeForm
