export default {
  title: {
    en: 'Recipient Address',
    ru: 'Адрес Получателя',
    fr: 'Adresse du destinataire',
    es: 'Dirección de recepción',
    pt: 'Endereço do destinatário',
    de: 'Empfängeradresse',
    zh: '收據地址',
  },
  text: {
    en: 'Your staked {token} will be owned by',
    ru: 'Застейканный Вами {token} будет принадлежать',
    fr: 'Vos ETH stakés seront détenu par',
    es: 'Su {token} en staking pertenecerá a',
    pt: 'Seu staked {token} será de propriedade de',
    de: 'Ihr eingesetzter {token} wird Eigentum von sein',
    zh: '您質押的 {token} 所有權歸屬於',
  },
  inputText: {
    en: 'Address or ENS name',
    ru: 'Адрес или ENS имя',
    fr: 'Adresse ou nom ENS',
    es: 'Dirección o ENS',
    pt: 'Endereço ou nome ENS',
    de: 'Adresse oder ENS Name',
    zh: '地址或 ENS域名',
  },
  errors: {
    invalidEnsName: {
      en: 'Unable to resolve ENS address',
      ru: 'Не удалось проверить адрес ENS',
      fr: 'Impossible de résoudre l\'adresse ENS',
      es: 'Error al tratar de resolver su ENS',
      pt: 'Não foi possível resolver o endereço ENS',
      de: 'ENS Name kann nicht aufgelöst werden',
      zh: '無法解析 ENS域名',
    },
  },
}
