import { constants, getters } from 'helpers'
import { Route, encodeRouteToPath } from '@uniswap/v3-sdk'
import { CurrencyAmount, Fraction } from '@uniswap/sdk-core'


type Input = {
  pool: UniPool
  config: Config
  amountIn: string
  contracts: Contracts
}

const fetchUniswapAmounts = async (values: Input): Promise<Deposit.UniswapAmounts> => {
  const { pool, amountIn, config, contracts } = values

  const { isHarbour } = getters.getNetworkType(config)

  if (!contracts.helpers.uniswap.quoterContract || isHarbour) {
    const value = constants.blockchain.amount0

    return {
      swapAmountOutBN: value,
      minSwapAmountOut: value.toString(),
    }
  }

  const { uniStakedToken, uniWrappedEthToken } = getters.getUniTokens(config)

  const route = new Route(
    [ pool ],
    uniWrappedEthToken,
    uniStakedToken
  )

  const path = encodeRouteToPath(route, false)
  const currencyAmount = CurrencyAmount.fromRawAmount(uniWrappedEthToken, amountIn)
  const amountHash = `0x${currencyAmount.quotient.toString(16)}`
  const swapAmountOutBN = await contracts.helpers.uniswap.quoterContract.quoteExactInput(path, amountHash)

  const swapAmount = CurrencyAmount.fromRawAmount(
    uniStakedToken,
    swapAmountOutBN?.toString()
  )

  const minSwapAmountOut = new Fraction(1)
    .add(constants.blockchain.uniswapDefaultSlippage)
    .invert()
    .multiply(swapAmount.quotient)
    .quotient.toString()

  return {
    swapAmountOutBN,
    minSwapAmountOut,
  }
}


export default fetchUniswapAmounts
