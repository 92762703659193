import { BigNumber } from '@ethersproject/bignumber'
import { getters } from 'helpers'

import getRequestData from './getRequestData'
import fetchDepositGas from './fetchDepositGas'
import fetchUniswapAmounts from './fetchUniswapAmounts'


type Input = {
  config: Config
  address: string
  amountIn: string
  recipient: string
  contracts: Contracts
}

const fetchUniswapData = async ({ config, address, amountIn, recipient, contracts }: Input) => {
  try {
    const pools = await getters.getUniPools({ contracts, config })

    if (pools) {
      const pool = pools[config.addresses.pools.uniswap.stakedTokenNativeToken]

      if (pool) {
        const swapAmounts = await fetchUniswapAmounts({ pool, config, amountIn, contracts })

        if (swapAmounts) {
          const { minSwapAmountOut, swapAmountOutBN } = swapAmounts

          const isValidSwapParams = (
            minSwapAmountOut
            && swapAmountOutBN?.gt(0)
            && getters.isStringValueGreaterZero(minSwapAmountOut)
          )

          if (isValidSwapParams) {
            const {
              fee,
              tokenIn,
              tokenOut,
              deadline,
              sqrtPriceLimitX96,
            } = getRequestData(config)

            const requestParams = {
              fee,
              tokenIn,
              tokenOut,
              deadline,
              sqrtPriceLimitX96,
              recipient,
              amountIn: BigNumber.from(amountIn),
              amountOutMinimum: BigNumber.from(minSwapAmountOut),
            }

            const gasLimit = await fetchDepositGas({
              params: requestParams,
              address,
              amountIn,
              contracts,
            })

            if (gasLimit) {
              return {
                requestParams,
                swapAmounts,
                gasLimit,
              }
            }
          }
        }
      }
    }
  }
  catch (error) {
    console.error('Fetch Uniswap params error', error)
  }
}


export default fetchUniswapData
