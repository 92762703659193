import React from 'react'
import { modifiers } from 'helpers'
import type { Field } from 'formular'
import { useFieldState } from 'formular'
import { useDevice } from 'modules/device'
import { isAddress } from '@ethersproject/address'

import { Box, Flex } from 'components/layout'
import { Tooltip } from 'components/feedback'
import { Text, Icon, QuestionIcon } from 'components/ui'

import RowWrapper from '../components/RowWrapper/RowWrapper'
import { ChangeDepositAddressModal, openChangeDepositAddressModal } from './ChangeDepositAddressModal/ChangeDepositAddressModal'

import messages from './messages'


export type AddressRowProps = {
  token: Extract<Tokens, 'GNO' | 'mGNO' | 'ETH'>
  addressField: Field<string>
}

const AddressRow: React.FC<AddressRowProps> = (props) => {
  const { token, addressField } = props

  const { isMobile } = useDevice()
  const { value } = useFieldState(addressField)

  const message = value ?
    isAddress(value)
      ? modifiers.shortenAddress(value, -6)
      : value
    : '-'

  const handleClick = () => {
    openChangeDepositAddressModal({ addressField, token })
  }

  const tooltipContent = {
    ...messages.infoTooltip,
    values: { token },
  }

  return (
    <RowWrapper
      title={(
        <Flex justify="end">
          <Box mr={8}>
            <Tooltip
              content={tooltipContent}
              center
              dataTestId="stake-recipient-help"
            >
              <QuestionIcon background="blue" size={14} />
            </Tooltip>
          </Box>
          <Text
            message={messages.title}
            color="info"
            size={isMobile ? 14 : 16}
            dataTestId="stake-recipient-item"
          />
        </Flex>
      )}
    >
      <Box mr={8} noWrapper>
        <Text
          message={message}
          color="default"
          size={14}
          dataTestId="stake-recipient-address"
        />
      </Box>
      {
        Boolean(value) && (
          <Tooltip content={messages.buttonTooltip}>
            <button
              type="button"
              data-testid="stake-change-recipient-button"
              onClick={handleClick}
            >
              <Icon name="fa-light fa-pen-to-square" color="info" size={16} />
            </button>
          </Tooltip>
        )
      }
      <ChangeDepositAddressModal />
    </RowWrapper>
  )
}


export default React.memo(AddressRow)
