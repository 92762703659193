import { useCallback, useEffect, useMemo } from 'react'
import request from 'request-module'
import { LimitOrder } from '@1inch/limit-order-protocol'
import { useConfig, useObjectState, useRecaptcha, useStore } from 'hooks'

import createOrder from './createOrder'
import type { Output as UseAmountOutOutput } from './useAmountOut'


type State = {
  orders: Array<{
    signature: string
    orderHash: string
    data: LimitOrder
  }>
  isOrdersFetching: boolean
}

const storeSelector = (store: Store) => ({
  isWhitelisted: store.account.isWhitelisted,
})

const useOrders = (calculateAmountOut: UseAmountOutOutput['calculateAmountOut']) => {
  const { config, library, address } = useConfig()
  const { isWhitelisted } = useStore(storeSelector)
  const { reCaptchaToken, isReCaptchaFetching } = useRecaptcha('withdrawOrder')

  const [ { orders, isOrdersFetching }, setState ] = useObjectState<State>({
    orders: [],
    isOrdersFetching: isWhitelisted,
  })

  const handleCreateOrder = useCallback((amount: string, tokenOut: Tokens) => {
    if (address && reCaptchaToken) {
      return createOrder({
        amount,
        config,
        address,
        library,
        tokenOut,
        reCaptchaToken,
        calculateAmountOut,
      })
    }
  }, [ address, library, config, reCaptchaToken ])

  const fetchOrders = useCallback(() => {
    request.post<State['orders']>({
      url: `${config.api.rest}/withdraw-orders/${address}`,
      params: {
        network: config.network.id,
      },
    })
      .then((orders) => {
        setState({ orders, isOrdersFetching: false })
      })
      .catch(() => {
        setState({ isOrdersFetching: false })
      })
  }, [ address, config, setState ])

  const handleRemoveOrder = useCallback((signature: string) => {
    setState((state) => {
      const newOrders = state.orders.filter((order) => order.signature !== signature)

      return {
        ...state,
        orders: newOrders,
      }
    })
  }, [ setState ])

  useEffect(() => {
    if (isWhitelisted) {
      fetchOrders()
    }
  }, [])

  return useMemo(() => ({
    orders,
    isOrdersFetching: isReCaptchaFetching || isOrdersFetching,
    fetchOrders,
    createOrder: handleCreateOrder,
    removeOrder: handleRemoveOrder,
  }), [
    orders,
    isOrdersFetching,
    isReCaptchaFetching,
    handleCreateOrder,
    handleRemoveOrder,
    fetchOrders,
  ])
}


export default useOrders
