export default {
  paused: {
    en: `
      New deposits are only available on StakeWise V3
    `,
    ru: `
      Новые вклады доступны только на StakeWise V3
    `,
    fr: `
      Les nouveaux dépôts ne sont disponibles que sur  StakeWise V3
    `,
    es: `
      Los nuevos depósitos solo están disponibles en StakeWise V3
    `,
    pt: `
      Novos depósitos estão disponíveis apenas em StakeWise V3
    `,
    de: `
      Neue Einlagen sind nur verfügbar am StakeWise V3
    `,
    zh: `
      新存款仅在 StakeWise V3
    `,
  },
}
