export default {
  title: {
    en: 'Export Rewards',
    ru: 'Экспорт Вознаграждений',
    fr: 'Exporter les récompenses',
    es: 'Exportar Ganancias',
    pt: 'Exportar recompensas',
    de: 'Belohnungen exportieren',
    zh: '匯出獎勵',
  },
  from: {
    en: 'From',
    ru: 'С',
    fr: 'Depuis',
    es: 'Desde',
    pt: 'De',
    de: 'Von',
    zh: '從',
  },
  to: {
    en: 'To',
    ru: 'По',
    fr: 'Vers',
    es: 'Para',
    pt: 'A',
    de: 'An',
    zh: '到',
  },
  format: {
    en: 'Format',
    ru: 'Формат',
    fr: 'Format',
    es: 'Formato',
    pt: 'Formato',
    de: 'Format',
    zh: '格式',
  },
  placeholder: {
    en: 'MM/DD/YYYY',
    ru: 'ММ/ДД/ГГГГ',
    fr: 'MM/JJ/AAAA',
    es: 'DD/MM/YYYY',
    pt: 'DD/MM/YYYY',
    de: 'MM/TT/JJJJ',
    zh: 'MM/DD/YYYY',
  },
  buttonTitle: {
    en: 'Download',
    ru: 'Скачать',
    fr: 'Télécharger',
    es: 'Descargar',
    pt: 'Download',
    de: 'Download',
    zh: '下載',
  },
}
